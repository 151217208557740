import React, { useState } from "react";
import axios from "axios";
import { ConsoleLogger } from "@aws-amplify/core";
import fileDownload from "js-file-download";
import ModalNovaVerzeMTVInput from "./Order-Mediaplan-Modal_import_TV_2";
import ModalRunning from "./modal_running";
import ModalInfo from "./modal_info";

export default function MediaplanModalImport(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [subModal, setSubModal] = useState(false);
  const [inputData, setInputData] = useState([]); // z backendu pride matica pri TV 1. faze importu, ktoru len updatuju a posielame naspat na BE

  const [isRunning, setIsRunning] = useState(false);
  const [loadType, setLoadType] = useState("standard"); //pre TV ci nahravame s MP alebo bez

  const inputDataZeros = [
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Nova Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 0,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Prima Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 1,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Nova Sport",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 2,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Barrandov Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 3,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "AT Media",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 4,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Seznam TV",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 5,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "CT Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 6,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "O2 TV",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 7,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Media one",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 8,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Óčko",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 9,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Sporty TV",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 10,
    },
  ];

  const toggleModal = () => {
    props.setModal(!props.modal);
    console.log(selectedFile);
  };

  const sortMV = (data) => {
    const values = data;
    values.sort((a, b) => b.ID - a.ID);
    props.setMediaplanVerzia(values);
  };

  let filename_update;
  let urlll;

  const NemereneStaniceImport = () => {
    setInputData(inputDataZeros);
    setLoadType("nemerene");
    setSubModal(true);
  };

  const handleSubmit = async () => {
    const data = new FormData();
    data.append("file", selectedFile, selectedFile.name);

    var today = new Date();
    var date = today.getFullYear() + today.getMonth() + today.getDate();
    var time = today.getMilliseconds();
    var dateTime = date + "_" + time;

    filename_update = dateTime + "_" + selectedFile.name;

    setIsRunning(true);
    // GET request: presigned URL
    await axios
      .get(
        "/mediaplannewversion?filename=" +
          filename_update +
          "&mediatyp=" +
          props.vybranyMediatyp
      )
      .then((res) => {
        console.log(res);
        urlll = res.data.uploadURL;
      })
      .catch((err) => {
        setIsRunning(false);
      });
    // PUT request: upload file to S3
    await fetch(urlll, {
      method: "PUT",
      body: selectedFile,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        setIsRunning(false);
      });

    const url =
      "/newmediaplanprint?filename=" +
      filename_update +
      "&kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&substitution=" +
      props.substitution +
      "&substituted=" +
      props.vybranyMediaplan;
    await axios
      .get(url)
      .then((res) => {
        if (
          (props.vybranyMediatyp === "TV") &
          (res.data[0]["IS_TV_STANDARD"] == true)
        ) {
          console.log(res.data[0]["M"]);

          setInputData(res.data[0]["M"]);

          props.mv_id.current = res.data[0]["M"][0]["ID_MEDIAPLAN_VERSION"];
          props.setLoadingMV(props.mv_id.current);

          setIsRunning(false);
          setSubModal(true);
        } else {
          props.setModal(false);

          props.setMediaplanVerzia(res.data[0]["MV"]);
          props.setMediaplan(res.data[0]["M"]);

          if (props.vybranyMediatyp === "TV") {
            props.setMediaplanSP(res.data[0]["M_SP"]);
          }

          sortMV(res.data[0]["MV"]);

          const aktualny_mediaplan = Math.max(
            ...res.data[0]["M"].map((o) => o.ID_MEDIAPLAN_VERSION)
          );
          props.setVybranyMediaplan(aktualny_mediaplan);

          props.setInfoBox("Mediaplán byl úspěšne nahrán do systému.");
          props.setIsError(false);
          props.setModal(false);

          setIsRunning(false);

          if (res.data[0]["STAV"] === 1) {
            props.setProcessType(res.data[0]["STAV"]);
            props.setProcessToInvoke(true);
            props.setProcessFrom("upload");
          }

          if (res.data[0]["STAV"] === 2) {
            props.setProcessType(res.data[0]["STAV"]);
            props.setProcessToInvoke(true);
            props.setProcessFrom("upload");
          }
        }
      })
      .catch((err) => {
        try {
          props.setIsError(true);
          props.setInfoBox(err.response.data);
          props.setModal(false);
          setIsRunning(false);
        } catch {
          props.setIsError(true);
          props.setInfoBox("Neočekávaná chyba! Mediaplán se nezdařilo nahrát!");
          props.setModal(false);
          setIsRunning(false);
        }
      });
  };

  return (
    <>
      {props.modal && (
        <div class="modal-window">
          <ModalNovaVerzeMTVInput
            vybranyMediatyp={props.vybranyMediatyp}
            vybranaZakazka={props.vybranaZakazka}
            subModal={subModal}
            setSubModal={setSubModal}
            inputData={inputData}
            setInputData={setInputData}
            loadingMV={props.loadingMV}
            setLoadingMV={props.setLoadingMV}
            mv_id={props.mv_id}
            setMediaplanVerzia={props.setMediaplanVerzia}
            setMediaplan={props.setMediaplan}
            sortMV={props.sortMV}
            setVybranyMediaplan={props.setVybranyMediaplan}
            vybranyMediaplan={props.vybranyMediaplan}
            setModal={props.setModal}
            setProcessType={props.setProcessType}
            setProcessToInvoke={props.setProcessToInvoke}
            setProcessFrom={props.setProcessFrom}
            loadType={loadType}
          />
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Probíha import mediaplánu"}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Import mediaplánu</div>
              <input
                class="form-input"
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
              {props.vybranyMediatyp === "TV" ? (
                <>
                  <div class="modal-window__actions">
                    <a
                      className="btn btn--medium btn--primary"
                      onClick={handleSubmit}
                    >
                      Nahrát soubor
                    </a>
                    <a
                      className="btn btn--medium btn--primary"
                      onClick={NemereneStaniceImport}
                    >
                      Pokračovat bez souboru
                    </a>
                  </div>
                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Storno
                    </a>
                  </div>
                </>
              ) : (
                <div class="modal-window__actions">
                  <a
                    className="btn btn--medium btn--primary"
                    onClick={handleSubmit}
                  >
                    Nahrát soubor
                  </a>
                  <a
                    class="btn btn--medium btn--bordered"
                    onClick={toggleModal}
                  >
                    Storno
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
