import React, { useState, useHistory } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ProcessModalDelete(props) {
  const [deleted, setDeleted] = useState(0);

  const navigate = useNavigate();

  const toggleModal = () => {
    props.setModalDelete(!props.modalDelete);
  };

  const DeleteOrder = () => {
    const url =
      "/processcontinuation?from=" +
      "delete" +
      "&kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp;

    axios.post(url).then((res) => {
      debugger;
      console.log(res.data);
      const process = res.data;
      process === "Success" ? setDeleted(1) : setDeleted(2);
    });
  };

  const RedirectToMyorders = () => {
    props.setModalDelete(!props.modalDelete);
    navigate("/MyOrders");
  };

  return (
    <>
      {props.modalDelete && (
        <div class="modal-window modal-window--alert">
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              {deleted === 0 ? (
                <>
                  <div class="modal-window__content">
                    <div class="alert alert--error">
                      Opravdu chcete smazat zakázku s ID {props.vybranaZakazka}?
                      Akci už nebude možné vrátit zpět!
                    </div>
                  </div>
                  <div class="modal-window__actions">
                    <a
                      className="btn btn--medium btn--red"
                      onClick={DeleteOrder}
                    >
                      Ano
                    </a>
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Ne
                    </a>
                  </div>
                </>
              ) : (
                <></>
              )}
              {deleted === 1 ? (
                <>
                  <div class="modal-window__content">
                    <div class="alert alert--info">
                      Zakázku s ID {props.vybranaZakazka} byla úspěšne smazána.
                    </div>
                  </div>
                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={RedirectToMyorders}
                    >
                      ok
                    </a>
                  </div>
                </>
              ) : (
                <></>
              )}
              {deleted === 2 ? (
                <>
                  <div class="modal-window__content">
                    <div class="alert alert--warning">
                      Zakázku s ID {props.vybranaZakazka} se nepodarilo smazat.
                      K zakázce již byl nahrán mediaplán aneb zaslán e-mail.
                    </div>
                  </div>
                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Ok
                    </a>
                  </div>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
            <div class="modal-window__actions"></div>
          </div>
        </div>
      )}
    </>
  );
}
