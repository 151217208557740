import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useParams, useLocation } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.min.css";
//import OrderMenu from "./Order-Menu";
//import InfoBar from "./InfoBar";
import GeneralsModalSeen from "./Order-Generals-Modal_seen";
import ModalRunningWhite from "./modal_running_white";
import ModalInfo from "./modal_info";

export default function OrderGenerals(props) {
  // const [isLoading, setLoading] = useState(true);
  const [ID, setID] = useState();
  const [NAZEV_ZAKAZKY, setNAZEV_ZAKAZKY] = useState();
  const [CLIENT, setCLIENT] = useState();
  const [ID_CLIENTS, setID_CLIENTS] = useState();
  const [MEDIATYP, setMEDIATYP] = useState();
  const [OD, setOD] = useState();
  const [DO, setDO] = useState();
  const [CLIENT_SUCCESS_CONSULTANT, setCLIENT_SUCCESS_CONSULTANT] = useState();
  const [
    ZASTUP_CLIENT_SUCCESS_CONSULTANT,
    setZASTUP_CLIENT_SUCCESS_CONSULTANT,
  ] = useState();
  const [NAKUP, setNAKUP] = useState();
  const [ZASTUP_NAKUP, setZASTUP_NAKUP] = useState();
  const [CLIENT_LEAD, setCLIENT_LEAD] = useState();
  const [NAVAZANE_ZAKAZKY, setNAVAZANE_ZAKAZKY] = useState("");
  const [KLIENT_PO, setKLIENT_PO] = useState();
  const [KAMPAN_ID, setKAMPAN_ID] = useState();
  const [BRAND, setBRAND] = useState();
  //const [SEGMENT, setSEGMENT] = useState();

  const [usersClients, setUsersClients] = useState([]);
  const [edit, setEdit] = useState(false);
  const [seen, setSeen] = useState(false);

  const [isError, setIsError] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [infoBox, setInfoBox] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    const SetAllStates = () => {
      const obj = JSON.parse(localStorage.getItem("zakazka")).find(
        (x) => x.KAMPAN_ID === params.zakazka
      );

      obj["SEEN"] == 0 ? setSeen(true) : setSeen(false);

      sessionStorage.setItem(
        "STAV_ZAKAZKY",
        obj["STAV_ZAKAZKY"] === "Schváleno"
          ? obj["CONFIRMED_COUNT"] === null
            ? obj["STAV_ZAKAZKY"] + " (0x)"
            : obj["STAV_ZAKAZKY"] + " (" + obj["CONFIRMED_COUNT"] + "x)"
          : obj["STAV_ZAKAZKY"]
      );

      setID(obj["ID"]);
      setNAZEV_ZAKAZKY(obj["NAZEV_ZAKAZKY"]);
      setCLIENT(obj["CLIENT"]);
      setID_CLIENTS(obj["ID_CLIENTS"]);
      setMEDIATYP(obj["MEDIATYP"]);
      setOD(obj["OD"]);
      setDO(obj["DO"]);
      setCLIENT_SUCCESS_CONSULTANT(obj["CLIENT_SUCCESS_CONSULTANT"]);
      setZASTUP_CLIENT_SUCCESS_CONSULTANT(
        obj["ZASTUP_CLIENT_SUCCESS_CONSULTANT"]
      );
      setNAKUP(obj["NAKUP"]);
      setZASTUP_NAKUP(obj["ZASTUP_NAKUP"]);
      setCLIENT_LEAD(obj["CLIENT_LEAD"]);
      setNAVAZANE_ZAKAZKY(obj["NAVAZANE_ZAKAZKY"]);
      setKLIENT_PO(obj["KLIENT_PO"]);
      setKAMPAN_ID(obj["KAMPAN_ID"]);
      setBRAND(obj["BRAND"]);
    };

    axios.get("/usersclients").then(
      (res) => {
        setUsersClients(res.data);
      },
      (err) => {
        console.log(err);
      }
    );

    const { hash, pathname, search } = location;

    if (hash.includes("redirected")) {
      axios.get("/myorders").then((res) => {
        console.log("called");
        localStorage.setItem("zakazka", JSON.stringify(res.data[0]["ZAKAZKY"]));
        try {
          props.setEmailRedirect(!props.emailRedirect);
          SetAllStates();
        } catch {}
      });
    } else {
      try {
        SetAllStates();
      } catch {}
    }
  }, []);

  const editZakazka = () => {
    if (edit === true) {
      setInfoMessage("Editují zakázku");
      setIsRunning(true);

      const data = {
        KAMPAN_ID: KAMPAN_ID,
        KLIENT_PO: KLIENT_PO,
        CLIENT_SUCCESS_CONSULTANT: CLIENT_SUCCESS_CONSULTANT,
        ZASTUP_CLIENT_SUCCESS_CONSULTANT: ZASTUP_CLIENT_SUCCESS_CONSULTANT,
        CLIENT_LEAD: CLIENT_LEAD,
        NAKUP: NAKUP,
        ZASTUP_NAKUP: ZASTUP_NAKUP,
        OD: OD,
        DO: DO,
        NAZEV_ZAKAZKY: NAZEV_ZAKAZKY,
        ID: ID,
      };

      console.log(data);

      axios
        .post("/editorder?mass=false", data)
        .then((res) => {
          console.log(res.data);
          setIsRunning(false);
          setInfoBox("Zakázka byla editována!");
          setIsError(false);
          setEdit(false);
        })
        .catch((err) => {
          setIsRunning(false);
          setInfoBox("Zakázku se nezdařilo editovat.");
          setIsError(true);
        });
    }
  };

  const AllowEdit = () => {
    setEdit(!edit);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <>
      <GeneralsModalSeen
        seen={seen}
        setSeen={setSeen}
        vybranaZakazka={params.zakazka}
      />
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              <div class="form">
                <div class="form__columns">
                  <div class="form__col">
                    <form class="form__in">
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          ID zakázky
                        </label>
                        <div class="form__value">
                          <input
                            class="form-input"
                            value={KAMPAN_ID}
                            readOnly
                          />
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Název zakázky
                        </label>
                        <div class="form__value--w450">
                          {(props.user !== 4) & (edit === true) ? (
                            <input
                              class="form-input form-input--valid"
                              value={NAZEV_ZAKAZKY}
                              onChange={(e) => setNAZEV_ZAKAZKY(e.target.value)}
                            />
                          ) : (
                            <input
                              class="form-input"
                              value={NAZEV_ZAKAZKY}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Klient
                        </label>
                        <div class="form__value">
                          <input class="form-input" value={CLIENT} readOnly />
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Brand
                        </label>
                        <div class="form__value">
                          <input class="form-input" value={BRAND} readOnly />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="form__col">
                    <form class="form__in">
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Client Lead
                        </label>
                        <div class="form__value">
                          {(props.user == 1) & (edit === true) ? (
                            <select
                              class="form-select form-select--valid"
                              name="CLIENT_LEAD"
                              value={CLIENT_LEAD}
                              onChange={(e) => setCLIENT_LEAD(e.target.value)}
                            >
                              <option
                                value={CLIENT_LEAD}
                                selected
                                disabled
                                hidden
                              >
                                {CLIENT_LEAD}
                              </option>
                              {usersClients
                                .filter(
                                  (o) =>
                                    (o.CLIENT_ID === ID_CLIENTS &&
                                      o.ROLE == 2) ||
                                    o.ROLE == 1
                                )
                                .filter(
                                  //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                  (value, index, self) =>
                                    self
                                      .map((x) => x.EMAIL)
                                      .indexOf(value.EMAIL) == index
                                )
                                .map((usersClients) => (
                                  <option value={usersClients.EMAIL}>
                                    {usersClients.EMAIL}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <input
                              class="form-input"
                              value={CLIENT_LEAD}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Client Success Consultant
                        </label>
                        <div class="form__value">
                          {(props.user < 3) & (edit === true) ? (
                            <select
                              class="form-select form-select--valid"
                              name="CLIENT_SUCCESS_CONSULTANT"
                              value={CLIENT_SUCCESS_CONSULTANT}
                              onChange={(e) =>
                                setCLIENT_SUCCESS_CONSULTANT(e.target.value)
                              }
                            >
                              <option
                                value={CLIENT_SUCCESS_CONSULTANT}
                                selected
                                disabled
                                hidden
                              >
                                {CLIENT_SUCCESS_CONSULTANT}
                              </option>
                              <option value={CLIENT_LEAD}>Client Lead</option>
                              {usersClients
                                .filter(
                                  (o) =>
                                    (o.CLIENT_ID === ID_CLIENTS &&
                                      o.ROLE <= 3) ||
                                    o.ROLE == 1
                                )
                                .filter(
                                  //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                  (value, index, self) =>
                                    self
                                      .map((x) => x.EMAIL)
                                      .indexOf(value.EMAIL) == index
                                )
                                .map((usersClients) => (
                                  <option value={usersClients.EMAIL}>
                                    {usersClients.EMAIL}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <input
                              class="form-input"
                              value={CLIENT_SUCCESS_CONSULTANT}
                              readOnly
                            />
                          )}
                        </div>
                      </div>

                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Zastupující CSC
                        </label>
                        <div class="form__value">
                          {(props.user !== 4) & (edit === true) ? (
                            <select
                              class="form-select form-select--valid"
                              name="ZASTUP_CLIENT_SUCCESS_CONSULTANT"
                              value={ZASTUP_CLIENT_SUCCESS_CONSULTANT}
                              onChange={(e) =>
                                setZASTUP_CLIENT_SUCCESS_CONSULTANT(
                                  e.target.value
                                )
                              }
                            >
                              <option
                                value={ZASTUP_CLIENT_SUCCESS_CONSULTANT}
                                selected
                                disabled
                                hidden
                              >
                                {ZASTUP_CLIENT_SUCCESS_CONSULTANT}
                              </option>
                              <option value={CLIENT_LEAD}>Client Lead</option>
                              {usersClients
                                .filter(
                                  (o) =>
                                    (o.CLIENT_ID === ID_CLIENTS &&
                                      o.ROLE <= 3) ||
                                    o.ROLE == 1
                                )
                                .filter(
                                  //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                  (value, index, self) =>
                                    self
                                      .map((x) => x.EMAIL)
                                      .indexOf(value.EMAIL) == index
                                )
                                .map((usersClients) => (
                                  <option value={usersClients.EMAIL}>
                                    {usersClients.EMAIL}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <input
                              class="form-input"
                              value={ZASTUP_CLIENT_SUCCESS_CONSULTANT}
                              readOnly
                            />
                          )}
                        </div>
                      </div>

                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Nákupčí
                        </label>
                        <div class="form__value">
                          {(props.user !== 4) & (edit === true) ? (
                            <select
                              class="form-select form-select--valid"
                              name="NAKUP"
                              value={NAKUP}
                              onChange={(e) => setNAKUP(e.target.value)}
                            >
                              <option value={NAKUP} selected disabled hidden>
                                {NAKUP}
                              </option>
                              <option value={CLIENT_LEAD}>Client Lead</option>
                              <option value={CLIENT_SUCCESS_CONSULTANT}>
                                Client Success Consultant
                              </option>
                              {usersClients
                                .filter(
                                  (o) =>
                                    (o.CLIENT_ID === ID_CLIENTS &&
                                      o.ROLE <= 4) ||
                                    o.ROLE == 1
                                )
                                .filter(
                                  //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                  (value, index, self) =>
                                    self
                                      .map((x) => x.EMAIL)
                                      .indexOf(value.EMAIL) == index
                                )
                                .map((usersClients) => (
                                  <option value={usersClients.EMAIL}>
                                    {usersClients.EMAIL}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <input class="form-input" value={NAKUP} readOnly />
                          )}
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Zastupující Nákupčí
                        </label>
                        <div class="form__value">
                          {edit === true ? (
                            <select
                              class="form-select form-select--valid"
                              name="ZASTUP_NAKUP"
                              value={ZASTUP_NAKUP}
                              onChange={(e) => setZASTUP_NAKUP(e.target.value)}
                            >
                              <option
                                value={ZASTUP_NAKUP}
                                selected
                                disabled
                                hidden
                              >
                                {ZASTUP_NAKUP}
                              </option>
                              <option value={CLIENT_LEAD}>Client Lead</option>
                              <option value={CLIENT_SUCCESS_CONSULTANT}>
                                Client Success Consultant
                              </option>
                              {usersClients
                                .filter(
                                  (o) =>
                                    (o.CLIENT_ID === ID_CLIENTS &&
                                      o.ROLE <= 4) ||
                                    o.ROLE == 1
                                )
                                .filter(
                                  //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                  (value, index, self) =>
                                    self
                                      .map((x) => x.EMAIL)
                                      .indexOf(value.EMAIL) == index
                                )
                                .map((usersClients) => (
                                  <option value={usersClients.EMAIL}>
                                    {usersClients.EMAIL}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <input
                              class="form-input"
                              value={ZASTUP_NAKUP}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="form">
                <div class="form__columns">
                  <div class="form__col">
                    <div class="form__col">
                      <form class="form__in">
                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Mediatyp
                          </label>
                          <div class="form__value">
                            <input
                              class="form-input"
                              value={MEDIATYP}
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            PO klienta
                          </label>
                          <div class="form__value">
                            {(props.user !== 4) & (edit === true) ? (
                              <input
                                class="form-input form-input--valid"
                                value={KLIENT_PO}
                                onChange={(e) => setKLIENT_PO(e.target.value)}
                              />
                            ) : (
                              <div class="form__value">
                                <input
                                  class="form-input"
                                  value={KLIENT_PO}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Začátek realizace zakázky
                          </label>
                          <div class="form__value">
                            {(props.user !== 4) & (edit === true) ? (
                              <input
                                class="form-input form-input--valid"
                                value={OD}
                                onChange={(e) => setOD(e.target.value)}
                              />
                            ) : (
                              <div class="form__value">
                                <input
                                  class="form-input"
                                  value={formatDate(OD)}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Ukončení realizace zakázky
                          </label>
                          <div class="form__value">
                            {(props.user !== 4) & (edit === true) ? (
                              <input
                                class="form-input form-input--valid"
                                value={DO}
                                onChange={(e) => setDO(e.target.value)}
                              />
                            ) : (
                              <div class="form__value">
                                <input
                                  class="form-input"
                                  value={formatDate(DO)}
                                  readOnly
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Stav zakázky
                          </label>
                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.STAV_ZAKAZKY}
                              readOnly
                            />
                          </div>
                        </div>
                        {/* split NAVAZANE_ZAKAZKY by , and display them in input tag and down show label*/}
                        {NAVAZANE_ZAKAZKY.split(",").map((zakazka, index) => (
                          <div class="form__item">
                            <label
                              for="id"
                              class="form-label form-label--inline"
                            >
                              {index === 0 ? "Navázané zakázky" : ""}
                            </label>
                            <div class="form__value">
                              <input
                                class="form-input"
                                value={zakazka}
                                readOnly
                              />
                            </div>
                          </div>
                        ))}
                      </form>
                    </div>
                  </div>
                </div>
                {props.user < 6 ? (
                  <div class="action-bar">
                    <div class="action-bar__in">
                      {edit ? (
                        <a
                          class="btn btn--medium btn--primary"
                          onClick={editZakazka}
                        >
                          Uložit změny
                        </a>
                      ) : (
                        <></>
                      )}
                      {edit ? (
                        <a
                          class="btn btn--medium btn--bordered"
                          onClick={AllowEdit}
                        >
                          Zrušit
                        </a>
                      ) : (
                        <a
                          class="btn btn--medium btn--transparent"
                          onClick={AllowEdit}
                        >
                          Editovat zakázku
                        </a>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
