import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Filter from "./Filter";
import Loader from "./Loader";
export default function SelectClientModalHelios(props) {
  const [modal, setModal] = useState(false);
  const [clients, setClients] = useState([]);

  const [clientsF, setClientsF] = useState([]);
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [sortDirection, setSortDirection] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const toggleModal = () => {
    setModal(!modal);
  };
  useEffect(() => {
    axios.get("/clients?queryDB=helios").then(
      (res) => {
        setClients(res.data);
        setClientsF(res.data); // len aktivny klienti;
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const PreFillOrder = (index) => {
    if (props.from == "update") {
      props.UpdateVal(clientsF[index]["Nazev"], "PARTNER");
      props.UpdateVal(clientsF[index]["CisloOrg"], "CISLOORG");
    } else {
      props.setPARTNER(clientsF[index]["Nazev"]);
      props.setCISLOORG(clientsF[index]["CisloOrg"]);
    }
  };

  const sortColumn = (colName) => {
    const values = [...clientsF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    values.sort((a, b) => {
      return sortDirection
        ? a[colName].localeCompare(b[colName])
        : b[colName].localeCompare(a[colName]);
    });
    setSortDirection(!sortDirection);
    setClientsF(values);
  };

  return (
    <>
      <a
        class="btn btn--small btn--primary"
        onClick={() => {
          toggleModal();
        }}
      >
        Vyberte dodavatele
      </a>
      {modal && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window modal-window--w800">
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Vyberte dodavatele</div>
              <div class="modal-window__content">
                {isLoading ? (
                  <Loader message="" />
                ) : (
                  <div class="table">
                    <div class="table__head">
                      <div class="table__row">
                        <div class="table__col table__col--w300">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => sortColumn("Nazev")}
                          >
                            Dodavatel
                          </span>
                          <Filter
                            filter={filter}
                            setFilter={setFilter}
                            filterVal={filterVal}
                            setFilterVal={setFilterVal}
                            table={clients}
                            filterInput={filterInput}
                            setFilterInput={setFilterInput}
                            setFilteredTable={setClientsF}
                            colName={"Nazev"}
                          />
                        </div>
                        <div class="table__col table__col--w280">IČO</div>
                        <div class="table__col table__col--flexible">DIČ</div>
                      </div>
                    </div>

                    <div class="table__body">
                      {clientsF.map((clients, index) => (
                        <div
                          class="table__row"
                          key={index}
                          onClick={() => {
                            toggleModal();
                            PreFillOrder(index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div class="table__col table__col--w300">
                            {clients.Nazev}
                          </div>
                          <div class="table__col table__col--w280">
                            {clients.ICO}
                          </div>
                          <div class="table__col table__col--flexible">
                            {clients.DIC}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div class="modal-window__actions">
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
