import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import Loader from "./Loader";
import { compareTime, IsValidDate } from "../helpers";
export default function Sidebar(props) {
  const [submenu, setSubmenu] = useState(false);
  const [selectedLink, setSelectedlink] = useState(0);
  const [filterInput, setFilterInput] = useState(
    sessionStorage.getItem("filterInput") !== null
      ? JSON.parse(sessionStorage.getItem("filterInput"))
      : [{ KAMPAN_ID: "" }]
  ); // vlozi key value stlpec:hodnota, ked vyprazdnujem filter tak je deletefilterinput[col]

  useEffect(() => {
    props.setLoading(true);

    axios.get("/myorders").then((res) => {
      console.log(res.data);
      localStorage.setItem("zakazka", JSON.stringify(res.data[0]["ZAKAZKY"]));
      props.setZakazka(res.data[0]["ZAKAZKY"]);
      props.setZakazkaFaktury(res.data[0]["FAKTURY"]);

      var values = res.data[0]["ZAKAZKY"];

      if (Object.keys(filterInput[0]).length > 0) {
        // check ci nieco vobec filtrujeme
        for (var key in filterInput[0]) {
          if (filterInput[0].hasOwnProperty(key)) {
            if ((key !== "DO") & (key !== "OD")) {
              var values = values.filter((o) =>
                o[key] !== null
                  ? o[key].toLowerCase().includes(filterInput[0][key])
                  : o[key]
              );
            } else {
              //OD DO filtruje ci bezi kampan medzi zadanymi datumami, ked je vyplneny iba jeden tak checkujem len z jednej strany
              var filter_od = filterInput[0]["OD"];
              var filter_do = filterInput[0]["DO"];

              if (filter_od != undefined) {
                if (IsValidDate(filter_od)) {
                  var values = values.filter(
                    (o) => new Date(o["DO"]) >= new Date(filter_od)
                  );
                }
              }
              if (filter_do != undefined) {
                if (IsValidDate(filter_do)) {
                  var values = values.filter(
                    (o) => new Date(o["OD"]) <= new Date(filter_do)
                  );
                }
              }
            }
          }
        }
      }

      props.setZakazkaF(values);
      props.setLoading(false);
    });

    axios.get("/emailsdisplay").then((res) => {
      console.log(res.data);
      props.setMails(res.data);
    });

    axios.get("/heliosinvoices?type=unassigned_invoices").then(
      (res) => {
        props.setUnassignedInv(res.data);
      },
      (err) => {
        console.log(err);
      }
    );

    axios.get("/heliosinvoices?type=deposit_invoices&kampan_id=all").then(
      (res) => {
        console.log(res.data);
        props.setDepositInvoices(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [props.rolesUpdated, props.emailDeleted]);

  if (props.isLoading) {
    // return <div class="loader__in"></div>;
    return <Loader message="" />;
  }

  return (
    <main class="content">
      <div class="container">
        <div class="content__in">
          <div class="content__side">
            <div class="side-panel">
              <div class="side-panel__in">
                <div class="side-panel__section">
                  <div class="side-menu">
                    <ul class="side-menu__list">
                      <li class="side-menu__item">
                        <Link to={"/Prehled/MyOrders"} className="text-link">
                          <a
                            href=""
                            className={
                              selectedLink == 0
                                ? "side-menu__link side-menu__link--active"
                                : "side-menu__link"
                            }
                            onClick={() => {
                              setSelectedlink(0);
                              setSubmenu(false);
                            }}
                          >
                            Moje zakázky
                            <span class="side-menu__count">
                              {"(" +
                                props.zakazka.filter(
                                  (o) =>
                                    compareTime(o.DO, props.historyFilter) ==
                                    true
                                ).length +
                                ")"}
                            </span>
                          </a>
                        </Link>
                      </li>

                      <li class="side-menu__item">
                        <Link
                          to={"/Prehled/unsortedcommunication"}
                          className="text-link"
                        >
                          <a
                            href=""
                            className={
                              selectedLink == 1
                                ? "side-menu__link side-menu__link--active"
                                : "side-menu__link"
                            }
                            onClick={() => {
                              setSelectedlink(1);
                              setSubmenu(false);
                            }}
                          >
                            Nezařazená pošta
                            <span class="side-menu__count">
                              {"(" + props.mails.length + ")"}
                            </span>
                          </a>
                        </Link>
                      </li>
                      {props.user == 1 ? (
                        <li class="side-menu__item">
                          <Link className="text-link">
                            <a
                              className="side-menu__link"
                              onClick={() => setSubmenu(!submenu)}
                            >
                              Faktury
                            </a>
                          </Link>
                          {submenu && (
                            <div class="side-submenu">
                              <ul class="side-submenu__list">
                                <li
                                  className={
                                    selectedLink == 3
                                      ? "side-submenu__item side-submenu__item--active"
                                      : "side-submenu__item"
                                  }
                                  onClick={() => {
                                    setSelectedlink(3);
                                  }}
                                >
                                  <Link
                                    to={"/Prehled/unassignedinvoicesbez"}
                                    className="text-link"
                                  >
                                    <a class="side-submenu__link">
                                      Bez přiřazené zakázky
                                      <span class="side-menu__count">
                                        {"(" +
                                          props.unassignedInv[0]["MISSING"]
                                            .length +
                                          ")"}
                                      </span>
                                    </a>
                                  </Link>
                                </li>
                                <li
                                  className={
                                    selectedLink == 4
                                      ? "side-submenu__item side-submenu__item--active"
                                      : "side-submenu__item"
                                  }
                                  onClick={() => {
                                    setSelectedlink(4);
                                  }}
                                >
                                  <Link
                                    to={"/Prehled/unassignedinvoicesspatne"}
                                    className="text-link"
                                  >
                                    <a class="side-submenu__link">
                                      Se špatně přiřazenou zakázkou
                                      <span class="side-menu__count">
                                        {"(" +
                                          props.unassignedInv[0]["UNORDERED"]
                                            .length +
                                          ")"}
                                      </span>
                                    </a>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    selectedLink == 5
                                      ? "side-submenu__item side-submenu__item--active"
                                      : "side-submenu__item"
                                  }
                                  onClick={() => {
                                    setSelectedlink(5);
                                  }}
                                >
                                  <Link
                                    to={"/Prehled/depositinvoices"}
                                    className="text-link"
                                  >
                                    <a h class="side-submenu__link">
                                      {"Zálohové"}
                                      <span class="side-menu__count">
                                        {"(" +
                                          [
                                            ...new Map(
                                              props.depositInvoices.map(
                                                (item) => [item["ID"], item]
                                              )
                                            ).values(),
                                          ].filter(
                                            (o) => o.SumaKcBezDPH - o.DRAW > 0.1
                                          ).length +
                                          ")"}
                                      </span>
                                    </a>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </main>
  );
}
