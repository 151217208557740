import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

export default function GeneralsModalSeen(props) {
  const MarkAsSeen = () => {
    const url =
      "/processcontinuation?from=" +
      "seen" +
      "&kampan_id=" +
      props.vybranaZakazka;

    axios.post(url).then((res) => {
      console.log(res.data);
    });

    props.setSeen(false);
  };

  return (
    <>
      {props.seen && (
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__in">
              <div class="modal-window__title">
                <div class="alert alert--warning">
                  V Heliosu došlo k změne u fakturačních entít. Zkontrolujte log
                  v procesu pro bližší informace.
                </div>
              </div>

              <div class="modal-window__actions">
                <a
                  className="btn btn--medium btn--bordered"
                  onClick={() => props.setSeen(false)}
                >
                  Rozumím. Ponechat upozornění pro další uživatelé.
                </a>

                <a class="btn btn--medium btn--bordered" onClick={MarkAsSeen}>
                  Rozumím. Zrušit upozornění pro všechny uživatelé.
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
