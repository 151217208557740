import React, { useState } from "react";

export default function ModalInfo(props) {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {props.infoBox && (
        <div class="modal-window-white">
          <div class="modal-window-white__wrap">
            <br></br>
            <div class="modal-window-white__in">
              <div class="modal-window-white__content">
                {props.isError ? (
                  <div class="alert alert--error">{props.infoBox}</div>
                ) : (
                  <div class="alert alert--success">{props.infoBox}</div>
                )}
              </div>

              <div class="modal-window-white__actions">
                <a
                  class="btn btn--medium btn--transparent"
                  onClick={() => props.setInfoBox("")}
                >
                  Ok
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*props.infoBox && (
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__title">
              {props.isError ? "CHYBA!" : "PROCES PREBEHOL USPESNE"}
            </div>

            <label for="" class="form-label">
              {props.infoBox}
            </label>

            <div class="modal-window__actions">
              <a
                class="btn btn--medium btn--transparent"
                onClick={() => props.setInfoBox("")}
              >
                Ok
              </a>
            </div>
          </div>
        </div>
      )*/}
    </>
  );
}
