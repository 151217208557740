import React from "react";
import { useEffect } from "react";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  IsValidDate,
  CheckValidPaste,
} from "../helpers";

export default function FiTextCastkaF(props) {
  // useEffect(() => {}, [props.polozky]);

  const UpdatePolozky = async (value, skupina, col) => {
    var values = [...props.polozky];
    /* var item = values.filter(
      (o) => (o.ID_HELIOS_P != null) & (o.SKUPINA == props.skupina)
    );*/
    values = values.map((o) =>
      o.SKUPINA === skupina ? { ...o, TEXT: value } : o
    );

    /* if (value == "") {
      values[idx][col] = null;
    } else {
      values[idx][col] = value;
    }*/
    console.log(values);
    props.setPolozky(values);
  };

  return (
    <>
      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for="id" class="form-label form-label--inline">
            Skupina
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {props.idx < 10 ? (
            <input
              class="form-input"
              value={
                props.polozky.find((o) => o.SKUPINA == props.skupina)["SKUPINA"]
              }
              key={props.idx}
            ></input>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div class={"modal-form__item"} key={props.idx + "1f"}>
        {props.idx == 0 ? (
          <label for={props.idx} class="form-label form-label--inline">
            Text
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {props.idx < 10 ? (
            <input
              class={
                props.polozky.find((o) => o.SKUPINA == props.skupina)["TEXT"] ==
                ""
                  ? "form-input form-input--invalid"
                  : "form-input"
              }
              key={props.idx + "asddsadsa"}
              placeholder={
                props.idx == 0
                  ? props.idx + 1 + ". Asd ghfr sd..."
                  : props.idx + 1 + "."
              }
              value={
                props.polozky.find((o) => o.SKUPINA == props.skupina)["TEXT"]
              }
              onChange={(e) => {
                UpdatePolozky(e.target.value, props.skupina, "TEXT");
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div class={"modal-form__item"} key={props.idx + "2f"}>
        {props.idx == 0 ? (
          <label for={props.idx} class="form-label form-label--inline">
            Kategorizace Položky
          </label>
        ) : (
          <></>
        )}

        <div class="form__value"></div>
      </div>

      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for="id" class="form-label form-label--inline">
            Hodnota
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {props.idx < 10 ? (
            <input
              class="form-input"
              value={numberWithSpaces(
                props.polozky
                  .filter(
                    (o) =>
                      (o.ID_HELIOS_P != null) & (o.SKUPINA == props.skupina)
                  )
                  .reduce((n, { AMOUNT }) => n + replaceComma(AMOUNT), 0)
              )}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for="id" class="form-label form-label--inline">
            Středisko
          </label>
        ) : (
          <></>
        )}

        <div class="form__value"></div>
      </div>

      {props.druhaZakazka == null ? (
        <></>
      ) : (
        <div class="modal-form__item">
          {props.idx == 0 ? (
            <label for="id" class="form-label form-label--inline">
              Zakázka
            </label>
          ) : (
            <></>
          )}

          <div class="form__value"></div>
        </div>
      )}
    </>
  );
}
