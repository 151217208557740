import React, { useEffect, useState } from "react";
import { formatDate } from "../helpers";
import Loader from "./Loader";
import { numberWithSpaces } from "../helpers";
export default function UnassignedInvoicesSpatne(props) {
  if (props.isLoading) {
    // return <div class="loader__in"></div>;
    return <Loader message="" />;
  }

  return (
    <div class="table">
      <div class="table__head">
        <div class="table__row">
          <div class="table__col table__col--pin">
            <div class="table-pin"></div>
          </div>

          <div class="table__col table__col--w200">Datum doručení</div>

          <div class="table__col table__col--w280">Číslo faktury</div>

          <div class="table__col table__col--w400">Popis</div>
          <div class="table__col table__col--w400">ID kampaně</div>

          <div class="table__col table__col--flexible">
            Fakturovaná částka (bez DPH)
          </div>
        </div>
      </div>

      <div class="table__body">
        {props.unassignedInv[0]["UNORDERED"].map(
          (unassignedInvoices, index) => (
            <div class="table__row" key={index}>
              <div class="table__col table__col--pin">
                <div class="table-pin"></div>
              </div>

              <div class="table__col table__col--w200">
                {formatDate(unassignedInvoices.INVOICE_PERIOD)}
              </div>
              <div class="table__col table__col--w280">
                {unassignedInvoices.INVOICE_ALIAS_PORCIS}
              </div>
              <div class="table__col table__col--w400">
                {unassignedInvoices.NOTE}
              </div>
              <div class="table__col table__col--w400">
                {unassignedInvoices.ID_KAMPAN}
              </div>

              <div class="table__col table__col--flexible">
                {numberWithSpaces(unassignedInvoices.INVOICE_AMOUNT)}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
