import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import ModalInfo from "./modal_info";
import SelectClientModalHelios from "./Modal_select_client_helios";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
} from "../helpers";

export default function ModalFiEvidence(props) {
  const [validace, setValidace] = useState("");

  const [ID_MEDIATYP, setID_MEDIATYP] = useState();
  const [DATUM_UVEREJNENI_M, setDATUM_UVEREJNENI_M] = useState("");
  const [CNNN, setCNNN] = useState("");
  const [MNNP, setMNNP] = useState("");
  const [FEE, setFEE] = useState("");

  const [NOTE_1, setNOTE_1] = useState("");
  const [PARTNER, setPARTNER] = useState("");
  const [PARTNER_KAT, setPARTNER_KAT] = useState("");

  const [CISLOORG, setCISLOORG] = useState();

  const [NAZEV_SLUZBY, setNAZEV_SLUZBY] = useState("");

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  //nazev klient alebo dodavatel
  const Evidate = () => {
    if (props.vybranyMediatyp == "Fee" || props.vybranyMediatyp == "Kickback") {
      if (ID_MEDIATYP == undefined) {
        setValidace("ID_MEDIATYP");
        return;
      }
    }

    if (
      props.vybranyMediatyp == "Other services" ||
      props.vybranyMediatyp == "Internet"
    ) {
      if (NAZEV_SLUZBY == "") {
        setValidace("NAZEV_SLUZBY");
        return;
      }
    }

    if (DATUM_UVEREJNENI_M == "") {
      setValidace("DATUM_UVEREJNENI_M");
      return;
    }
    if (props.vybranyMediatyp != "Internet") {
      if (CNNN == "") {
        setValidace("CNNN");
        return;
      }
    }

    if (props.vybranyMediatyp == "Internet") {
      if (MNNP == "") {
        setValidace("MNNP");
        return;
      }
      if (FEE == "") {
        setValidace("FEE");
        return;
      }
      if (PARTNER.includes("Seznam") & (PARTNER_KAT == "")) {
        setValidace("PARTNER_KAT");
        return;
      }
    }

    var data;
    if (props.vybranyMediatyp == "Fee" || props.vybranyMediatyp == "Kickback") {
      data = {
        ID_MEDIATYP: ID_MEDIATYP,
        DATUM_UVEREJNENI_M: DATUM_UVEREJNENI_M + "-01",
        CNNN: replaceComma(CNNN),
        NOTE_1: NOTE_1 == "" ? null : NOTE_1,
      };
    }
    if (props.vybranyMediatyp == "Other services") {
      data = {
        CISLOORG: CISLOORG == undefined ? null : CISLOORG,
        PARTNER: PARTNER == "" ? null : PARTNER,
        NAZEV_SLUZBY: NAZEV_SLUZBY,
        DATUM_UVEREJNENI_M: DATUM_UVEREJNENI_M + "-01",
        CNNN: replaceComma(CNNN),
        NOTE_1: NOTE_1 == "" ? null : NOTE_1,
      };
    }
    if (props.vybranyMediatyp == "Internet") {
      data = {
        CISLOORG: CISLOORG == undefined ? null : CISLOORG,
        PARTNER: PARTNER == "" ? null : PARTNER,
        PARTNER_KAT: PARTNER_KAT == "" ? null : PARTNER_KAT,
        NAZEV_SLUZBY: NAZEV_SLUZBY,
        DATUM_UVEREJNENI_M: DATUM_UVEREJNENI_M + "-01",
        MNNP: replaceComma(MNNP),
        FEE: replaceComma(FEE),
        CNNN: replaceComma(MNNP) + replaceComma(FEE),
        NOTE_1: NOTE_1 == "" ? null : NOTE_1,
      };
    }
    setIsRunning(true);
    setInfoMessage("Evidují položku.");

    const url_query_string =
      "financialinsight?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&type=evidate";

    axios
      .post(url_query_string, data)
      .then((res) => {
        console.log(res.data);
        props.setFinancialInsight(res.data);
        props.setFinancialInsightF(res.data);
        props.setModalEvidence(!props.setModalEvidence);
        setIsRunning(false);
        props.setIsError(false);
        props.setInfoBox("Fakturační položka byla úspěšne zaevidována.");
        resetState();
      })
      .catch((err) => {
        console.log(err);

        const st_err =
          "Neočekávaná chyba! Fakturační položku se nezdařilo zaevidovat!";
        const date_err = "Špatně zadaný datum! Správny formát je YYYY-MM!";

        try {
          err.response.status == 402
            ? err.response.data == "date"
              ? props.setInfoBox(date_err)
              : props.setInfoBox(st_err)
            : props.setInfoBox(st_err);
        } catch (err) {
          props.setInfoBox(st_err);
        }

        setIsRunning(false);
        props.setIsError(true);
      });
  };

  const resetState = () => {
    setID_MEDIATYP();
    setDATUM_UVEREJNENI_M("");
    setCNNN("");
    setMNNP("");
    setFEE("");
    setNOTE_1("");
    setPARTNER("");
    setPARTNER_KAT("");
    setCISLOORG();
    setNAZEV_SLUZBY("");
  };

  const toggleModal = () => {
    props.setModalEvidence(!props.modalEvidence);
    resetState();
  };

  return (
    <>
      {props.modalEvidence && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window modal-window--w680">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={infoMessage}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Evidence fakturační položky</div>
              <div class="modal-window__content">
                <div class="modal-form">
                  <form class="modal-form__in">
                    {props.vybranyMediatyp == "Other services" ||
                    props.vybranyMediatyp == "Internet" ? (
                      <>
                        <div class="modal-form__item">
                          <label for="id" class="form-label form-label--inline">
                            Dodavatel
                          </label>

                          <div class="modal-form__value">
                            {PARTNER != "" ? (
                              <input
                                class="form-input"
                                placeholder="Povinné"
                                value={PARTNER}
                                readOnly
                              />
                            ) : (
                              <SelectClientModalHelios
                                setPARTNER={setPARTNER}
                                setCISLOORG={setCISLOORG}
                              />
                            )}
                          </div>
                        </div>
                        <div class="modal-form__item">
                          <label for="id" class="form-label form-label--inline">
                            Název služby
                          </label>

                          <div class="modal-form__value">
                            <input
                              class="form-input"
                              placeholder="Povinné"
                              value={NAZEV_SLUZBY}
                              onChange={(e) => setNAZEV_SLUZBY(e.target.value)}
                            />
                          </div>
                          {validace == "NAZEV_SLUZBY" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {props.vybranyMediatyp == "Fee" ||
                    props.vybranyMediatyp == "Kickback" ? (
                      <div class="modal-form__item">
                        <label for="id" class="form-label form-label--inline">
                          {props.vybranyMediatyp == "Fee" ? "Fee" : "Kickback"}{" "}
                          se váže k mediatypu
                        </label>
                        <div class="modal-form__value">
                          <select
                            class="form-select"
                            value={ID_MEDIATYP}
                            onChange={(e) => setID_MEDIATYP(e.target.value)}
                          >
                            <option value="" selected disabled hidden>
                              Povinné
                            </option>
                            <option value={1}>TV</option>
                            <option value={2}>Internet</option>
                            <option value={3}>Print</option>
                            <option value={4}>Radio</option>
                            <option value={5}>OOH</option>
                            <option value={6}>Cinema</option>
                            <option value={8}>Other</option>
                          </select>
                        </div>
                        {validace == "ID_MEDIATYP" ? (
                          <div class="validation">
                            <div class="validation__item">Povinné pole!</div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
                {(props.vybranyMediatyp == "Internet") &
                PARTNER.includes("Seznam") ? (
                  <>
                    <br></br>
                    <div class="form">
                      <form class=" form__in">
                        <div class="form__item">
                          <label
                            for="id"
                            class="form-label form-label--inline-evidence"
                          >
                            Dodavatel - kategorie
                          </label>

                          <div class="form__value">
                            <select
                              class="form-select"
                              value={PARTNER_KAT}
                              onChange={(e) => setPARTNER_KAT(e.target.value)}
                            >
                              <option value="" selected disabled hidden>
                                Povinné
                              </option>
                              <option value={"A"}>A</option>
                              <option value={"B"}>B</option>
                              <option value={"C"}>C</option>
                            </select>
                          </div>
                          {validace == "PARTNER_KAT" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </form>
                    </div>
                  </>
                ) : (
                  <br></br>
                )}

                <div class="modal-form">
                  <form class="modal-form__in">
                    <div class="modal-form__item">
                      <label for="id" class="form-label form-label--inline">
                        Fakturované období
                      </label>

                      <div class="modal-form__value">
                        <input
                          class="form-input"
                          placeholder="YYYY-MM - Povinné"
                          value={DATUM_UVEREJNENI_M}
                          onChange={(e) =>
                            setDATUM_UVEREJNENI_M(e.target.value)
                          }
                        />
                      </div>
                      {validace == "DATUM_UVEREJNENI_M" ? (
                        <div class="validation">
                          <div class="validation__item">Povinné pole!</div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {props.vybranyMediatyp == "Other services" ||
                    props.vybranyMediatyp == "Fee" ||
                    props.vybranyMediatyp == "Kickback" ? (
                      <div class="modal-form__item">
                        <label for="id" class="form-label form-label--inline">
                          {props.vybranyMediatyp == "Other services"
                            ? "Prodej - částka (bez DPH)"
                            : ""}
                          {props.vybranyMediatyp == "Fee"
                            ? "Cost to Client"
                            : ""}
                        </label>

                        <div class="modal-form__value">
                          <input
                            class="form-input"
                            onKeyDown={(e) => checkValidInput(e)}
                            value={CNNN}
                            placeholder="150000.. - Povinné"
                            onChange={(e) => setCNNN(e.target.value)}
                          />
                        </div>
                        {validace == "CNNN" ? (
                          <div class="validation">
                            <div class="validation__item">Povinné pole!</div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {props.vybranyMediatyp == "Internet" ? (
                      <>
                        <div class="modal-form__item">
                          <label for="id" class="form-label form-label--inline">
                            Media Net Net Prodej
                          </label>

                          <div class="modal-form__value">
                            <input
                              class="form-input"
                              onKeyDown={(e) => checkValidInput(e)}
                              value={MNNP}
                              placeholder="150000.. - Povinné"
                              onChange={(e) => setMNNP(e.target.value)}
                            />
                          </div>
                          {validace == "MNNP" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div class="modal-form__item">
                          <label for="id" class="form-label form-label--inline">
                            Fee
                          </label>

                          <div class="modal-form__value">
                            <input
                              class="form-input"
                              onKeyDown={(e) => checkValidInput(e)}
                              value={FEE}
                              placeholder="150000.. - Povinné"
                              onChange={(e) => setFEE(e.target.value)}
                            />
                          </div>
                          {validace == "FEE" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div class="modal-form__item">
                          <label for="id" class="form-label form-label--inline">
                            Cost to Client
                          </label>

                          <div class="modal-form__value">
                            <input
                              class="form-input"
                              value={numberWithSpaces(
                                replaceComma(MNNP == "" ? 0 : MNNP) +
                                  replaceComma(FEE == "" ? 0 : FEE)
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div class="modal-form__item"></div>
                    <div class="modal-form__item modal-form__item--full">
                      <label for="id" class="form-label form-label--inline">
                        Komentář
                      </label>

                      <div class="modal-form__value">
                        <textarea
                          placeholder="Max. 250 znaků.."
                          type="text"
                          class="form-textarea"
                          value={NOTE_1}
                          onChange={(e) => setNOTE_1(e.target.value)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <br></br>
              <div class="modal-window__actions">
                <a class="btn btn--medium btn--primary" onClick={Evidate}>
                  Uložit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
