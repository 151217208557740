import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  CheckValidPaste,
} from "../helpers";
import FormUpEvFeeOs from "./Order-Form_up_ev_fee_os";

export default function ModalInvoicesEvidenceOS(props) {
  const [isRunning, setIsRunning] = useState(false);

  const EvidateInvoice = async () => {
    for (let i = 0; i < props.mediaplanAgg.length; i++) {
      props.mediaplanAgg[i].CNNN = parseFloat(
        props.mediaplanAgg[i].CNNN.toString().replace(",", ".")
      );
    }

    const data = {
      DATA: props.mediaplanAgg[0],
      INV: props.invoiceToEvidate,
    };
    console.log(data);

    setIsRunning(true);

    const url_query_string =
      "invoiceevidence?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&invoice_id=" +
      props.invoiceToEvidate +
      "&typ=evidence&mass=false";

    await axios
      .post(url_query_string, data)
      .then((res) => {
        setIsRunning(false);
        props.setVybranyClient(false);
        props.setRecievedInvoices(res.data);
        props.setRecievedInvoicesF(res.data);
        props.setModalInvoiceToEvidate(false);
        props.setModalToEvidate(false);
        props.setIsError(false);
        props.setModalAction(false);
        props.setInfoBox("Faktura byla úspěšne zaevidována.");
      })
      .catch((err) => {
        setIsRunning(false);
        props.setIsError(true);
        props.setInfoBox("Fakturu se nezdařilo zaevidovat!");
        console.log(err);
      });

    const url =
      "/financialinsight?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp;

    await axios.get(url).then(
      (res) => {
        props.setFinancialInsightModal(res.data);
        props.setFinancialInsightModalF(res.data);
      },
      (err) => {}
    );
  };

  const toggleModal = () => {
    console.log(props.mediaplanAgg[0]["ID"]);

    props.setModalInvoiceToEvidate(!props.modalInvoiceToEvidate);
    props.setModalAction(false);
  };

  const UpdateInvoice = (value, name) => {
    const values = [...props.mediaplanAgg];
    values[0][name] = value;

    props.setMediaplanAgg(values);
  };

  return (
    <>
      {props.modalInvoiceToEvidate && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Evidují fakturu."}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Evidence Faktury</div>
              <div class="modal-window__content">
                <div class="form">
                  <FormUpEvFeeOs
                    UpdateVal={UpdateInvoice}
                    myArr={props.mediaplanAgg}
                    user={props.user}
                    validace={""}
                  />
                </div>
              </div>
              <br></br>
              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--primary"
                  onClick={EvidateInvoice}
                >
                  Uložit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
