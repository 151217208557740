import React, { useState } from "react";
import { IsValidDate } from "../helpers";

export default function FilterDurable(props) {
  const TurnFilter = (colName) => {
    var values = [...props.filter];
    values[0][colName] = !values[0][colName];
    props.setFilter(values);
    sessionStorage.setItem("filter", JSON.stringify(values));
  };
  const DoNothing = () => {};
  const FilterCol = (value, col) => {
    var values = [...props.table];
    // var values_2 = [...props.filterInput];

    value !== ""
      ? (props.filterInput[0][col] = value.toLowerCase()) // pridavam stlpec s hodnotu do filtrovania, ked ideme na "" tak vymazujem z filtrovania
      : delete props.filterInput[0][col];

    if (Object.keys(props.filterInput[0]).length > 0) {
      // check ci nieco vobec filtrujeme
      for (var key in props.filterInput[0]) {
        if (props.filterInput[0].hasOwnProperty(key)) {
          if ((key !== "DO") & (key !== "OD")) {
            var values = values.filter((o) =>
              o[key] !== null
                ? o[key].toLowerCase().includes(props.filterInput[0][key])
                : o[key]
            );
          } else {
            //OD DO filtruje ci bezi kampan medzi zadanymi datumami, ked je vyplneny iba jeden tak checkujem len z jednej strany
            var filter_od = props.filterInput[0]["OD"];
            var filter_do = props.filterInput[0]["DO"];

            if (filter_od != undefined) {
              if (IsValidDate(filter_od)) {
                var values = values.filter(
                  (o) => new Date(o["DO"]) >= new Date(filter_od)
                );
              }
            }
            if (filter_do != undefined) {
              if (IsValidDate(filter_do)) {
                var values = values.filter(
                  (o) => new Date(o["OD"]) <= new Date(filter_do)
                );
              }
            }
          }
        }
      }
    }

    props.setFilterInput(props.filterInput);
    sessionStorage.setItem("filterInput", JSON.stringify(props.filterInput));

    props.setFilteredTable(values);
  };

  return (
    <>
      <div class="table__toggle" onClick={() => TurnFilter(props.colName)}>
        <div class="icon">
          <svg>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="/assets/img/sprite.svg#chevron-down"
            />
          </svg>
        </div>
      </div>
      <div
        class={
          props.filter[0][props.colName]
            ? "table-filter table-filter--active"
            : "table-filter"
        }
      >
        <div class="table-filter__in">
          <div class="table-filter-search__in">
            <div class="table-filter-search__icon">
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#search"
                  />
                </svg>
              </div>
            </div>

            <input
              type="text"
              class={
                //chceme len podsvietit datumovy filter, ked je tam zly format datumu a ked dobry tak zvyraznit
                props.colName == "OD" || props.colName == "DO"
                  ? props.filterInput[0][props.colName] != undefined
                    ? IsValidDate(props.filterInput[0][props.colName])
                      ? "table-filter-search__input form-input  form-input--valid"
                      : "table-filter-search__input form-input  form-input--invalid"
                    : "table-filter-search__input form-input"
                  : "table-filter-search__input form-input"
              }
              placeholder="Hledat"
              value={props.filterInput[0][props.colName]}
              //value={sessionStorage.getItem("filterInput")[props.colName]}
              onChange={(e) => {
                FilterCol(e.target.value, props.colName);
                ["OD", "DO"].includes(props.colName)
                  ? props.setFakturovano(!props.fakturovano)
                  : DoNothing();
              }}
            ></input>
          </div>
        </div>
      </div>
    </>
  );
}
