import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";

export default function ModalInvoicesAction(props) {
  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setinfoMessage] = useState("");

  const toggleModal = () => {
    props.setModalAction(!props.modalAction);
  };

  const UnorderInvoice = () => {
    const url_query_string =
      "/heliosinvoices?kampan_id=" +
      props.vybranaZakazka +
      "&faktura=" +
      props.invoiceToEvidate[0]["ID"] +
      "&type=unorder";

    setIsRunning(true);
    setinfoMessage("Přesouvám fakturu.");

    axios.post(url_query_string).then(
      (res) => {
        console.log(res.data);

        props.setRecievedInvoices(res.data);
        props.setRecievedInvoicesF(res.data);
        setIsRunning(false);
        props.setModalAction(!props.modalAction);
      },
      (err) => {
        console.log(err);
      }
    );
    props.setModalAction(!props.modalAction);
  };
  return (
    <>
      <ModalRunning
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      {props.modalAction && (
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              {
                /*props.action == "" ? (
                <>
                  <div class="modal-window__title">
                    Co chcete udělat s fakturou
                    {props.invoiceToEvidate[0]["INVOICE_ALIAS_PORCIS"]}?
                  </div>
                  <div class="modal-window__actions">
                    {(props.invoiceToEvidate[0]["INVOICE_TYPE"] != 13) &
                    (props.invoiceToEvidate[0]["INVOICE_TYPE"] != 14) ? (
                      <a
                        class="btn btn--medium btn--primary"
                        onClick={() => {
                          props.setModalToEvidate(true);
                        }}
                      >
                        Evidovat
                      </a>
                    ) : (
                      <></>
                    )}

                    <a
                      class="btn btn--medium btn--red"
                      onClick={() => props.setAction("unorder")}
                    >
                      Přesunout do nezařazených
                    </a>
                  </div>
                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Storno
                    </a>
                  </div>
                </>
              ) : (*/
                <>
                  <div class="modal-window__title">
                    Opravdu chcete fakturu{" "}
                    {props.invoiceToEvidate[0]["INVOICE_ALIAS_PORCIS"]}{" "}
                    přiřazenou k zakázce {props.vybranaZakazka} přesunout medzi
                    nezařazené faktury?
                  </div>
                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--red"
                      onClick={UnorderInvoice}
                    >
                      Ano
                    </a>
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Ne
                    </a>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}
