import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { compareTime, IsValidDate } from "../helpers";

export default function SafeKurzyClient(props) {
  const [submenu, setSubmenu] = useState(false);
  const [selectedLink, setSelectedlink] = useState(0);

  return (
    <div class="content__side">
      <div class="side-panel">
        <div class="side-panel__in">
          <div class="side-panel__section">
            <div class="side-menu">
              <ul class="side-menu__list">
                <li class="side-menu__item">
                  <a
                    className={
                      selectedLink == 0
                        ? "side-menu__link side-menu__link--active"
                        : "side-menu__link"
                    }
                    onClick={() => {
                      setSelectedlink(0);
                      props.setSelectedClient(0);
                    }}
                  >
                    Obecné
                  </a>
                </li>
                {[...new Set(props.safeKurzy.map((sk) => sk.CISLOORG))]
                  .map((cisloorg) => {
                    const client = props.safeKurzy.find(
                      (sk) => sk.CISLOORG === cisloorg
                    );
                    return { CISLOORG: cisloorg, CLIENT: client.CLIENT };
                  }) //array s unique client and cisloorg
                  .map((sk, index) => (
                    <li class="side-menu__item">
                      <a
                        className={
                          selectedLink == sk.CISLOORG
                            ? "side-menu__link side-menu__link--active"
                            : "side-menu__link"
                        }
                        onClick={() => {
                          setSelectedlink(sk.CISLOORG);
                          props.setSelectedClient(sk.CISLOORG);
                        }}
                      >
                        {sk.CLIENT}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
