import axios from "axios";
import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import Loader from "./Loader";

export default function Downloads(props) {
  const [isLoading, setLoading] = useState(true);

  useEffect(async () => {
    let urldown;
    let filename;

    var url_query_string =
      "/emailsdownload" + sessionStorage.getItem("zs_mind2_url_params");

    if (
      (sessionStorage.getItem("zs_mind2_url_params") != undefined) &
      (sessionStorage.getItem("zs_mind2_url_params") != "")
    ) {
      const response = await axios({
        method: "GET",
        url: url_query_string,
      });
      console.log(response);
      urldown = response.data[0];
      filename = response.data[1];
      //na presignedurl pouzivame fetch lebo ta nejde posielat TOKENY!!!!
      const uninterceptedAxiosInstance = axios.create();

      uninterceptedAxiosInstance(urldown, {
        method: "GET", //,
        responseType: "blob",
      })
        .then((res) => {
          fileDownload(res.data, filename);
        })
        .catch((err) => {
          console.log(err);
        });

      sessionStorage.removeItem("zs_mind2_url_params");
    }
    setLoading(false);
  }, []);
  if (isLoading) {
    return <Loader message="Stahují přílohu." />;
  }

  return <></>;
}
