import React from "react";

export default function Validation(props) {
  return (
    <>
      {props.validace === props.validace_custom ? (
        <div class="validation">
          <div class="validation__item">{props.message}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
