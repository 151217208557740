import React, { useState } from "react";

export default function ModalInfoList(props) {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {props.infoBox && (
        <div class="modal-window-white">
          <div class="modal-window-white__wrap">
            <br></br>
            <div class="modal-window-white__in">
              <div class="modal-window-white__content">
                {props.isError ? (
                  <div class="alert alert--error">{props.infoBox}</div>
                ) : (
                  <>
                    <div class="alert alert--success">{props.infoBox}</div>
                    {props.list.length > 0 ? (
                      <>
                        <br></br>
                        <div class="form">
                          <form class="form__in">
                            {props.list.map((item) => (
                              <div class="form__item">
                                <div class="form__value">
                                  <label
                                    for="id"
                                    class="form-label form-label--inline"
                                  >
                                    {item[props.colName]}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </form>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>

              <div class="modal-window-white__actions">
                <a
                  class="btn btn--medium btn--transparent"
                  onClick={() => props.setInfoBox("")}
                >
                  Ok
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
