import React, { useState } from "react";

export default function ModalInfoRedirect(props) {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {props.infoBox && (
        <div class="modal-window-white">
          <div class="modal-window-white__wrap">
            <br></br>
            <div class="modal-window-white__in">
              <div class="modal-window-white__content">
                {props.isError ? (
                  <div class="alert alert--error">{props.infoBox}</div>
                ) : (
                  <div class="alert alert--success">{props.infoBox}</div>
                )}
              </div>

              <div class="modal-window-white__actions">
                <a
                  class="btn btn--medium btn--bordered"
                  onClick={() => props.setInfoBox("")}
                >
                  Ok
                </a>
                <a class="btn btn--medium btn--bordered" href={props.newOrder}>
                  Přejít k zakázce
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
