import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FilterDurable from "./FilterDurable.js";
import ModalMassEdit from "./MyOrders-Modal_mass_edit.js";
import ModalInfo from "./modal_info.js";
import ModalList from "./MyOrders-Modal_list.js";
import ShowHideCol from "./Show_hide_col.js";
import { formatDate, compareTime, IsValidDate } from "../helpers.js";

export default function MyOrders(props) {
  const [sortDirection, setSortDirection] = useState(false);
  /*
  const [filter, setFilter] = useState([{ INIT: false }]); //vlozi stlpce ktore maju aktivovany filter, resp. otvoreny
  const [filterInput, setFilterInput] = useState([]); // vlozi key value stlpec:hodnota, ked vyprazdnujem filter tak je deletefilterinput[col]
  */

  const [filter, setFilter] = useState(
    sessionStorage.getItem("filter") !== null
      ? JSON.parse(sessionStorage.getItem("filter"))
      : [{ INIT: false }]
  ); //vlozi stlpce ktore maju aktivovany filter, resp. otvoreny
  const [filterInput, setFilterInput] = useState(
    sessionStorage.getItem("filterInput") !== null
      ? JSON.parse(sessionStorage.getItem("filterInput"))
      : [{ KAMPAN_ID: "" }]
  ); // vlozi key value stlpec:hodnota, ked vyprazdnujem filter tak je deletefilterinput[col]

  const [hromadnaEditace, setHromadnaEditace] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalList, setModalList] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersClients, setUsersClients] = useState([]);

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [show, setShow] = useState(0);
  const [fakturovano, setFakturovano] = useState(0);

  useEffect(() => {
    const values = [...props.zakazkaF];
    const values_2 = [...props.zakazka];

    function GetDate(date, date_od) {
      if (date != undefined) {
        if (IsValidDate(date)) {
          return date;
        } else {
          return date_od ? "1999-01-01" : "2055-12-31";
        }
      } else {
        return date_od ? "1999-01-01" : "2055-12-31";
      }
    }

    var filter_od = GetDate(filterInput[0]["OD"], true);
    var filter_do = GetDate(filterInput[0]["DO"], false);

    function StavFakturace(values, setStateF, od_param, do_param) {
      for (let i = 0; i < values.length; i++) {
        if (
          props.zakazkaFaktury.filter(
            (o) =>
              (o.KAMPAN_ID === values[i].KAMPAN_ID) &
                (compareTime(o.OBDOBI.split("T")[0], od_param) == true) &&
              compareTime(do_param, o.OBDOBI.split("T")[0]) == true
          ).length > 0
        ) {
          values[i]["STAV_FAKTURACE"] = "Ano";
        } else {
          values[i]["STAV_FAKTURACE"] = "Ne";
        }
      }

      setStateF(values);
    }
    StavFakturace(values, props.setZakazkaF, filter_od, filter_do);
    StavFakturace(values_2, props.setZakazka, filter_od, filter_do);
  }, [fakturovano]);

  const selectZakazka = (index) => {
    const values = [...props.zakazkaF];

    values[index]["SELECTED"] =
      values[index]["SELECTED"] == 0 || values[index]["SELECTED"] == null
        ? 1
        : 0;

    props.setZakazkaF(values);
  };

  const SelectAll = () => {
    const values = [...props.zakazkaF];
    var value_to_change = selectAll == false ? 1 : 0;

    values.map(function (x) {
      x.SELECTED = value_to_change;
      return x;
    });

    setSelectAll(!selectAll);
    props.setZakazkaF(values);
  };

  const CheckEdit = () => {
    const data = props.zakazkaF.filter((o) => o.SELECTED == 1);

    if (data.length === 0) {
      setIsError(true);
      setInfoBox("Vyberte alespoň jednu zakázku!");
    } else {
      setModalList(true);
    }
  };

  const sortColumn = (colName) => {
    const values = [...props.zakazkaF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));

    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    props.setZakazkaF(values);
  };

  return (
    <>
      {modal == true ? (
        <ModalMassEdit
          modal={modal}
          setModal={setModal}
          users={users}
          setUsers={setUsers}
          zakazkaF={props.zakazkaF}
          user={props.user}
          usersClients={usersClients}
          setUsersClients={setUsersClients}
          rolesUpdated={props.rolesUpdated}
          setRolesUpdated={props.setRolesUpdated}
        />
      ) : (
        <></>
      )}

      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      <ModalList
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
        zakazkaF={props.zakazkaF}
        modalList={modalList}
        setModalList={setModalList}
        modal={modal}
        setModal={setModal}
        users={users}
        setUsers={setUsers}
        usersClients={usersClients}
        setUsersClients={setUsersClients}
      />

      <div class="table-container table-container__height">
        <div class="table">
          <div class="table__head">
            <div class="table__row">
              {hromadnaEditace == 0 ? (
                <div class="table__col table__col--notif"></div>
              ) : (
                <div class="table__col table__col--pin">
                  <div class="table-pin">
                    <div class="icon">
                      <svg>
                        <use
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xlinkHref="/assets/img/sprite.svg#asd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )}

              <div
                class={
                  ((filterInput[0].KAMPAN_ID != "") &
                  (filterInput[0].KAMPAN_ID != undefined)
                    ? "table__col--filtered "
                    : "") + "table__col table__col--w160"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("KAMPAN_ID")}
                >
                  ID zakázky s mediatypem
                </span>
                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"KAMPAN_ID"}
                />
              </div>

              <div
                class={
                  (filterInput[0].NAZEV_ZAKAZKY != undefined
                    ? "table__col--filtered "
                    : "") + "table__col table__col--w400"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("KAMPAN_ID")}
                >
                  Název
                </span>

                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"NAZEV_ZAKAZKY"}
                />
              </div>

              <div
                class={
                  (filterInput[0].CLIENT != undefined
                    ? "table__col--filtered "
                    : "") + "table__col table__col--w280"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("CLIENT")}
                >
                  Klient
                </span>
                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"CLIENT"}
                />
              </div>

              <div
                class={
                  (filterInput[0].MEDIATYP != undefined
                    ? "table__col--filtered "
                    : "") + "table__col table__col--w140"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("MEDIATYP")}
                >
                  Mediatyp
                </span>

                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"MEDIATYP"}
                />
              </div>

              <div
                class={
                  (filterInput[0].OD != undefined
                    ? "table__col--filtered "
                    : "") + "table__col table__col--w160"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("OD")}
                >
                  Realizace od
                </span>

                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"OD"}
                  setFakturovano={setFakturovano}
                  fakturovano={fakturovano}
                />
              </div>
              <div
                class={
                  (filterInput[0].DO != undefined
                    ? "table__col--filtered "
                    : "") + "table__col table__col--w160"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("DO")}
                >
                  Realizace do
                </span>

                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"DO"}
                  setFakturovano={setFakturovano}
                  fakturovano={fakturovano}
                />
              </div>
              <div
                class={
                  (filterInput[0].STAV_FAKTURACE != undefined
                    ? "table__col--filtered "
                    : "") + "table__col table__col--w140"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("STAV_FAKTURACE")}
                >
                  Fakturováno
                </span>

                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"STAV_FAKTURACE"}
                />
                {show <= 4 ? (
                  <ShowHideCol show="plus" setShow={setShow} showValue={show} />
                ) : (
                  <></>
                )}
                {show >= 1 ? (
                  <ShowHideCol
                    show="minus"
                    setShow={setShow}
                    showValue={show}
                  />
                ) : (
                  <></>
                )}
              </div>
              {show >= 1 ? (
                <div
                  class={
                    (filterInput[0].CLIENT_LEAD_FULL_NAME != undefined
                      ? "table__col--filtered "
                      : "") + "table__col table__col--w160"
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => sortColumn("CLIENT_LEAD_FULL_NAME")}
                  >
                    Client Lead
                  </span>

                  <FilterDurable
                    filter={filter}
                    setFilter={setFilter}
                    table={props.zakazka}
                    filterInput={filterInput}
                    setFilterInput={setFilterInput}
                    setFilteredTable={props.setZakazkaF}
                    colName={"CLIENT_LEAD_FULL_NAME"}
                  />
                </div>
              ) : (
                <></>
              )}
              {show >= 2 ? (
                <div
                  class={
                    (filterInput[0].CLIENT_SUCCESS_CONSULTANT_FULL_NAME !=
                    undefined
                      ? "table__col--filtered "
                      : "") + "table__col table__col--w160"
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      sortColumn("CLIENT_SUCCESS_CONSULTANT_FULL_NAME")
                    }
                  >
                    CSC
                  </span>

                  <FilterDurable
                    filter={filter}
                    setFilter={setFilter}
                    table={props.zakazka}
                    filterInput={filterInput}
                    setFilterInput={setFilterInput}
                    setFilteredTable={props.setZakazkaF}
                    colName={"CLIENT_SUCCESS_CONSULTANT_FULL_NAME"}
                  />
                </div>
              ) : (
                <></>
              )}
              {show >= 3 ? (
                <div
                  class={
                    (filterInput[0].NAKUP_FULL_NAME != undefined
                      ? "table__col--filtered "
                      : "") + "table__col table__col--w160"
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => sortColumn("NAKUP_FULL_NAME")}
                  >
                    Nákupčí
                  </span>

                  <FilterDurable
                    filter={filter}
                    setFilter={setFilter}
                    table={props.zakazka}
                    filterInput={filterInput}
                    setFilterInput={setFilterInput}
                    setFilteredTable={props.setZakazkaF}
                    colName={"NAKUP_FULL_NAME"}
                  />
                </div>
              ) : (
                <></>
              )}
              {show >= 4 ? (
                <div
                  class={
                    (filterInput[0]
                      .ZASTUP_CLIENT_SUCCESS_CONSULTANT_FULL_NAME != undefined
                      ? "table__col--filtered "
                      : "") + "table__col table__col--w160"
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      sortColumn("ZASTUP_CLIENT_SUCCESS_CONSULTANT_FULL_NAME")
                    }
                  >
                    Zastupujíci CSC
                  </span>

                  <FilterDurable
                    filter={filter}
                    setFilter={setFilter}
                    table={props.zakazka}
                    filterInput={filterInput}
                    setFilterInput={setFilterInput}
                    setFilteredTable={props.setZakazkaF}
                    colName={"ZASTUP_CLIENT_SUCCESS_CONSULTANT_FULL_NAME"}
                  />
                </div>
              ) : (
                <></>
              )}
              {show >= 5 ? (
                <div
                  class={
                    (filterInput[0].ZASTUP_NAKUP_FULL_NAME != undefined
                      ? "table__col--filtered "
                      : "") + "table__col table__col--w160"
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => sortColumn("ZASTUP_NAKUP_FULL_NAME")}
                  >
                    Zastupujíci Nákupčí
                  </span>

                  <FilterDurable
                    filter={filter}
                    setFilter={setFilter}
                    table={props.zakazka}
                    filterInput={filterInput}
                    setFilterInput={setFilterInput}
                    setFilteredTable={props.setZakazkaF}
                    colName={"ZASTUP_NAKUP_FULL_NAME"}
                  />
                </div>
              ) : (
                <></>
              )}
              <div
                class={
                  (filterInput[0].STAV_ZAKAZKY != undefined
                    ? "table__col--filtered "
                    : "") + "table__col table__col--flexible"
                }
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => sortColumn("STAV_ZAKAZKY")}
                >
                  Stav
                </span>
                <FilterDurable
                  filter={filter}
                  setFilter={setFilter}
                  table={props.zakazka}
                  filterInput={filterInput}
                  setFilterInput={setFilterInput}
                  setFilteredTable={props.setZakazkaF}
                  colName={"STAV_ZAKAZKY"}
                />
              </div>
            </div>
          </div>

          <div class="table__body">
            {props.zakazkaF
              .filter((o) => compareTime(o.DO, props.historyFilter) == true)
              .map((zakazka, index) =>
                hromadnaEditace == 0 ? (
                  <Link
                    className="text-link"
                    to={
                      "/OrderGenerals/" +
                      zakazka.KAMPAN_ID +
                      "/" +
                      zakazka.MEDIATYP
                    }
                  >
                    <div class="table__row" key={index}>
                      {zakazka.SEEN == 0 ? (
                        <div class="table__col table__col--notif">
                          <div class="validation__notif">
                            <div class="validation__notif--seen">!</div>
                          </div>
                        </div>
                      ) : (
                        <div class="table__col table__col--notif"></div>
                      )}

                      <div class="table__col table__col--w160">
                        {zakazka.KAMPAN_ID}
                      </div>
                      <div class="table__col table__col--w400">
                        {zakazka.NAZEV_ZAKAZKY}
                      </div>
                      <div class="table__col table__col--w280">
                        {zakazka.CLIENT}
                      </div>
                      <div class="table__col table__col--w140">
                        {zakazka.MEDIATYP}
                      </div>
                      <div class="table__col table__col--w160">
                        {formatDate(zakazka.OD)}
                      </div>
                      <div class="table__col table__col--w160">
                        {formatDate(zakazka.DO)}
                      </div>
                      <div class="table__col table__col--w140">
                        {zakazka.STAV_FAKTURACE}
                      </div>
                      {show >= 1 ? (
                        <div class="table__col table__col--w160">
                          {zakazka.CLIENT_LEAD_FULL_NAME}
                        </div>
                      ) : (
                        <></>
                      )}
                      {show >= 2 ? (
                        <div class="table__col table__col--w160">
                          {zakazka.CLIENT_SUCCESS_CONSULTANT_FULL_NAME}
                        </div>
                      ) : (
                        <></>
                      )}
                      {show >= 3 ? (
                        <div class="table__col table__col--w160">
                          {zakazka.NAKUP_FULL_NAME}
                        </div>
                      ) : (
                        <></>
                      )}
                      {show >= 4 ? (
                        <div class="table__col table__col--w160">
                          {zakazka.ZASTUP_CLIENT_SUCCESS_CONSULTANT_FULL_NAME}
                        </div>
                      ) : (
                        <></>
                      )}
                      {show >= 5 ? (
                        <div class="table__col table__col--w160">
                          {zakazka.ZASTUP_NAKUP_FULL_NAME}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div class="table__col table__col--flexible">
                        {zakazka.MEDIATYP == "Internet" ||
                        zakazka.MEDIATYP == "Fee" ||
                        zakazka.MEDIATYP == "Other Services"
                          ? zakazka.STAV_ZAKAZKY
                          : zakazka.STAV_ZAKAZKY === "Schváleno"
                          ? zakazka.CONFIRMED_COUNT === null
                            ? zakazka.STAV_ZAKAZKY + " (0x)"
                            : zakazka.STAV_ZAKAZKY +
                              " (" +
                              zakazka.CONFIRMED_COUNT +
                              "x)"
                          : zakazka.STAV_ZAKAZKY}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div
                    class={
                      zakazka.SELECTED == 0 || zakazka.SELECTED == null
                        ? "table__row"
                        : "table__row table__row--highlight"
                    }
                    key={index}
                  >
                    <div class="table__col table__col--pin">
                      <div
                        class="table-pin"
                        onClick={() => selectZakazka(index)}
                      >
                        <div class="icon">
                          <svg>
                            <use
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xlinkHref="/assets/img/sprite.svg#pin"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="table__col table__col--w160">
                      {zakazka.KAMPAN_ID}
                    </div>
                    <div class="table__col table__col--w400">
                      {zakazka.NAZEV_ZAKAZKY}
                    </div>
                    <div class="table__col table__col--w280">
                      {zakazka.CLIENT}
                    </div>
                    <div class="table__col table__col--w140">
                      {zakazka.MEDIATYP}
                    </div>
                    <div class="table__col table__col--w160">
                      {formatDate(zakazka.OD)}
                    </div>
                    <div class="table__col table__col--w160">
                      {formatDate(zakazka.DO)}
                    </div>
                    {show >= 1 ? (
                      <div class="table__col table__col--w160">
                        {zakazka.CLIENT_LEAD_FULL_NAME}
                      </div>
                    ) : (
                      <></>
                    )}
                    {show >= 2 ? (
                      <div class="table__col table__col--w160">
                        {zakazka.CLIENT_SUCCESS_CONSULTANT_FULL_NAME}
                      </div>
                    ) : (
                      <></>
                    )}
                    {show >= 3 ? (
                      <div class="table__col table__col--w160">
                        {zakazka.NAKUP_FULL_NAME}
                      </div>
                    ) : (
                      <></>
                    )}
                    {show >= 4 ? (
                      <div class="table__col table__col--w160">
                        {zakazka.ZASTUP_CLIENT_SUCCESS_CONSULTANT_FULL_NAME}
                      </div>
                    ) : (
                      <></>
                    )}
                    {show >= 5 ? (
                      <div class="table__col table__col--w160">
                        {zakazka.ZASTUP_NAKUP_FULL_NAME}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div class="table__col table__col--flexible">
                      {zakazka.MEDIATYP == "Internet" ||
                      zakazka.MEDIATYP == "Fee" ||
                      zakazka.MEDIATYP == "Other Services"
                        ? zakazka.STAV_ZAKAZKY
                        : zakazka.STAV_ZAKAZKY === "Schváleno"
                        ? zakazka.CONFIRMED_COUNT === null
                          ? zakazka.STAV_ZAKAZKY + " (0x)"
                          : zakazka.STAV_ZAKAZKY +
                            " (" +
                            zakazka.CONFIRMED_COUNT +
                            "x)"
                        : zakazka.STAV_ZAKAZKY}
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
        {props.user < 6 ? (
          <div class="action-bar">
            <div class="action-bar__in">
              {hromadnaEditace == 0 ? (
                <>
                  <a
                    class="btn btn--medium btn--primary"
                    onClick={() => setHromadnaEditace(1)}
                  >
                    Hromadná editace zakázek
                  </a>
                  <a
                    class="btn btn--medium btn--bordered"
                    onClick={() =>
                      props.setHistoryFilter(
                        props.historyFilter === "1999-01-01"
                          ? "2023-12-31"
                          : "1999-01-01"
                      )
                    }
                  >
                    {props.historyFilter === "1999-01-01"
                      ? "Skrýt historii"
                      : "Zobrazit historii"}
                  </a>
                </>
              ) : (
                <>
                  <a
                    class="btn btn--medium btn--transparent"
                    onClick={SelectAll}
                  >
                    Vybrat všechny řádky
                  </a>
                  <a
                    class="btn btn--medium btn--transparent"
                    onClick={() => setHromadnaEditace(0)}
                  >
                    Skrýt výběr řádků
                  </a>

                  <a
                    class="btn btn--medium btn--primary"
                    onClick={() => CheckEdit()}
                  >
                    Pokračovat v editaci
                  </a>
                </>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
