import React, { useEffect, useState } from "react";
import { formatDate } from "../helpers";
import Loader from "./Loader";
import { numberWithSpaces } from "../helpers";

export default function UnassignedInvoicesBez(props) {
  if (props.isLoading) {
    // return <div class="loader__in"></div>;
    return <Loader message="" />;
  }

  return (
    <div class="content__main">
      <div class="table">
        <div class="table__head">
          <div class="table__row">
            <div class="table__col table__col--pin">
              <div class="table-pin"></div>
            </div>

            <div class="table__col table__col--w200">Datum doručení</div>

            <div class="table__col table__col--w280">Číslo faktury</div>

            <div class="table__col table__col--w400">Popis</div>
            <div class="table__col table__col--w200">IČO</div>

            <div class="table__col table__col--w200">DIČ</div>
            <div class="table__col table__col--flexible">
              Fakturovaná částka (bez DPH)
            </div>
          </div>
        </div>

        <div class="table__body">
          {props.unassignedInv[0]["MISSING"].map(
            (unassignedInvoices, index) => (
              <div class="table__row" key={index}>
                <div class="table__col table__col--pin">
                  <div class="table-pin"></div>
                </div>

                <div class="table__col table__col--w200">
                  {formatDate(unassignedInvoices.DatumDoruceni)}
                </div>
                <div class="table__col table__col--w280">
                  {unassignedInvoices.PorCisKV}
                </div>
                <div class="table__col table__col--w400">
                  {unassignedInvoices.Popis}
                </div>
                <div class="table__col table__col--w200">
                  {unassignedInvoices.ICO}
                </div>
                <div class="table__col table__col--w200">
                  {unassignedInvoices.DIC}
                </div>
                <div class="table__col table__col--flexible">
                  {numberWithSpaces(unassignedInvoices.SumaValBezDPHDruh)}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
