import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectClientModal from "./Modal_select_client";
import ModalRunningWhite from "./modal_running_white";
import ModalInfo from "./modal_info";

import Validation from "./Validation";
import {
  compareTime,
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  IsValidDate,
  formatDate,
} from "../helpers";
import FiTextCastkaUcto from "./Order-FI-text_castka_ucto";
import FiTextCastkaF from "./Order-FI-text_castka_faktura";
import FiTextCastkaFS from "./Order-FI-text_castka_faktura_s";
import ModalFIComm from "./Order-FI-Modal_fi_fakturace-Comm";

export default function NewInvoice(props) {
  const [obdobi, setObdobi] = useState("");
  const [klientPO, setKlientPO] = useState("");
  const [text, setText] = useState("");
  const [poznamka, setPoznamka] = useState("");
  const [mena, setMena] = useState("CZK");
  const [infoMessage, setInfoMessage] = useState("");
  const [dobropisNa, setDobropisNa] = useState("");
  const [safeKurzy, setSafeKurzy] = useState([]);
  const [safeKurz, setSafeKurz] = useState(0);

  const [CLIENT, setCLIENT] = useState("");
  const [ID_CLIENTS, setID_CLIENTS] = useState(0);

  const [isError, setIsError] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [validace, setValidace] = useState("");
  const [validaceP, setValidaceP] = useState(true);

  const [attachments, setAttachments] = useState([
    { ZAKAZKA: 1, att: "", attID: null },
    { ZAKAZKA: 2, att: "", attID: null },
  ]);
  const [modalComm, setModalComm] = useState(false);

  const [zakazkaVyber, setZakazkaVyber] = useState(1);
  const [druhaZakazka, setDruhaZakazka] = useState(null);

  const polozkyDefault = [
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
  ];
  const [polozky, setPolozky] = useState(polozkyDefault);

  useEffect(() => {
    const url_query_string =
      "/heliosinvoices?kampan_id=" + props.vybranaZakazka + "&type=safe_kurzy";

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        setSafeKurzy(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const MenaSafeKurz = (mena) => {
    setMena(mena);

    if (mena !== "CZK" && IsValidDate(obdobi + "-01")) {
      var safe = safeKurzy.find(
        (o) => o.MENA == mena && formatDate(o.OBDOBI) == obdobi + "-01"
      );
      safe != undefined && setSafeKurz(safe.NAKUP_MENA);
    }
  };

  const ObdobiSafeKurz = (obdobi) => {
    setObdobi(obdobi);
    debugger;
    if (mena !== "CZK" && IsValidDate(obdobi + "-01")) {
      var safe = safeKurzy.find(
        (o) => o.MENA == mena && formatDate(o.OBDOBI) == obdobi + "-01"
      );
      safe != undefined && setSafeKurz(safe.NAKUP_MENA);
    }
  };

  const asd = () => {
    for (let i = 0; i < polozky.length; i++) {
      var text_f = polozky[i].TEXT;
      var helios_p = polozky[i].ID_HELIOS_P;
      var amount = polozky[i].AMOUNT;
      var zakazka = druhaZakazka !== null ? polozky[i].ID_KAMPAN : null; //ked neni vybrata druha zakazka tak zakazku vyplnujeme az pri odosielani
      var id_stredisko = polozky[i].ID_STREDISKO;
      var skupina = polozky[i].SKUPINA;

      if (
        (text_f !== "" ||
          helios_p !== null ||
          amount !== null ||
          zakazka !== null ||
          id_stredisko !== null ||
          skupina !== null) &&
        (text_f == "" ||
          helios_p == null ||
          amount == null ||
          zakazka == (druhaZakazka !== null ? null : "a") ||
          id_stredisko == null ||
          skupina == null)
      ) {
        setValidace(i);
        if (
          text_f == "" &&
          helios_p !== null &&
          amount !== null &&
          zakazka !== (druhaZakazka !== null ? null : "a") &&
          id_stredisko !== null &&
          skupina !== null &&
          skupina !== "Samostatně"
        ) {
          setValidaceP(false);
        } else {
          setValidaceP(true);
        }
        return true;
      }
      return false;
    }
  };
  const CreateInvoice = (fakturace) => {
    if (ID_CLIENTS == "") {
      setValidace("CLIENT");
      return;
    }

    if (!IsValidDate(obdobi + "-01")) {
      setValidace("obdobi");
      return;
    }

    if (text == "") {
      setValidace("text");
      return;
    }

    if (asd()) {
      return;
    }

    if (polozky[0].SKUPINA == null) {
      setValidace(0);
      setValidaceP(true);
      return;
    }

    for (let i = 0; i < polozky.length; i++) {
      var helios_p = polozky[i].ID_HELIOS_P;
      var id_stredisko = polozky[i].ID_STREDISKO;

      if (helios_p !== null && id_stredisko == null) {
        setValidace("stredisko");
        return;
      }
    }

    for (let i = 0; i < polozky.length; i++) {
      if ("AMOUNT" in polozky[i])
        if ((polozky[i].AMOUNT !== null) & (polozky[i].AMOUNT !== undefined))
          polozky[i].AMOUNT = parseFloat(
            polozky[i].AMOUNT.toString().replace(",", ".")
          );
    }
    if (mena !== "CZK") {
      polozky.map(function (x) {
        x.AMOUNT_FC = x.AMOUNT / safeKurz;
        return x;
      });
    }

    polozky.map(function (x) {
      x.SKUPINA = x.SKUPINA == "Samostatně" ? 0 : x.SKUPINA;
      return x;
    });

    var data;

    data = {
      OBDOBI: obdobi + "-01",
      TEXT: text,
      POZNAMKA: poznamka == "" ? null : poznamka,
      KLIENT_PO: klientPO == "" ? null : klientPO,
      ATTACHMENT_1:
        attachments[0]["attID"] == false ? null : attachments[0]["attID"],
      ATTACHMENT_2:
        attachments[1]["attID"] == false ? null : attachments[1]["attID"],
      POLOZKY: polozky.filter((o) => o.ID_HELIOS_P !== null),
      MENA: mena,
      ID_CLIENTS: ID_CLIENTS == "" ? null : ID_CLIENTS,
      DOBROPISNA: dobropisNa == "" ? null : dobropisNa,
    };

    setIsRunning(true);
    setInfoMessage("Zakládam fakturu.");

    const url_query_string =
      "/invoiceevidence?&typ=create_empty" + "&fakturace=" + fakturace;

    axios
      .post(url_query_string, data)
      .then((res) => {
        console.log(res.data);

        setPolozky([]);
        setPolozky(polozkyDefault);
        setCLIENT("");
        setID_CLIENTS(0);
        setObdobi("");
        setKlientPO("");
        setText("");
        setPoznamka("");
        setMena("CZK");
        setDobropisNa("");
        setAttachments([
          { ZAKAZKA: 1, att: "", attID: null },
          { ZAKAZKA: 2, att: "", attID: null },
        ]);
        setIsRunning(false);
        setIsError(false);
        setInfoBox("Faktura byla založena.");
      })
      .catch((err) => {
        console.log(err);
        setInfoBox("Při zakladání faktury došlo k chybě!");
        setIsRunning(false);
        setIsError(true);
      });
  };

  return (
    <>
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      {modalComm ? (
        <ModalFIComm
          setModalComm={setModalComm}
          zakazka={"all"}
          zakazkaVyber={zakazkaVyber}
          setVyber={() => function () {}}
          predVyber={null}
          attachments={attachments}
          setAttachments={setAttachments}
        />
      ) : (
        <></>
      )}
      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              <div class="form">
                <div class="form__columns">
                  <div class="form__col">
                    <form class="form__in">
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Klient
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={CLIENT}
                            onChange={(e) => setCLIENT(e.target.value)}
                          />
                          <Validation
                            validace={validace}
                            validace_custom={"CLIENT"}
                            message={"Povinné pole!"}
                          />
                        </div>
                        <div class="form__actions">
                          <SelectClientModal
                            setCLIENT={setCLIENT}
                            setID_CLIENTS={setID_CLIENTS}
                            setBRAND={() => function () {}}
                            isKlient={null}
                          />
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Fakturace v měně
                        </label>
                        <div class="form__value">
                          <select
                            class="form-select"
                            value={mena}
                            onChange={(e) => MenaSafeKurz(e.target.value)}
                          >
                            <option value={"CZK"}>CZK</option>
                            <option value={"EUR"}>EUR</option>
                            <option value={"GBP"}>GBP</option>
                            <option value={"CHF"}>CHF</option>
                            <option value={"JPY"}>JPY</option>
                            <option value={"PLN"}>PLN</option>
                            <option value={"USD"}>USD</option>
                          </select>
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Chci fakturovat za období
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            placeholder="YYYY-MM - Povinné"
                            value={obdobi}
                            onChange={(e) => ObdobiSafeKurz(e.target.value)}
                          />
                        </div>
                        <Validation
                          validace={validace}
                          validace_custom={"obdobi"}
                          message={"Špatně vyplněný datum!"}
                        />
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          PO číslo
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={klientPO}
                            onChange={(e) => setKlientPO(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Text faktury
                        </label>

                        <div class="form__value">
                          <textarea
                            placeholder="Max. 500 znaků.."
                            type="text"
                            class="form-textarea"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                          />
                        </div>
                        <Validation
                          validace={validace}
                          validace_custom={"text"}
                          message={"Vyplňte!"}
                        />
                      </div>
                    </form>
                  </div>
                  <div class="form__col">
                    <form class="form__in">
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Poznámka k fakturaci
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={poznamka}
                            onChange={(e) => setPoznamka(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Dobropis se vaze k fakture c.:
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={dobropisNa}
                            onChange={(e) => setDobropisNa(e.target.value)}
                          />
                        </div>
                      </div>

                      {attachments.map((attachment, index) => (
                        <>
                          {attachment.attID != null ? (
                            <div class="form__item">
                              <label
                                for="id"
                                class="form-label form-label--inline"
                              >
                                Příloha č.
                                {index + 1}:
                              </label>
                              <div class="form__value">
                                <input
                                  class="form-input"
                                  value={attachment.att}
                                  readOnly
                                />
                              </div>
                              <div class="form__actions">
                                <a
                                  class="btn btn--small btn--bordered"
                                  onClick={() => {
                                    setAttachments(
                                      attachments.map((o) =>
                                        o.ZAKAZKA == attachment.ZAKAZKA
                                          ? {
                                              ...o,
                                              attID: null,
                                            }
                                          : o
                                      )
                                    );
                                  }}
                                >
                                  X
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div class="form__item">
                              <label
                                for="id"
                                class="form-label form-label--inline"
                              >
                                Příloha č.
                                {index + 1}:
                              </label>
                              <div class="form__actions">
                                <a
                                  class="btn btn--small btn--primary"
                                  onClick={() => {
                                    setModalComm(true);
                                    setZakazkaVyber(attachment.ZAKAZKA);
                                  }}
                                >
                                  Vybrat
                                </a>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </form>
                  </div>
                </div>
                <br></br>
                <br></br>
                <div class="modal-window__subtitle">Položky do učetnictví</div>
                <div class="container-centering">
                  <form class="modal-form-6__in">
                    {polozky.map((polozka, index) => (
                      <>
                        <FiTextCastkaUcto
                          polozka={polozka}
                          setPolozky={setPolozky}
                          setPolozkyRozdil={() => function () {}}
                          polozky={polozky}
                          idx={index}
                          druhaZakazka={"vyplnit"}
                          financialInsight={[]}
                          financialInsightModal={[]}
                          vybranaZakazka={props.vybranaZakazka}
                          validace={validace}
                          validace_custom={index}
                          validaceP={validaceP}
                          mena={mena}
                          safeKurz={safeKurz}
                        />
                      </>
                    ))}
                    <FiTextCastkaUcto
                      polozka={{
                        TEXT: "TOTAL",
                        AMOUNT: numberWithSpaces(
                          polozky
                            .filter((o) => o.AMOUNT != null)
                            .reduce(
                              (n, { AMOUNT }) => n + replaceComma(AMOUNT),
                              0
                            )
                        ),
                      }}
                      setPolozky={setPolozky}
                      polozky={polozky}
                      idx={10}
                      druhaZakazka={"vyplnit"}
                    />
                  </form>
                </div>
                <br></br>
                <div class="divider"> </div>
                <br></br>
                <div class="modal-window__subtitle">Položky na faktuře</div>
                <div class="container-centering">
                  <form class={"modal-form-6__in"}>
                    {[
                      ...new Set(polozky.map(({ SKUPINA = [] }) => SKUPINA)), //toto by malo spravit array s unique skupoinami
                    ].map((skupina, index) =>
                      (skupina != null) & (skupina !== "Samostatně") ? (
                        <FiTextCastkaF
                          skupina={skupina}
                          setPolozky={setPolozky}
                          polozky={polozky}
                          idx={index}
                          druhaZakazka={"vyplnit"}
                          setPolozkyRozdil={() => function () {}}
                          financialInsight={[]}
                          financialInsightModal={[]}
                        />
                      ) : (
                        <></>
                      )
                    )}
                    {polozky.map((polozka, index) =>
                      (polozka.SKUPINA != null) &
                      (polozka.SKUPINA === "Samostatně") ? (
                        <FiTextCastkaFS
                          skupina={"Samostatně"}
                          setPolozky={setPolozky}
                          polozky={polozky}
                          polozka={polozka}
                          idx={index}
                          druhaZakazka={"vyplnit"}
                          setPolozkyRozdil={() => function () {}}
                          financialInsight={[]}
                          financialInsightModal={[]}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </form>
                </div>
                <div class="action-bar">
                  <div class="action-bar__in">
                    <a
                      class="btn btn--medium btn--primary"
                      onClick={() => CreateInvoice(1)}
                    >
                      Odeslat k fakturaci
                    </a>
                    <a
                      class="btn btn--medium btn--primary"
                      onClick={() => CreateInvoice(0)}
                    >
                      Odeslat - bez fakturace
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
