import React, { useEffect, useState } from "react";
import {
  Navigate,
  Link,
  Outlet,
  useParams,
  useLocation,
} from "react-router-dom";
import InfoBar from "./InfoBar";

export default function OrderMenu(props) {
  const params = useParams();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const [titleName, setTitleName] = useState("");

  useEffect(() => {
    const { hash, pathname, search } = location;
    if (pathname.includes("Communication")) {
      setSelectedTab(1);
      var tab = "C";
    } else if (pathname.includes("Mediaplan")) {
      setSelectedTab(2);
      var tab = "MP";
    } else if (pathname.includes("Process")) {
      setSelectedTab(3);
      var tab = "P";
    } else if (pathname.includes("FinancialInsight")) {
      setSelectedTab(4);
      var tab = "FP";
    } else if (pathname.includes("ReceivedInvoices")) {
      setSelectedTab(5);
      var tab = "PF";
    } else if (pathname.includes("IssuedInvoices")) {
      setSelectedTab(6);
      var tab = "VF";
    } else if (pathname.includes("PendingInvoices")) {
      setSelectedTab(7);
      var tab = "VyF";
    } else {
      setSelectedTab(0);
      var tab = "ZI";
    }

    document.title = params.zakazka;
  }, []);

  function SelectTabAndTitleName() {
    const { hash, pathname, search } = location;
    if (pathname.includes("Communication")) {
      setSelectedTab(1);
      var tab = "C";
    } else if (pathname.includes("Mediaplan")) {
      setSelectedTab(2);
      var tab = "MP";
    } else if (pathname.includes("Process")) {
      setSelectedTab(3);
      var tab = "P";
    } else if (pathname.includes("FinancialInsight")) {
      setSelectedTab(4);
      var tab = "FP";
    } else if (pathname.includes("ReceivedInvoices")) {
      setSelectedTab(5);
      var tab = "PF";
    } else if (pathname.includes("IssuedInvoices")) {
      setSelectedTab(6);
      var tab = "VF";
    } else if (pathname.includes("PendingInvoices")) {
      setSelectedTab(7);
      var tab = "VyF";
    } else {
      setSelectedTab(0);
      var tab = "ZI";
    }

    document.title = tab + "-" + params.zakazka;
  }

  return (
    <>
      <InfoBar emailRedirect={props.emailRedirect} />
      <div class="tabs">
        <div class="container">
          <div class="tabs__in">
            <Link
              className="text-link"
              to={"/OrderGenerals/" + params.zakazka + "/" + params.mediatyp}
            >
              <div
                class={
                  selectedTab == 0
                    ? "tabs__item tabs__item--active"
                    : "tabs__item"
                }
                onClick={() => {
                  setSelectedTab(0);
                }}
              >
                Základní informace
              </div>
            </Link>
            <Link
              className="text-link"
              to={
                "/OrderGenerals/" +
                params.zakazka +
                "/" +
                params.mediatyp +
                "/Communication"
              }
            >
              <div
                class={
                  selectedTab == 1
                    ? "tabs__item tabs__item--active"
                    : "tabs__item"
                }
                onClick={() => setSelectedTab(1)}
              >
                Komunikace a přílohy
              </div>
            </Link>
            {(params.mediatyp != "Fee") &
            (params.mediatyp != "Other services") &
            (params.mediatyp != "Internet") &
            (params.mediatyp != "Kickback") ? (
              <Link
                className="text-link"
                to={
                  "/OrderGenerals/" +
                  params.zakazka +
                  "/" +
                  params.mediatyp +
                  "/Mediaplan"
                }
              >
                <div
                  class={
                    selectedTab == 2
                      ? "tabs__item tabs__item--active"
                      : "tabs__item"
                  }
                  onClick={() => setSelectedTab(2)}
                >
                  Mediaplán
                </div>
              </Link>
            ) : (
              <></>
            )}
            <Link
              className="text-link"
              to={
                "/OrderGenerals/" +
                params.zakazka +
                "/" +
                params.mediatyp +
                "/Process"
              }
            >
              <div
                class={
                  selectedTab == 3
                    ? "tabs__item tabs__item--active"
                    : "tabs__item"
                }
                onClick={() => setSelectedTab(3)}
              >
                Proces
              </div>
            </Link>
            <Link
              className="text-link"
              to={
                "/OrderGenerals/" +
                params.zakazka +
                "/" +
                params.mediatyp +
                "/FinancialInsight"
              }
            >
              <div
                class={
                  selectedTab == 4
                    ? "tabs__item tabs__item--active"
                    : "tabs__item"
                }
                onClick={() => setSelectedTab(4)}
              >
                Finanční přehled
              </div>
            </Link>
            {(params.mediatyp != "Fee") & (params.mediatyp != "Kickback") ? (
              <Link
                className="text-link"
                to={
                  "/OrderGenerals/" +
                  params.zakazka +
                  "/" +
                  params.mediatyp +
                  "/ReceivedInvoices"
                }
              >
                <div
                  class={
                    selectedTab == 5
                      ? "tabs__item tabs__item--active"
                      : "tabs__item"
                  }
                  onClick={() => setSelectedTab(5)}
                >
                  Přijaté faktury
                </div>
              </Link>
            ) : (
              <></>
            )}
            <Link
              className="text-link"
              to={
                "/OrderGenerals/" +
                params.zakazka +
                "/" +
                params.mediatyp +
                "/IssuedInvoices"
              }
            >
              <div
                class={
                  selectedTab == 6
                    ? "tabs__item tabs__item--active"
                    : "tabs__item"
                }
                onClick={() => setSelectedTab(6)}
              >
                Vydané faktury
              </div>
            </Link>
            <Link
              className="text-link"
              to={
                "/OrderGenerals/" +
                params.zakazka +
                "/" +
                params.mediatyp +
                "/PendingInvoices"
              }
            >
              <div
                class={
                  selectedTab == 7
                    ? "tabs__item tabs__item--active"
                    : "tabs__item"
                }
                onClick={() => setSelectedTab(7)}
              >
                Vystavené faktury
              </div>
            </Link>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
}
