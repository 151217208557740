import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import ModalClientCreate from "./ClientsManagement-Modal_client_create";
import Filter from "./Filter";

export default function ModalClients(props) {
  const [modalClientCreate, setModalClientCreate] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [vybranyClient, setVybranyClient] = useState(false);
  const [clientsHelios, setClientsHelios] = useState([]);
  const [pickedClient, setPickedClient] = useState([]);

  const [clientsHeliosF, setClientsHeliosF] = useState([]);
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [sortDirection, setSortDirection] = useState(false);

  useEffect(() => {
    const url_query_string = "/clients?queryDB=helios";

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        setClientsHelios(res.data);
        setClientsHeliosF(res.data);
      },
      (err) => {}
    );
  }, []);

  const SelectMediaplan = (event, index, vydavatel) => {
    const values = [...clientsHeliosF];
    //switch button ma debiliny v target.value takze checkujeme ci je checked
    var value_to_change;
    debugger;
    if (event.target.checked === true) {
      value_to_change = 1;
      values[index]["SELECTED"] = value_to_change;
      setVybranyClient(true);
      setClientsHeliosF(values);
    } else {
      value_to_change = 0;
      values[index]["SELECTED"] = value_to_change;
      setVybranyClient(false);
      setClientsHeliosF(values);
    }
  };

  const SelectClient = () => {
    const values = [...clientsHeliosF];

    setPickedClient(values.filter((o) => o.SELECTED === 1));

    setModalClientCreate(true);
  };

  const toggleModal = () => {
    props.setModalClients(false);
  };

  const sortColumn = (colName) => {
    const values = [...clientsHeliosF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    values.sort((a, b) => {
      return sortDirection
        ? a[colName].localeCompare(b[colName])
        : b[colName].localeCompare(a[colName]);
    });
    setSortDirection(!sortDirection);
    setClientsHeliosF(values);
  };

  if (isLoading) {
    return (
      <>
        {
          //modal &&
          <>
            <div class="modal-window">
              <div class="modal-window__wrap">
                <div class="modal-window__close">
                  <div>Loading...</div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }

  return (
    <>
      {props.modalClients && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <>
          <ModalClientCreate
            clients={props.clients}
            modalClientCreate={modalClientCreate}
            setModalClientCreate={setModalClientCreate}
            pickedClient={pickedClient}
            setClients={props.setClients}
            setClientsF={props.setClientsF}
            setModalClients={props.setModalClients}
          />
          <div
            class={
              modalClientCreate
                ? "modal-window modal-window--hidden"
                : "modal-window modal-window--w1000"
            }
          >
            <div class="modal-window__wrap">
              <div class="modal-window__close" onClick={toggleModal}>
                <div class="icon">
                  <svg>
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="/assets/img/sprite.svg#cross"
                    />
                  </svg>
                </div>
              </div>
              <div class="modal-window__in">
                <div class="modal-window__title">
                  Evidovaní klienti v Heliosu
                </div>
                <div class="modal-window__content">
                  <div class="table-container">
                    <div class="table">
                      <div class="table__head">
                        <div class="table__row">
                          <div class="table__col table__col--w300 table__col--with-checkbox">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => sortColumn("Nazev")}
                            >
                              Klient
                            </span>
                            <Filter
                              filter={filter}
                              setFilter={setFilter}
                              filterVal={filterVal}
                              setFilterVal={setFilterVal}
                              table={clientsHelios}
                              filterInput={filterInput}
                              setFilterInput={setFilterInput}
                              setFilteredTable={setClientsHeliosF}
                              colName={"Nazev"}
                            />
                          </div>
                          <div class="table__col table__col--w200">Sídlo</div>
                          <div class="table__col table__col--w200">IČO</div>
                          <div class="table__col table__col--w200">DIČ</div>
                        </div>
                      </div>
                      <div class="table__body">
                        {clientsHeliosF.map((clientsHelios, index) => (
                          <div class="table__row">
                            <div class="table__col table__col--inner-cols table__col--w300">
                              <div class="table__inner-columns">
                                <div class="table__inner-col">
                                  <div class="form-checkbox form-checkbox--no-label">
                                    {vybranyClient ? (
                                      clientsHelios.SELECTED === 1 ? (
                                        <>
                                          <input
                                            type="checkbox"
                                            // defaultChecked={mediaplanik.SELECTED}
                                            onChange={(event) => {
                                              SelectMediaplan(event, index);
                                            }}
                                            id={index}
                                          />
                                          <label for={index}> </label>
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <>
                                        <input
                                          type="checkbox"
                                          // defaultChecked={mediaplanik.SELECTED}
                                          onChange={(event) => {
                                            SelectMediaplan(event, index);
                                          }}
                                          id={index}
                                        />
                                        <label for={index}> </label>
                                      </>
                                    )}
                                  </div>
                                </div>

                                <div class="table__inner-col">
                                  {clientsHelios.Nazev}
                                </div>
                              </div>
                            </div>

                            <div class="table__col table__col--w200">
                              {clientsHelios.Ulice}
                            </div>
                            <div class="table__col table__col--w200">
                              {clientsHelios.ICO}
                            </div>
                            <div class="table__col table__col--w200">
                              {clientsHelios.DIC}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-window__actions">
                  <a
                    className="btn btn-medium btn--primary"
                    onClick={SelectClient}
                  >
                    Vybrat
                  </a>
                  <a
                    class="btn btn--medium btn--bordered"
                    onClick={toggleModal}
                  >
                    Storno
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
