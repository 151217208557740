import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import FinancialInsightStandardTable from "./Order-FI_standard_table";
import FinancialInsightFeeOsTable from "./Order-FI_fee_os_table";
import { numberWithSpaces } from "../helpers";
import Loader from "./Loader";
export default function ModalFIFiDobropis(props) {
  const [isLoading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState(false);
  const [validaceID, setvalidaceID] = useState(0);

  useEffect(() => {
    const url_query_string =
      "/financialinsight?kampan_id=" +
      props.invoiceToEvidate[0]["ID_KAMPAN_DRUHA"] +
      "&mediatyp=" +
      props.invoiceToEvidate[0]["MEDIATYP_DRUHY"];

    axios.get(url_query_string).then(
      (res) => {
        const values = [...res.data];
        var items = values.filter(
          (o) =>
            o.ID_INVOICE_TO_HELIOS ==
            props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
        );

        for (let i = 0; i < items.length; i++) {
          items[i]["MNNP_OPRAVA"] = null;
          items[i]["FEE_OPRAVA"] = null;
          items[i]["CNNN_OPRAVA"] = null;
        }
        props.setFinancialInsightModal(values);
        props.setFinancialInsightModalF(values);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  const Dobropising = (col, id, val) => {
    const values = [...props.financialInsightModalF];
    var item = values.find((o) => o.ID == id);

    item[col] = val == "" ? null : val;

    if (
      (props.invoiceToEvidate[0]["MEDIATYP_DRUHY"] != "Fee") &
      (props.invoiceToEvidate[0]["MEDIATYP_DRUHY"] != "Other services")
    ) {
      item["CNNN_OPRAVA"] =
        (item["MNNP_OPRAVA"] == null
          ? 0
          : parseFloat(item["MNNP_OPRAVA"].toString().replace(",", "."))) +
        (item["FEE_OPRAVA"] == null
          ? 0
          : parseFloat(item["FEE_OPRAVA"].toString().replace(",", ".")));
    }

    props.setDruhyMediatyp(props.invoiceToEvidate[0]["MEDIATYP_DRUHY"]);
    props.setDruhaZakazka(props.invoiceToEvidate[0]["ID_KAMPAN_DRUHA"]);
    props.setFinancialInsightModalF(values);
  };

  const Continue = () => {
    const values = [...props.financialInsightModal];
    var items = values.filter(
      (o) =>
        o.ID_INVOICE_TO_HELIOS ==
        props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
    );

    for (let i = 0; i < items.length; i++) {
      items[i]["SELECTED"] = 1;
      items[i]["FEE_OPRAVA"] =
        items[i]["FEE_OPRAVA"] == null
          ? 0
          : parseFloat(items[i]["FEE_OPRAVA"].toString().replace(",", "."));
      items[i]["MNNP_OPRAVA"] =
        items[i]["MNNP_OPRAVA"] == null
          ? 0
          : parseFloat(items[i]["MNNP_OPRAVA"].toString().replace(",", "."));
      items[i]["CNNN_OPRAVA"] =
        items[i]["CNNN_OPRAVA"] == null
          ? 0
          : parseFloat(items[i]["CNNN_OPRAVA"].toString().replace(",", "."));

      if (
        items[i]["MNNP_OPRAVA"] > 0 ||
        items[i]["FEE_OPRAVA"] > 0 ||
        items[i]["CNNN_OPRAVA"] > 0
      ) {
        return setvalidaceID(items[i]["ID"]);
      }
      setvalidaceID(0);
    }
    values.map((el) => {
      el.FEE_ORG = el.FEE;
      el.MNNP_ORG = el.MNNP;
      el.CNNN_ORG = el.CNNN;
      el.FEE = el.FEE_OPRAVA;
      el.MNNP = el.MNNP_OPRAVA;
      el.CNNN = el.CNNN_OPRAVA;
      delete el.FEE_OPRAVA;
      delete el.MNNP_OPRAVA;
      delete el.CNNN_OPRAVA;
    });

    props.setFinancialInsightModal(values);
    props.setModalFI(false);
    props.setVyber(props.predVyber);
    props.VyplnPolozky(props.predVyber);
  };

  const toggleModal = () => {
    props.setDruhaZakazka(null);
    props.setVyber(props.predVyber);
    props.setFinancialInsightModal([]);
    props.setFinancialInsightModalF([]);
    props.setModalFI(false);
  };

  const sortColumn = (colName) => {
    const values = [...props.financialInsightModalF];

    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    props.setFinancialInsightModalF(values);
  };

  return (
    <div class={"modal-window modal-window--flexible"}>
      <div class="modal-window__wrap">
        <div class="modal-window__close" onClick={toggleModal}>
          <div class="icon">
            <svg>
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="/assets/img/sprite.svg#cross"
              />
            </svg>
          </div>
        </div>
        <div class="modal-window__in">
          <div class="modal-window__title">Vyberte položku k napárovaní</div>
          <div class="modal-window__content">
            <div class="table-container">
              {isLoading ? (
                <Loader message="" />
              ) : props.invoiceToEvidate[0]["MEDIATYP_DRUHY"] ==
                  "Other services" ||
                props.invoiceToEvidate[0]["MEDIATYP_DRUHY"] == "Fee" ||
                props.invoiceToEvidate[0]["MEDIATYP_DRUHY"] == "Internet" ? (
                <FinancialInsightFeeOsTable
                  financialInsight={props.financialInsightModal}
                  financialInsightF={props.financialInsightModalF}
                  setFinancialInsightF={props.setFinancialInsightModalF}
                  sortColumn={sortColumn}
                  parentFI={"dobropis"}
                  Dobropising={Dobropising}
                  vybranyClient={props.vybranyClient}
                  uctarna={0}
                  invoiceToEvidate={
                    props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
                  }
                  validaceID={validaceID}
                  vybranyMediatyp={props.invoiceToEvidate[0]["MEDIATYP_DRUHY"]}
                />
              ) : (
                <FinancialInsightStandardTable
                  financialInsightF={props.financialInsightModalF}
                  financialInsight={props.financialInsightModal}
                  setFinancialInsightF={props.setFinancialInsightModalF}
                  sortColumn={sortColumn}
                  parentFI={"dobropis"}
                  Dobropising={Dobropising}
                  vybranyClient={props.vybranyClient}
                  uctarna={0}
                  invoiceToEvidate={
                    props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
                  }
                  validaceID={validaceID}
                />
              )}
            </div>
          </div>
          <div class="modal-window__actions">
            <a className={"btn btn-medium btn--primary"} onClick={Continue}>
              Pokracovat
            </a>
            <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
              Storno
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
