import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";

export default function MediaplanModalApproval(props) {
  const [isRunning, setIsRunning] = useState(false);

  const toggleModal = (value, index) => {
    const values = [...props.mediaplanVerzia];
    var value_to_change;

    if (value === true) {
      value_to_change = 0;
    } else {
      value_to_change = 1;
    }

    values[index]["CONFIRMED"] = value_to_change;
    props.setMediaplanVerzia(values);
    console.log(props.mediaplanVerzia);
    props.setModalapp(false);
  };

  const ConfirmMediaplan = (value, index) => {
    const values = [...props.mediaplanVerzia];
    //switch button ma debiliny v target.value takze checkujeme ci je checked
    var value_to_change;

    if (value === true) {
      value_to_change = 1;
    } else {
      value_to_change = 0;
    }

    values[index]["CONFIRMED"] = value_to_change;

    const data = values[index];
    console.log(data);

    const url =
      "/mediaplanconfirmation?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp;
    setIsRunning(true);
    axios
      .post(url, data)
      .then((res) => {
        props.setMediaplanVerzia(values);

        value === true
          ? props.setInfoBox("Mediaplán byl úspěšne schválen.")
          : props.setInfoBox("Mediaplán byl odschválen.");

        props.setIsError(false);
        props.setModalapp(false);
        setIsRunning(false);

        if (value === true)
          if (res.data === 1) {
            props.setProcessFrom("approval");
            props.setProcessToInvoke(true);
            sessionStorage.setItem("STAV_ZAKAZKY", "Schváleno (1x)");
          } else {
            sessionStorage.setItem(
              "STAV_ZAKAZKY",
              "Schváleno (" + res.data + "x)"
            );
          }
      })
      .catch((err) => {
        try {
          props.setIsError(true);
          props.setInfoBox(err.response.data);
          props.setModalapp(false);
          setIsRunning(false);
        } catch {
          props.setIsError(true);
          props.setInfoBox(
            "Neočekávaná chyba! Mediaplán se nezdařilo schválit!"
          );
          props.setModalapp(false);
          setIsRunning(false);
        }
      });
  };

  return (
    <>
      {props.modalapp && (
        <div class="modal-window">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={
              props.mediaplanToApproveValue === true
                ? "Schvalují mediaplán. Čekejte prosím."
                : "Odschvalují mediaplán. Čekejte prosím."
            }
          />
          <div class="modal-window__wrap">
            <div
              class="modal-window__close"
              onClick={() =>
                toggleModal(
                  props.mediaplanToApproveValue,
                  props.mediaplanToApprove
                )
              }
            >
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                {props.mediaplanToApproveValue === true
                  ? "Opravdu chcete schválit mediaplán verzi " +
                    props.mediaplanToApproveVersion +
                    "?"
                  : "Opravdu chcete zrušit schválení verzi mediaplánu " +
                    props.mediaplanToApproveVersion +
                    "? Všechny faktury, které již byly naparovány k této verzi budou odparovány!"}
              </div>
              <div class="modal-window__actions">
                <a
                  className="btn btn--medium btn--primary"
                  onClick={() =>
                    ConfirmMediaplan(
                      props.mediaplanToApproveValue,
                      props.mediaplanToApprove
                    )
                  }
                >
                  Ano
                </a>
                <a
                  class="btn btn--medium btn--bordered"
                  onClick={() =>
                    toggleModal(
                      props.mediaplanToApproveValue,
                      props.mediaplanToApprove
                    )
                  }
                >
                  Ne
                </a>
              </div>
            </div>
            <div class="modal-window__actions"></div>
          </div>
        </div>
      )}
    </>
  );
}
