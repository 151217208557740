import axios from "axios";
import React, { useEffect, useState } from "react";
import OrderModalProcessContinuation from "./Order-Modal_process_continuation";
import Loader from "./Loader";
import ProcessModalDelete from "./Order-Process-Modal_delete";
import { formatDateTime } from "../helpers";
import { useParams } from "react-router-dom";

export default function Process(props) {
  const [isLoading, setLoading] = useState(true);
  const [processToInvoke, setProcessToInvoke] = useState(false);
  const [processFrom, setProcessFrom] = useState("");
  const [process, setProcess] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);

  const [sortDirection, setSortDirection] = useState(false);
  const params = useParams();

  const sortColumn = (colName) => {
    const values = [...process];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setProcess(values);
  };

  useEffect(() => {
    const url = "/processcontinuation?&kampan_id=" + params.zakazka;

    axios.get(url).then((res) => {
      console.log(res.data);
      setProcess(res.data);
      setLoading(false);
    });
  }, []);

  const Continue = () => {
    setProcessToInvoke(true);
    setProcessFrom("forced");
  };

  const Back = () => {
    setProcessToInvoke(true);
    setProcessFrom("back");
  };

  const Cancel = () => {
    setProcessToInvoke(true);
    setProcessFrom("cancel");
  };

  if (isLoading) {
    return <Loader message="" />;
  }

  return (
    <>
      <OrderModalProcessContinuation
        setProcess={setProcess}
        process={process}
        processToInvoke={processToInvoke}
        processFrom={processFrom}
        setProcessToInvoke={setProcessToInvoke}
        vybranaZakazka={params.zakazka}
        setSTAV_ZAKAZKY={props.setSTAV_ZAKAZKY}
        STAV_ZAKAZKY={props.STAV_ZAKAZKY}
        user={props.user}
      />
      <ProcessModalDelete
        vybranaZakazka={params.zakazka}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
      />
      <main class="content">
        <div class="container">
          <div class="content__in">
            {props.user < 6 ? (
              <div class="content__side">
                <div class="side-panel">
                  <div class="panel-info">
                    <div class="panel-info-table">
                      <div class="panel-info__actions">
                        <a
                          class="btn btn--small btn--primary"
                          onClick={Continue}
                        >
                          Pokračovat v procesu
                        </a>

                        <a class="btn btn--small btn--primary" onClick={Back}>
                          Vrátit zpět
                        </a>
                        {props.user <= 3 ? (
                          <a
                            class="btn btn--small btn--primary"
                            onClick={Cancel}
                          >
                            Zrušit zakázku
                          </a>
                        ) : (
                          <></>
                        )}
                        {props.user <= 2 ? (
                          <a
                            class="btn btn--small btn--red"
                            onClick={() => {
                              setModalDelete(!modalDelete);
                            }}
                          >
                            Smazat zakázku
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div class="content__main">
              <div class="table">
                <div class="table-container">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--w280">
                        <label>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => sortColumn("DATE")}
                          >
                            Datum
                          </span>
                        </label>
                      </div>
                      <div class="table__col table__col--w280">
                        <label>Uživatel</label>
                      </div>
                      <div class="table__col table__col--w200">
                        <label>Stav zakázky</label>
                      </div>
                      <div class="table__col table__col--flexible">
                        <label>Aktivita</label>
                      </div>
                    </div>
                  </div>

                  <div class="table__body">
                    {process.map((process, index) => (
                      <div class="table__row" key={index}>
                        <div class="table__col table__col--w280">
                          {formatDateTime(process.DATE)}
                        </div>
                        <div class="table__col table__col--w280">
                          {process.EMAIL}
                        </div>
                        <div class="table__col table__col--w200">
                          {process.STAV_ZAKAZKY}
                        </div>
                        <div class="table__col table__col--flexible">
                          {process.ACTIVITY}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
