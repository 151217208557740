import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Filter from "./Filter";
import ModalInvoicesEvidenceOS from "./Order-RI-Modal_invoices_evidence_os";
import FinancialInsightFeeOsTable from "./Order-FI_fee_os_table";
import { numberWithSpaces } from "../helpers";
import ModalInvoicesEvidenceMassOS from "./Order-RI-Modal_invoices_mass_os";
export default function ModalFiFeeOs(props) {
  const [modalInvoiceToEvidate, setModalInvoiceToEvidate] = useState(false);
  const [modalInvoiceToEvidateMass, setModalInvoiceToEvidateMass] =
    useState(false);
  const [isLoading, setLoading] = useState(false);

  const [mediaplanAgg, setMediaplanAgg] = useState([]);

  const [vybranyClient, setVybranyClient] = useState(false);
  const [financialInsightModal, setFinancialInsightModal] = useState([]);

  const [financialInsightModalF, setFinancialInsightModalF] = useState([]);
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [sortDirection, setSortDirection] = useState(false);

  const sortColumn = (colName) => {
    const values = [...financialInsightModalF];

    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setFinancialInsightModalF(values);
  };

  useEffect(() => {
    const url_query_string =
      "/financialinsight?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp;

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        setFinancialInsightModal(res.data);
        setFinancialInsightModalF(res.data);
      },
      (err) => {}
    );
  }, []);

  const SelectMediaplan = (event, index, vydavatel) => {
    const values = [...financialInsightModalF];
    var value_to_change;

    if (event.target.checked === true) {
      value_to_change = 1;
      setVybranyClient(true);
    } else {
      value_to_change = 0;
      setVybranyClient(false);
    }

    values[index]["SELECTED"] = value_to_change;
    setFinancialInsightModalF(values);
  };

  const SelectPartner = () => {
    const values = JSON.parse(JSON.stringify(financialInsightModalF));

    const values_filtered = values.filter((o) => o.SELECTED === 1);

    if (values_filtered.length > 1) {
      setMediaplanAgg(values_filtered);
      setModalInvoiceToEvidateMass(true);
    } else {
      values_filtered[0]["MNNN"] =
        values_filtered[0]["MNNN"] +
        props.invoiceToEvidate.reduce(
          (n, { INVOICE_AMOUNT }) => n + INVOICE_AMOUNT,
          0
        );

      if (
        values_filtered[0]["INVOICE_ALIAS_PORCIS"] === undefined ||
        values_filtered[0]["INVOICE_ALIAS_PORCIS"] === null
      ) {
        values_filtered[0]["INVOICE_ALIAS_PORCIS"] = props.invoiceToEvidate
          .map((u) => u.INVOICE_ALIAS_PORCIS)
          .join(", ");
      } else {
        values_filtered[0]["INVOICE_ALIAS_PORCIS"] =
          values_filtered[0]["INVOICE_ALIAS_PORCIS"] +
          ", " +
          props.invoiceToEvidate.map((u) => u.INVOICE_ALIAS_PORCIS).join(", ");
      }

      setMediaplanAgg(values_filtered);
      setModalInvoiceToEvidate(true);
    }
  };

  const toggleModal = () => {
    const values = [...financialInsightModalF];
    for (let object of values) {
      object.SELECTED = 0;
    }
    setFinancialInsightModalF(values);

    setVybranyClient(false);

    props.setModalToEvidate(false);
  };

  if (isLoading) {
    return (
      <>
        {
          //modal &&
          <>
            <div class="modal-window">
              <div class="modal-window__wrap">
                <div class="modal-window__close">
                  <div>Loading...</div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }

  return (
    <>
      <br></br>

      {props.modalToEvidate && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <>
          <div
            class={
              modalInvoiceToEvidate
                ? "modal-window"
                : "modal-window modal-window--flexible"
            }
          >
            <div class="modal-window__wrap">
              <div class="modal-window__close" onClick={toggleModal}>
                <div class="icon">
                  <svg>
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="/assets/img/sprite.svg#cross"
                    />
                  </svg>
                </div>
              </div>
              <div class="modal-window__in">
                <div class="modal-window__title">
                  Vyberte položku k napárovaní
                </div>
                <div class="modal-window__content">
                  <div class="table-container">
                    <FinancialInsightFeeOsTable
                      financialInsight={financialInsightModal}
                      setFinancialInsightF={setFinancialInsightModalF}
                      sortColumn={sortColumn}
                      parentFI={"invoices"}
                      financialInsightF={financialInsightModalF}
                      SelectMediaplan={SelectMediaplan}
                      numberWithSpaces={numberWithSpaces}
                      vybranyClient={vybranyClient}
                      vybranyMediatyp={props.vybranyMediatyp}
                      uctarna={props.uctarna}
                    />
                  </div>
                </div>
                <div class="modal-window__actions">
                  <ModalInvoicesEvidenceOS
                    mediaplanAgg={mediaplanAgg}
                    setMediaplanAgg={setMediaplanAgg}
                    financialInsightModalF={financialInsightModalF}
                    setFinancialInsightModalF={setFinancialInsightModalF}
                    setFinancialInsightModal={setFinancialInsightModal}
                    setVybranyClient={setVybranyClient}
                    vybranaZakazka={props.vybranaZakazka}
                    vybranyMediatyp={props.vybranyMediatyp}
                    modalInvoiceToEvidate={modalInvoiceToEvidate}
                    setModalInvoiceToEvidate={setModalInvoiceToEvidate}
                    invoiceToEvidate={props.invoiceToEvidate}
                    setRecievedInvoices={props.setRecievedInvoices}
                    setRecievedInvoicesF={props.setRecievedInvoicesF}
                    setModalToEvidate={props.setModalToEvidate}
                    setModalAction={props.setModalAction}
                    setIsError={props.setIsError}
                    setInfoBox={props.setInfoBox}
                  />
                  <a
                    className="btn btn-medium btn--primary"
                    onClick={SelectPartner}
                  >
                    Vybrat
                  </a>
                  <a
                    class="btn btn--medium btn--bordered"
                    onClick={toggleModal}
                  >
                    Storno
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ModalInvoicesEvidenceMassOS
            financialInsightF={mediaplanAgg}
            setFinancialInsightF={setMediaplanAgg}
            vybranaZakazka={props.vybranaZakazka}
            vybranyMediatyp={props.vybranyMediatyp}
            modalInvoiceToEvidateMass={modalInvoiceToEvidateMass}
            setModalInvoiceToEvidateMass={setModalInvoiceToEvidateMass}
            invoiceToEvidate={props.invoiceToEvidate}
            setRecievedInvoices={props.setRecievedInvoices}
            setRecievedInvoicesF={props.setRecievedInvoicesF}
            setModalToEvidate={props.setModalToEvidate}
            setModalAction={props.setModalAction}
            setIsError={props.setIsError}
            setInfoBox={props.setInfoBox}
            user={props.user}
          />
        </>
      )}
    </>
  );
}
