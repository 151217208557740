import React, { useState, useEffect } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import Loader from "./Loader";

export default function ModalClientUsers(props) {
  const [isLoading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [change, setChange] = useState(false);

  const [selectedUsersClients, setSelectedUsersClients] = useState([]);

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  useEffect(() => {
    axios.get("/usersclients").then(
      (res) => {
        setSelectedUsersClients(
          res.data.filter((o) => o.CLIENTSID == props.clientToUpdateID)
        );
        console.log(
          res.data.filter((o) => o.CLIENTSID == props.clientToUpdateID)
        );
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  const handleChangeInput = (event, index, user, client) => {
    const values = [...selectedUsersClients];

    if (event.target.checked === true) {
      values[index]["USERS_ID"] = user;
      values[index]["CLIENT_ID"] = client;
    } else {
      values[index]["USERS_ID"] = null;
      values[index]["CLIENT_ID"] = null;
    }

    setSelectedUsersClients(values);
    console.log(selectedUsersClients);
  };

  const toggleModal = () => {
    props.setAction("");
  };

  const ConfirmClients = () => {
    setIsRunning(true);
    setInfoMessage("Měnim uživatelé.");

    const data = selectedUsersClients;

    axios.post("/usersclients?updating=client", data).then(
      (res) => {
        console.log(res.data);
        props.setOrders(res.data[0]["ORDERS"]); // zoznam zakazek z kterych byli odebrami klienti
        props.setModalUpdate(!props.modalUpdate);
        setIsRunning(false);
        props.setIsError(false);

        res.data[0]["ORDERS"].length > 0
          ? props.setInfoBox(
              "Klient byl úspěšne aktualizován. Zároveň byli uživatelé odstránění z nasledujícich zakázek:"
            )
          : props.setInfoBox(
              "Klient byl úspěšne aktualizován. Všichni zakázky zustali beze změny."
            );
      },
      (err) => {
        console.log(err);
        const st_err = "Neočekávaná chyba! Klienta se nezdařilo aktualizovat!";

        props.setInfoBox(st_err);
        setIsRunning(false);
        props.setIsError(true);
      }
    );
  };

  const SelectAll = () => {
    const values = [...selectedUsersClients];

    if (selectAll === true) {
      for (let i = 0; i < values.length; i++) {
        values[i]["USERS_ID"] = values[i]["USERSID"];
        values[i]["CLIENT_ID"] = values[i]["CLIENTSID"];
      }
    } else {
      for (let index = 0; index < values.length; index++) {
        values[index]["USERS_ID"] = null;
        values[index]["CLIENT_ID"] = null;
      }
    }

    setSelectedUsersClients(values);
    console.log(selectedUsersClients);

    var checkboxes = document.getElementsByName("foo");

    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = selectAll;
      setSelectAll(!selectAll);
    }
  };

  const sortColumn = (colName) => {
    const values = [...selectedUsersClients];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setSelectedUsersClients(values);
  };

  if (isLoading) {
    return (
      <div class="modal-window  modal-window--w400">
        <div class="modal-window__wrap">
          <div class="modal-window__in">
            <Loader message="Načítám data." />{" "}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div class="modal-window  modal-window--w400">
      <div class="modal-window__wrap">
        <div class="modal-window__close" onClick={toggleModal}>
          <div class="icon">
            <svg>
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="/assets/img/sprite.svg#cross"
              />
            </svg>
          </div>
        </div>
        <div class="modal-window__in">
          <div class="modal-window__title">
            Přiřaďte klienta uživateli {props.selectedUserName}
          </div>
          <div class="modal-window__content">
            <div class="table">
              <div class="table__head">
                <div class="table__row">
                  <div
                    class={
                      change == false
                        ? "table__col table__col--flexible"
                        : "table__col table__col--flexible table__col--with-checkbox"
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => sortColumn("FULL_NAME")}
                    >
                      Užívatel
                    </span>
                  </div>
                </div>
              </div>

              <div class="table__body">
                {change == false
                  ? selectedUsersClients
                      .filter((o) => o.USERS_ID !== null)
                      .map((usersClients, index) => (
                        <div class="table__row" key={index}>
                          <div class="table__col  table__col--flexible">
                            {usersClients.FULL_NAME}
                          </div>
                        </div>
                      ))
                  : selectedUsersClients.map((usersClients, index) => (
                      <div class="table__row" key={index}>
                        <div class="table__col table__col--inner-cols table__col--flexible">
                          <div class="table__inner-columns">
                            <div class="table__inner-col">
                              <div class="form-checkbox form-checkbox--no-label">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    usersClients.USERS_ID === null
                                      ? false
                                      : true
                                    //usersClients.USERS_ID
                                  }
                                  onChange={(event) =>
                                    handleChangeInput(
                                      event,
                                      index,
                                      usersClients.USERSID,
                                      usersClients.CLIENTSID
                                    )
                                  }
                                  name="foo"
                                  id={index}
                                />
                                <label for={index}></label>
                              </div>
                            </div>
                            <div class="table__inner-col">
                              {usersClients.FULL_NAME}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div class="modal-window__actions">
            <ModalRunning
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              message={infoMessage}
            />
            {change == false ? (
              <a
                class="btn btn--medium btn--primary"
                onClick={() => setChange(!change)}
              >
                Změnit uživatelé
              </a>
            ) : (
              <>
                <a
                  class="btn btn--medium btn--primary"
                  onClick={() => {
                    ConfirmClients();
                  }}
                >
                  Potvrdit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={SelectAll}>
                  Vybrat vše
                </a>
              </>
            )}

            <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
              Storno
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
