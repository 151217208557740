import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import ModalRunning from "./modal_running";

export default function ModalInvoicesToUnpair(props) {
  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setinfoMessage] = useState("");

  const CancelInvoice = () => {
    const url_query_string =
      "/heliosinvoices?kampan_id=" +
      props.vybranaZakazka +
      "&faktura=" +
      props.invoiceToUnpair.ID +
      "&type=delete";

    setIsRunning(true);
    setinfoMessage("Probíha zrušení naparování faktury.");

    axios.post(url_query_string).then(
      (res) => {
        console.log(res.data);

        props.setRecievedInvoices(res.data);
        props.setRecievedInvoicesF(res.data);
        setIsRunning(false);
        props.setModalToUnpair(false);
      }
      //(err) => {
      //  console.log(err);
      //}
    );
  };

  const toggleModal = () => {
    props.setModalToUnpair(false);
  };

  return (
    <>
      <ModalRunning
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      {props.modalToUnpair && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                {"Opravdu chcete zrušit naparování faktury " +
                  props.invoiceToUnpair.ALIAS +
                  "?"}
              </div>

              <div class="modal-window__actions">
                <a class="btn btn--medium btn--red" onClick={CancelInvoice}>
                  Ano
                </a>

                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Ne
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
