import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import FormUpEv from "./Order-Form_up_ev";

export default function ModalFiUpdate(props) {
  const [validace, setValidace] = useState(0);

  const [isRunning, setIsRunning] = useState(false);

  const [mnnpUpdate, setMnnpUpdate] = useState(false);

  const InvoiceToUpdate = () => {
    for (let i = 0; i < props.invoiceToUpdate.length; i++) {
      props.invoiceToUpdate[i].MNNP = parseFloat(
        props.invoiceToUpdate[i].MNNP.toString().replace(",", ".")
      );

      props.invoiceToUpdate[i].FEE = parseFloat(
        props.invoiceToUpdate[i].FEE.toString().replace(",", ".")
      );
    }
    const data = {
      IE: props.invoiceToUpdate[0],
    };

    var validace_var;
    validace_var =
      data["MNNP"] - data["MNNN"] !== null && data["MNNP"] - data["MNNN"] !== 0
        ? data["CNR_MNR_FI_ID"] === null
          ? 1
          : 0
        : 0;

    validace_var =
      data["MNNP_MNNN_FI_ID"] == 5 || data["MNNP_MNNN_FI_ID"] == 6
        ? data["NOTE_1"] === null
          ? 3
          : validace_var
        : validace_var;

    setValidace(validace_var);

    if (validace_var === 0) {
      setIsRunning(true);
      const url_query_string =
        "financialinsight?kampan_id=" +
        props.vybranaZakazka +
        "&mediatyp=" +
        props.vybranyMediatyp;

      axios
        .post(url_query_string, data)
        .then((res) => {
          props.setFinancialInsight(res.data);
          props.setFinancialInsightF(res.data);
          props.setModalUpdate(!props.modalUpdate);
          setIsRunning(false);
          props.setIsError(false);
          props.setInfoBox("Fakturační položka byla úspěšne aktualizována.");
        })
        .catch((err) => {
          console.log(err);
          setIsRunning(false);
          props.setModalUpdate(!props.modalUpdate);
          props.setIsError(true);
          props.setInfoBox(
            "Neočekávaná chyba! Fakturační položku se nezdařilo aktualizovat!"
          );
        });
    }
  };

  const toggleModal = () => {
    console.log(props.invoiceToUpdate[0]["ID"]);

    props.setModalUpdate(!props.modalUpdate);
  };

  const UpdateInvoice = (value, name) => {
    //var value_to_change;
    // debugger;
    //var val1 = value.replace(",", "."); // nefunguje
    //value_to_change = parseFloat(val1);
    /*
    name == "MNNP" || name == "FEE"
      ? (value_to_change = parseFloat(value.replace(",", ".")))
      : (value_to_change = value);
    
      name == "MNNP" || name == "FEE"
      ? value == ""
        ? (value_to_change = value)
        : (value_to_change = parseFloat(value.replace(",", ".")))
      : (value_to_change = value);*/

    const values = [...props.invoiceToUpdate];
    values[0][name] = value;

    props.setInvoiceToUpdate(values);

    if (name == "MNNP") setMnnpUpdate(true); // len pre zvyraznenie pola fee
  };

  return (
    <>
      {props.modalUpdate && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Probíha aktualizace fakturační položky."}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Editace fakturační položky</div>
              <div class="modal-window__content">
                <div class="form">
                  <FormUpEv
                    UpdateVal={UpdateInvoice}
                    myArr={props.invoiceToUpdate}
                    user={props.user}
                    mnnpUpdate={mnnpUpdate}
                    validace={validace}
                  />
                </div>
              </div>
              <br></br>
              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--primary"
                  onClick={InvoiceToUpdate}
                >
                  Uložit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
