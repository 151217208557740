import axios from "axios";
import React, { useEffect, useState } from "react";
import NewOrderModal from "./NewOrder-Modal";
import SelectClientModal from "./Modal_select_client";
import ModalRunningWhite from "./modal_running_white";
import ModalInfo from "./modal_info";
import ModalInfoRedirect from "./modal_info_redirect";
//import Calendar from "react-calendar";
//import "react-calendar/dist/Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { compareTime } from "../helpers";

export default function NewOrder(props) {
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [usersClients, setUsersClients] = useState([]);
  const [infoMessage, setInfoMessage] = useState("");
  const [isKlient, setIsKlient] = useState(true); //nazev klient alebo dodavatel
  const [newOrder, setNewOrder] = useState("");

  const [ID, setID] = useState("");
  const [NAZEV_ZAKAZKY, setNAZEV_ZAKAZKY] = useState("");
  const [CLIENT, setCLIENT] = useState("");
  const [ID_CLIENTS, setID_CLIENTS] = useState(0);
  const [MEDIATYP, setMEDIATYP] = useState("");
  const [OD, setOD] = useState("");
  const [DO, setDO] = useState("");
  const [NAKUP, setNAKUP] = useState("");
  const [ZASTUP_NAKUP, setZASTUP_NAKUP] = useState("");
  const [CLIENT_LEAD, setCLIENT_LEAD] = useState("");
  const [NAVAZANE_ZAKAZKY, setNAVAZANE_ZAKAZKY] = useState("");
  const [STAV_ZAKAZKY, setSTAV_ZAKAZKY] = useState("");
  const [BRAND, setBRAND] = useState("");
  const [KLIENT_PO, setKLIENT_PO] = useState("");
  const [CLIENT_SUCCESS_CONSULTANT, setCLIENT_SUCCESS_CONSULTANT] =
    useState("");
  const [
    ZASTUP_CLIENT_SUCCESS_CONSULTANT,
    setZASTUP_CLIENT_SUCCESS_CONSULTANT,
  ] = useState("");
  // const [SEGMENT, setSEGMENT] = useState();

  const [preFilled, setPreFilled] = useState(0);

  const [isError, setIsError] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [validace, setValidace] = useState("");

  useEffect(() => {
    axios.get("/users").then(
      (res) => {
        setUsers(res.data);

        console.log(res.data);
      },
      (err) => {
        // setUser(false);
        console.log(err);
      }
    );
    axios.get("/usersclients").then(
      (res) => {
        setUsersClients(res.data);
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const VytvorZakazku = () => {
    if (NAZEV_ZAKAZKY == "") {
      setValidace("NAZEV_ZAKAZKY");
      return;
    }
    if (CLIENT == "") {
      setValidace("CLIENT");
      return;
    }
    if (MEDIATYP == "") {
      setValidace("MEDIATYP");
      return;
    }
    if (CLIENT_LEAD == "") {
      setValidace("CLIENT_LEAD");
      return;
    }
    if (BRAND == "") {
      setValidace("BRAND");
      return;
    }
    if (CLIENT_SUCCESS_CONSULTANT == "") {
      setValidace("CLIENT_SUCCESS_CONSULTANT");
      return;
    }
    if (OD == "") {
      setValidace("OD");
      return;
    }
    if (DO == "") {
      setValidace("DO");
      return;
    }

    if (compareTime(DO, OD) == false) {
      setValidace("DATE_RANGE");
      return;
    }

    setIsRunning(true);

    const data = {
      ID: ID,
      NAZEV_ZAKAZKY: NAZEV_ZAKAZKY == "" ? null : NAZEV_ZAKAZKY,
      ID_CLIENTS: ID_CLIENTS,
      CLIENT: CLIENT == "" ? null : CLIENT,
      MEDIATYP: MEDIATYP == "" ? null : MEDIATYP,
      OD: OD == "" ? null : formatDate(OD),
      DO: DO == "" ? null : formatDate(DO),
      NAKUP: NAKUP === "" ? null : NAKUP,
      ZASTUP_NAKUP: ZASTUP_NAKUP === "" ? null : ZASTUP_NAKUP,
      CLIENT_LEAD: CLIENT_LEAD == "" ? null : CLIENT_LEAD,
      BRAND: BRAND == "" ? null : BRAND,
      // SEGMENT: SEGMENT,
      KLIENT_PO: KLIENT_PO === "" ? null : KLIENT_PO,
      CLIENT_SUCCESS_CONSULTANT:
        CLIENT_SUCCESS_CONSULTANT == "" ? null : CLIENT_SUCCESS_CONSULTANT,
      ZASTUP_CLIENT_SUCCESS_CONSULTANT:
        ZASTUP_CLIENT_SUCCESS_CONSULTANT == ""
          ? null
          : ZASTUP_CLIENT_SUCCESS_CONSULTANT,
      PREFILLED: preFilled,
    };
    console.log(data);

    setInfoMessage("Vytvářim zakázku");

    axios
      .post("/neworder", data)
      .then((res) => {
        console.log(res);

        setIsRunning(false);
        setInfoBox(
          "Zákazka byla úspěšne vytvořena. ID nové zakázky je " +
            res.data[0] +
            "."
        );
        setIsError(false);
        setNewOrder(
          "/OrderGenerals/" + res.data[0] + "/" + res.data[1] + "#redirected"
        );
        setID("");
        setNAZEV_ZAKAZKY("");
        setID_CLIENTS(0);
        setCLIENT("");
        setMEDIATYP("");
        setOD("");
        setDO("");
        setNAKUP("");
        setCLIENT_LEAD("");
        setSTAV_ZAKAZKY("");
        setBRAND("");
        setKLIENT_PO("");
        setCLIENT_SUCCESS_CONSULTANT("");
        setZASTUP_CLIENT_SUCCESS_CONSULTANT("");
        setPreFilled(0);
        setValidace("");
      })
      .catch((err) => {
        try {
          console.log(err.response.data);
          setIsRunning(false);
          setInfoBox(err.response.data);
          setIsError(true);
        } catch {
          setIsRunning(false);
          setIsError(true);
          setInfoBox(
            "Chyba ve formuláři. Zkontrolujte prosím všechny zadaná pole!"
          );
        }
      });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <>
      <NewOrderModal
        setID={setID}
        setNAZEV_ZAKAZKY={setNAZEV_ZAKAZKY}
        setCLIENT={setCLIENT}
        setID_CLIENTS={setID_CLIENTS}
        setBRAND={setBRAND}
        // setSEGMENT={setSEGMENT}
        setOD={setOD}
        setDO={setDO}
        setCLIENT_SUCCESS_CONSULTANT={setCLIENT_SUCCESS_CONSULTANT}
        setCLIENT_LEAD={setCLIENT_LEAD}
        setNAVAZANE_ZAKAZKY={setNAVAZANE_ZAKAZKY}
        setPreFilled={setPreFilled}
        modal={modal}
        setModal={setModal}
      />
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <ModalInfoRedirect
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
        newOrder={newOrder}
      />
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              <div class="form">
                <div class="form__columns">
                  <div class="form__col">
                    <form class="form__in">
                      <div class="form__item">
                        <label
                          for="idRead"
                          class="form-label form-label--inline"
                        >
                          ID zakázky
                        </label>

                        <div class="form__value">
                          <input
                            type="text"
                            id="idRead"
                            class="form-input"
                            value={
                              ID === ""
                                ? "ID bude vygenerováno automaticky"
                                : ID
                            }
                            readOnly
                          ></input>
                        </div>
                      </div>

                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Název zakázky
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={NAZEV_ZAKAZKY}
                            onChange={(e) => setNAZEV_ZAKAZKY(e.target.value)}
                          />
                          {validace == "NAZEV_ZAKAZKY" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Klient
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={CLIENT}
                            onChange={(e) => setCLIENT(e.target.value)}
                          />
                          {validace == "CLIENT" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div class="form__actions">
                          <SelectClientModal
                            setCLIENT={setCLIENT}
                            setID_CLIENTS={setID_CLIENTS}
                            setBRAND={setBRAND}
                            isKlient={isKlient}
                            rozpad={true}
                          />
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Brand
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={BRAND}
                            onChange={(e) => setBRAND(e.target.value)}
                          />
                          {validace == "BRAND" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      {/*
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Segment
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            value={SEGMENT}
                            onChange={(e) => setSEGMENT(e.target.value)}
                          />
                        </div>
                            
                      </div>*/}
                    </form>
                  </div>
                  <div class="form__col">
                    <form class="form__in">
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Client Lead
                        </label>

                        <div class="form__value">
                          <select
                            class="form-select"
                            name="CLIENT_LEAD"
                            value={CLIENT_LEAD}
                            onChange={(e) => setCLIENT_LEAD(e.target.value)}
                          >
                            <option value="" selected disabled hidden>
                              Vyberte uživatele
                            </option>
                            {usersClients
                              .filter(
                                (o) =>
                                  (o.CLIENT_ID === ID_CLIENTS && o.ROLE == 2) ||
                                  o.ROLE == 1
                              )
                              .filter(
                                //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                (value, index, self) =>
                                  self
                                    .map((x) => x.EMAIL)
                                    .indexOf(value.EMAIL) == index
                              )
                              .map((usersClients) => (
                                <option
                                  key={usersClients.EMAIL}
                                  value={usersClients.EMAIL}
                                >
                                  {usersClients.EMAIL}
                                </option>
                              ))}
                          </select>
                          {validace == "CLIENT_LEAD" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Client Success Consultant
                        </label>

                        <div class="form__value">
                          <select
                            class="form-select"
                            name="CLIENT_SUCCESS_CONSULTANT"
                            value={CLIENT_SUCCESS_CONSULTANT}
                            onChange={(e) =>
                              setCLIENT_SUCCESS_CONSULTANT(e.target.value)
                            }
                          >
                            <option value="" selected disabled hidden>
                              Vyberte uživatele
                            </option>
                            <option value={CLIENT_LEAD}>Client Lead</option>
                            {usersClients
                              .filter(
                                (o) =>
                                  (o.CLIENT_ID === ID_CLIENTS && o.ROLE <= 3) ||
                                  o.ROLE == 1
                              )
                              .filter(
                                //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                (value, index, self) =>
                                  self
                                    .map((x) => x.EMAIL)
                                    .indexOf(value.EMAIL) == index
                              )
                              .map((usersClients) => (
                                <option
                                  key={usersClients.EMAIL}
                                  value={usersClients.EMAIL}
                                >
                                  {usersClients.EMAIL}
                                </option>
                              ))}
                          </select>
                          {validace == "CLIENT_SUCCESS_CONSULTANT" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Zastupujíci CSC
                        </label>

                        <div class="form__value">
                          <select
                            class="form-select"
                            name="ZASTUP_CLIENT_SUCCESS_CONSULTANT"
                            value={ZASTUP_CLIENT_SUCCESS_CONSULTANT}
                            onChange={(e) =>
                              setZASTUP_CLIENT_SUCCESS_CONSULTANT(
                                e.target.value
                              )
                            }
                          >
                            <option value="" selected disabled hidden>
                              Vyberte uživatele - nepovinné
                            </option>
                            <option value={CLIENT_LEAD}>Client Lead</option>
                            {usersClients
                              .filter(
                                (o) =>
                                  (o.CLIENT_ID === ID_CLIENTS && o.ROLE <= 3) ||
                                  o.ROLE == 1
                              )
                              .filter(
                                //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                (value, index, self) =>
                                  self
                                    .map((x) => x.EMAIL)
                                    .indexOf(value.EMAIL) == index
                              )
                              .map((usersClients) => (
                                <option
                                  key={usersClients.EMAIL}
                                  value={usersClients.EMAIL}
                                >
                                  {usersClients.EMAIL}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Nákupčí
                        </label>

                        <div class="form__value">
                          <select
                            class="form-select"
                            name="NAKUP"
                            value={NAKUP}
                            onChange={(e) => setNAKUP(e.target.value)}
                          >
                            <option value="" selected disabled hidden>
                              Vyberte uživatele - nepovinné
                            </option>
                            <option value={CLIENT_LEAD}>Client Lead</option>
                            <option value={CLIENT_SUCCESS_CONSULTANT}>
                              Client Success Consultant
                            </option>
                            {usersClients
                              .filter(
                                (o) =>
                                  (o.CLIENT_ID === ID_CLIENTS && o.ROLE <= 4) ||
                                  o.ROLE == 1
                              )
                              .filter(
                                //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                (value, index, self) =>
                                  self
                                    .map((x) => x.EMAIL)
                                    .indexOf(value.EMAIL) == index
                              )
                              .map((usersClients) => (
                                <option
                                  key={usersClients.EMAIL}
                                  value={usersClients.EMAIL}
                                >
                                  {usersClients.EMAIL}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Zastupujíci nákupčí
                        </label>

                        <div class="form__value">
                          <select
                            class="form-select"
                            name="ZASTUP_NAKUP"
                            value={ZASTUP_NAKUP}
                            onChange={(e) => setZASTUP_NAKUP(e.target.value)}
                          >
                            <option value="" selected disabled hidden>
                              Vyberte uživatele - nepovinné
                            </option>
                            <option value={CLIENT_LEAD}>Client Lead</option>
                            <option value={CLIENT_SUCCESS_CONSULTANT}>
                              Client Success Consultant
                            </option>
                            {usersClients
                              .filter(
                                (o) =>
                                  (o.CLIENT_ID === ID_CLIENTS && o.ROLE <= 4) ||
                                  o.ROLE == 1
                              )
                              .filter(
                                //filtrujem este unikatny email, lebo || o.ROLE == 1 vracia vsetky riadky
                                (value, index, self) =>
                                  self
                                    .map((x) => x.EMAIL)
                                    .indexOf(value.EMAIL) == index
                              )
                              .map((usersClients) => (
                                <option
                                  key={usersClients.EMAIL}
                                  value={usersClients.EMAIL}
                                >
                                  {usersClients.EMAIL}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="form">
                <div class="form__columns">
                  <div class="form__col">
                    <form class="form__in">
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Mediatyp
                        </label>

                        <div class="form__value">
                          <select
                            className="form-select"
                            name="MEDIATYP"
                            value={MEDIATYP === "" ? "Mediatyp" : MEDIATYP}
                            onChange={(e) => setMEDIATYP(e.target.value)}
                          >
                            <option value="Vyberte mediatyp">
                              Vyberte mediatyp
                            </option>
                            <option value="TV">TV</option>
                            <option value="Internet">Internet</option>
                            <option value="Print">Print</option>
                            <option value="Radio">Radio</option>
                            <option value="OOH">OOH</option>
                            <option value="Cinema">Cinema</option>
                            <option value="Fee">Fee</option>
                            <option value="Kickback">Kickback</option>

                            <option value="Other services">
                              Other services
                            </option>
                          </select>
                          {validace == "MEDIATYP" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="ida" class="form-label form-label--inline">
                          ID zakázky s mediatypem
                        </label>

                        <div class="form__value">
                          <input
                            id="ida"
                            type="text"
                            class="form-input"
                            value="ID bude vygenerováno automaticky"
                            readOnly
                          ></input>
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          PO klienta
                        </label>

                        <div class="form__value">
                          <input
                            class="form-input"
                            onChange={(e) => setKLIENT_PO(e.target.value)}
                          />
                        </div>
                      </div>

                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Začátek realizace zakázky
                        </label>

                        <div class="form__value">
                          <DatePicker
                            selected={OD}
                            placeholderText={"YYYY-MM-DD"}
                            onChange={(date) => setOD(new Date(date))}
                            dateFormat="yyyy-MM-dd"
                          />
                          {validace == "OD" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          Ukončení realizace zakázky
                        </label>

                        <div class="form__value">
                          <DatePicker
                            selected={DO}
                            placeholderText={"YYYY-MM-DD"}
                            onChange={(date) => setDO(new Date(date))}
                            dateFormat="yyyy-MM-dd"
                          />
                          {validace == "DO" ? (
                            <div class="validation">
                              <div class="validation__item">Povinné pole!</div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {validace == "DATE_RANGE" ? (
                            <div class="validation">
                              <div class="validation__item">
                                Datum konce realizace nemůže být dřívejší než
                                začátek!
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      {NAVAZANE_ZAKAZKY.split(",").map((zakazka, index) => (
                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            {index === 0 ? "Navázané zakázky" : ""}
                          </label>
                          <div class="form__value">
                            <input
                              class="form-input"
                              value={zakazka}
                              readOnly
                            />
                          </div>
                        </div>
                      ))}
                    </form>
                  </div>
                </div>
              </div>
              <div class="action-bar">
                <div class="action-bar__in">
                  <a
                    class="btn btn--medium btn--primary"
                    onClick={VytvorZakazku}
                  >
                    Vytvořit zakázku
                  </a>
                  <a
                    class="btn btn--medium btn--transparent"
                    onClick={() => setModal(!modal)}
                  >
                    Klonovat zakázku
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
