import React from "react";

export default function ShowHideCol(props) {
  return (
    <>
      <div class={props.show == "plus" ? "table-plus" : "table-minus"}>
        <div class="icon">
          <svg>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref={
                props.show == "plus"
                  ? "/assets/img/sprite.svg#plus"
                  : "/assets/img/sprite.svg#minus"
              }
              style={{ cursor: "pointer" }}
              onClick={
                props.show == "plus"
                  ? () => props.setShow(props.showValue + 1)
                  : () => props.setShow(props.showValue - 1)
              }
            />
          </svg>
        </div>
      </div>
    </>
  );
}
