import React from "react";

export default function ModalFIView(props) {
  function numberWithSpaces(x) {
    if (x !== null) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(",");
    } else {
      return x;
    }
  }

  const toggleModal = () => {
    props.setModalFIView(0);
  };
  return (
    <div class="modal-window__fiview modal-window--w800">
      <div class="modal-window__wrap">
        <div class="modal-window__in">
          <div class="modal-window__content">
            <div class="table-container">
              {props.mediatyp == "Other services" || props.mediatyp == "Fee" ? (
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      {props.mediatyp == "Other services" ? (
                        <div class={"table__col table__col--w140"}>
                          Dodavatel
                        </div>
                      ) : (
                        <></>
                      )}
                      <div class={"table__col table__col--w140"}>
                        Název služby
                      </div>
                      {props.mediatyp == "Fee" ? (
                        <div class="table__col table__col--w140">Mediatyp</div>
                      ) : (
                        <></>
                      )}

                      <div class="table__col table__col--w140">
                        Fakturované období
                      </div>
                      {props.mediatyp == "Other services" ? (
                        <div class="table__col table__col--w140">
                          Nákup - částka (bez DPH)
                        </div>
                      ) : (
                        <></>
                      )}
                      <div class="table__col table__col--flexible">
                        {props.mediatyp == "Other services"
                          ? "Prodej - částka (bez DPH)"
                          : "Cost to Client"}
                      </div>
                    </div>
                  </div>
                  <div class="table__body">
                    {props.financialInsightModal
                      .filter((o) => o.SELECTED == 1)
                      .map((financialInsight, index) => (
                        <div class="table__row" key={index}>
                          <div class="table__col table__col--w140">
                            {props.mediatyp == "Other services"
                              ? financialInsight.PARTNER
                              : "FEE"}
                          </div>
                          {props.mediatyp == "Other services" ? (
                            <div class="table__col table__col--w140">
                              {financialInsight.NAZEV_SLUZBY}
                            </div>
                          ) : (
                            <></>
                          )}
                          {props.mediatyp == "Fee" ? (
                            <div class="table__col table__col--w140">
                              {financialInsight.MEDIATYP}
                            </div>
                          ) : (
                            <></>
                          )}

                          <div class="table__col table__col--w140">
                            {financialInsight.DATUM_UVEREJNENI_M}
                          </div>
                          {props.mediatyp == "Other services" ? (
                            <div class="table__col table__col--w140">
                              {numberWithSpaces(financialInsight.MNNN)}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div class="table__col table__col--flexible">
                            {numberWithSpaces(financialInsight.CNNN)}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--w200">Vydavatel</div>
                      <div class="table__col table__col--w120">
                        Verze mediaplánu
                      </div>
                      <div class="table__col table__col--w120">
                        Číslo faktur
                      </div>
                      <div class="table__col table__col--w140">
                        Fakturované období
                      </div>
                      <div class="table__col table__col--w140">
                        Media Net Nákup
                      </div>
                      <div class="table__col table__col--w140">
                        Media Net Prodej MP
                      </div>
                      <div class="table__col table__col--w140">
                        Media Net Prodej
                      </div>
                      <div class="table__col table__col--flexible">
                        Cost to Client
                      </div>
                    </div>
                  </div>
                  <div class="table__body">
                    {props.financialInsightModal
                      .filter((o) => o.SELECTED == 1)
                      .map((financialInsight, index) => (
                        <div class="table__row" key={index}>
                          <div class="table__col table__col--w200">
                            {financialInsight.VYDAVATEL}
                          </div>
                          <div class="table__col table__col--w120">
                            {financialInsight.ID_MEDIAPLAN_VERSION_FE}
                          </div>
                          <div class="table__col table__col--w120">
                            {financialInsight.INVOICE_ALIAS_PORCIS}
                          </div>
                          <div class="table__col table__col--w140">
                            {financialInsight.DATUM_UVEREJNENI}
                          </div>
                          <div class="table__col table__col--w140">
                            {numberWithSpaces(financialInsight.MNNN)}
                          </div>
                          <div class="table__col table__col--w140">
                            {numberWithSpaces(financialInsight.MNNPMP)}
                          </div>
                          <div class="table__col table__col--w140">
                            {numberWithSpaces(financialInsight.MNNP)}
                          </div>
                          <div class="table__col table__col--flexible">
                            {numberWithSpaces(financialInsight.CNNN)}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class="modal-window__actions">
            <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
              Zavřít
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
