import React, { useState } from "react";

export default function Loader(props) {
  return (
    <div class="loader">
      <div class="loader__circle"></div>

      <div class="loader__content">{props.message}</div>
    </div>
  );
}
