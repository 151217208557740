import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

export default function OrderModalProcessContinuation(props) {
  useEffect(() => {
    // test();
  }, []);

  const ContinueInProcess = () => {
    const url =
      "/processcontinuation?from=" +
      props.processFrom +
      "&kampan_id=" +
      props.vybranaZakazka;

    axios.post(url).then((res) => {
      console.log(res.data);
      sessionStorage.setItem("STAV_ZAKAZKY", res.data[0]["STAV"]);

      if (
        props.processFrom === "forced" ||
        props.processFrom === "back" ||
        props.processFrom === "cancel"
      ) {
        props.setProcess(res.data[0]["process"]);
      }
    });

    props.setProcessToInvoke(false);
  };
  const test = () => {
    console.log(props.processFrom);
    console.log(props.processToInvoke);
  };

  return (
    <>
      {props.processToInvoke && (
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div
              class="modal-window__close"
              onClick={() => props.setProcessToInvoke(false)}
            >
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>

            {props.processFrom === "upload" ? (
              <div class="modal-window__in">
                {props.processType === 1 ? (
                  <div class="modal-window__title">
                    Chcete posunout zakázku do stavu "Ke schválení"?
                  </div>
                ) : (
                  <div class="modal-window__title">
                    Nebyl nahrán mail od klienta s mediaplánem. Opravdu chcete
                    posunout zakazku do stavu "Ke schvaleni"?
                  </div>
                )}

                <a
                  className="btn btn--medium btn--primary"
                  onClick={ContinueInProcess}
                >
                  Ano
                </a>
                <br></br>
                <a
                  class="btn btn--medium btn--bordered"
                  onClick={() => props.setProcessToInvoke(false)}
                >
                  Ne
                </a>
              </div>
            ) : (
              <></>
            )}
            {props.processFrom === "approval" ? (
              <div class="modal-window__in">
                <div class="modal-window__title">
                  Zakázka byla posunuta do stavu "Schválená".
                </div>
                <br></br>
                <a
                  class="btn btn--medium btn--bordered"
                  onClick={() => props.setProcessToInvoke(false)}
                >
                  Ok
                </a>
              </div>
            ) : (
              <></>
            )}
            {props.processFrom === "forced" ? (
              <div class="modal-window__in">
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Založena" ? (
                  <div class="modal-window__title">
                    Opravdu chcete posunout zakázku do stavu "Ke schválení"?
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Ke schválení" ? (
                  <div class="modal-window__title">
                    Opravdu chcete posunout zakázku do stavu "Schválená"?
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage
                  .getItem("STAV_ZAKAZKY")
                  .includes("Schváleno") ? (
                  <div class="modal-window__title">
                    Opravdu chcete posunout zakázku do stavu "Uzavřená"?
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Uzavřená" ? (
                  <div class="modal-window__title">
                    Zakázka již byla uzavřená.
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Zrušená" ? (
                  <div class="modal-window__title">Zakázka byla zrušená.</div>
                ) : (
                  <></>
                )}
                <br></br>
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Uzavřená" ||
                sessionStorage.getItem("STAV_ZAKAZKY") === "Zrušená" ? (
                  <a
                    class="btn btn--medium btn--bordered"
                    onClick={() => props.setProcessToInvoke(false)}
                  >
                    Ok
                  </a>
                ) : (
                  <>
                    <a
                      className="btn btn--medium btn--primary"
                      onClick={ContinueInProcess}
                    >
                      Ano
                    </a>
                    <br></br>
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={() => props.setProcessToInvoke(false)}
                    >
                      Ne
                    </a>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}

            {props.processFrom === "back" ? (
              <div class="modal-window__in">
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Ke schválení" ? (
                  <div class="modal-window__title">
                    Opravdu chcete vrátit zakázku do stavu "Založena"?
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage
                  .getItem("STAV_ZAKAZKY")
                  .includes("Schváleno") ? (
                  <div class="modal-window__title">
                    Opravdu chcete vrátit zakázku do stavu "Ke schválení"?
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Uzavřená" ? (
                  <div class="modal-window__title">
                    Opravdu chcete vrátit zakázku do stavu "Schválená"?
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Založena" ? (
                  <div class="modal-window__title">
                    Nelze provést. "Založena" je výchozí stav.
                  </div>
                ) : (
                  <></>
                )}
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Zrušená" ? (
                  props.user == 1 || props.user == 2 ? (
                    <div class="modal-window__title">
                      Opravdu chcete vrátit již zrušenou zakázku do stavu
                      "Založena"?
                    </div>
                  ) : (
                    <div class="modal-window__title">Zakázka byla zrušená.</div>
                  )
                ) : (
                  <div class="modal-window__title">Zakázka byla zrušená.</div>
                )}
                <br></br>
                {sessionStorage.getItem("STAV_ZAKAZKY") === "Založena" ||
                sessionStorage.getItem("STAV_ZAKAZKY") === "Zrušená" ? (
                  props.user > 2 ? (
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={() => props.setProcessToInvoke(false)}
                    >
                      Ok
                    </a>
                  ) : sessionStorage.getItem("STAV_ZAKAZKY") === "Zrušená" ? (
                    <>
                      <a
                        className="btn btn--medium btn--primary"
                        onClick={ContinueInProcess}
                      >
                        Ano
                      </a>
                      <br></br>
                      <a
                        class="btn btn--medium btn--bordered"
                        onClick={() => props.setProcessToInvoke(false)}
                      >
                        Ne
                      </a>
                    </>
                  ) : (
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={() => props.setProcessToInvoke(false)}
                    >
                      Ok
                    </a>
                  )
                ) : (
                  <>
                    <a
                      className="btn btn--medium btn--primary"
                      onClick={ContinueInProcess}
                    >
                      Ano
                    </a>
                    <br></br>
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={() => props.setProcessToInvoke(false)}
                    >
                      Ne
                    </a>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}

            {props.processFrom === "cancel" ? (
              <div class="modal-window__in">
                <div class="modal-window__title">
                  Opravdu chcete zrušit zakázku?
                </div>

                <>
                  <a
                    className="btn btn--medium btn--primary"
                    onClick={ContinueInProcess}
                  >
                    Ano
                  </a>
                  <br></br>
                  <a
                    class="btn btn--medium btn--bordered"
                    onClick={() => props.setProcessToInvoke(false)}
                  >
                    Ne
                  </a>
                </>
              </div>
            ) : (
              <></>
            )}

            <div class="modal-window__actions"></div>
          </div>
        </div>
      )}
    </>
  );
}
