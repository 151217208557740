import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";

export default function ModalFIComm(props) {
  const [isLoading, setLoading] = useState(true);
  const [mails, setMails] = useState([]);

  useEffect(() => {
    const url_query_string =
      "/emailsdisplay?fakturace=true&kampan_id=" + props.zakazka;
    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);

        setMails(res.data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  const selectAttachment = (ID, att) => {
    const array = [...props.attachments];
    var row = array.find((o) => o.ZAKAZKA == props.zakazkaVyber);
    row["attID"] = ID;
    row["att"] = att;

    props.setAttachments(array);

    props.setModalComm(false);
    props.setVyber(props.predVyber);
  };

  const toggleModal = () => {
    props.setModalComm(false);
    props.setVyber(props.predVyber);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <>
      <div class="modal-window modal-window--w1000">
        <div class="modal-window__wrap">
          <div class="modal-window__close" onClick={toggleModal}>
            <div class="icon">
              <svg>
                <use
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xlinkHref="/assets/img/sprite.svg#cross"
                />
              </svg>
            </div>
          </div>
          <div class="modal-window__in">
            <div class="modal-window__title">Vyberte e-mail s přílohou</div>
            <div class="modal-window__content">
              {isLoading ? (
                <Loader message="" />
              ) : (
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--w140">Datum</div>

                      <div class="table__col table__col--w280">Odesílatel</div>

                      <div class="table__col table__col--w400">Předmět</div>
                      <div class="table__col table__col--w400">Příloha</div>

                      <div class="table__col table__col--w200">Kategórie</div>
                    </div>
                  </div>

                  <div class="table__body">
                    {mails.map((mails, index) => (
                      <div
                        class="table__row"
                        key={index}
                        onClick={() => selectAttachment(mails.ID, mails.ATT)}
                        style={{ cursor: "pointer" }}
                      >
                        <div class="table__col table__col--w140">
                          {formatDate(mails.VALID_FROM)}
                        </div>
                        <div class="table__col table__col--w280">
                          {mails.SENDER_ADDRESS}
                        </div>

                        <div class="table__col table__col--w400">
                          {mails.EMAIL_SUBJECT}
                        </div>
                        <div class="table__col table__col--w400">
                          {mails.ATT}
                        </div>
                        <div class="table__col table__col--w200">
                          {mails.COMM_TYPE_LABEL}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {isLoading ? (
              <></>
            ) : (
              <div class="modal-window__actions">
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
