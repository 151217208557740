import React, { useEffect, useState } from "react";
import Filter from "./Filter";
export default function ModalFiFakturaceOrders(props) {
  const [sortDirection, setSortDirection] = useState(false);

  const [filter, setFilter] = useState([{ INIT: false }]); //vlozi stlpce ktore maju aktivovany filter, resp. otvoreny
  const [filterInput, setFilterInput] = useState([]); // vlozi key value stlpec:hodnota, ked vyprazdnujem filter tak je deletefilterinput[col]

  const [zakazkaF, setZakazkaF] = useState([]);

  useEffect(() => {
    setZakazkaF(JSON.parse(localStorage.getItem("zakazka")));
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const selectZakazka = (KAMPAN_ID, MEDIATYP) => {
    props.setDruhaZakazka(KAMPAN_ID);
    props.setDruhyMediatyp(MEDIATYP);

    props.setModalZakazky(false);
    props.setModalFI(true);
  };
  const toggleModal = () => {
    props.setModalZakazky(false);
    props.setVyber(props.predVyber);
  };

  const sortColumn = (colName) => {
    const values = [...zakazkaF];
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setZakazkaF(values);
  };

  return (
    <div class="modal-window modal-window--w800">
      <div class="modal-window__wrap">
        <div class="modal-window__close" onClick={toggleModal}>
          <div class="icon">
            <svg>
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="/assets/img/sprite.svg#cross"
              />
            </svg>
          </div>
        </div>
        <div class="modal-window__in">
          <div class="modal-window__title">Vyberte klienta</div>
          <div class="modal-window__content">
            <div class="table">
              <div class="table__head">
                <div class="table__row">
                  <div class="table__col table__col--w160">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => sortColumn("KAMPAN_ID")}
                    >
                      ID zakázky s mediatypem
                    </span>
                    <Filter
                      filter={filter}
                      setFilter={setFilter}
                      table={props.zakazka}
                      filterInput={filterInput}
                      setFilterInput={setFilterInput}
                      setFilteredTable={setZakazkaF}
                      colName={"KAMPAN_ID"}
                    />
                  </div>

                  <div class="table__col table__col--w400">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => sortColumn("KAMPAN_ID")}
                    >
                      Název
                    </span>

                    <Filter
                      filter={filter}
                      setFilter={setFilter}
                      table={props.zakazka}
                      filterInput={filterInput}
                      setFilterInput={setFilterInput}
                      setFilteredTable={setZakazkaF}
                      colName={"NAZEV_ZAKAZKY"}
                    />
                  </div>

                  <div class="table__col table__col--w280">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => sortColumn("CLIENT")}
                    >
                      Klient
                    </span>
                    <Filter
                      filter={filter}
                      setFilter={setFilter}
                      table={props.zakazka}
                      filterInput={filterInput}
                      setFilterInput={setFilterInput}
                      setFilteredTable={setZakazkaF}
                      colName={"CLIENT"}
                    />
                  </div>

                  <div class="table__col table__col--w160">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => sortColumn("OD")}
                    >
                      Realizace od
                    </span>

                    <Filter
                      filter={filter}
                      setFilter={setFilter}
                      table={props.zakazka}
                      filterInput={filterInput}
                      setFilterInput={setFilterInput}
                      setFilteredTable={setZakazkaF}
                      colName={"OD"}
                    />
                  </div>
                </div>
              </div>

              <div class="table__body">
                {zakazkaF.map((zakazka, index) => (
                  <div
                    class="table__row"
                    key={index}
                    onClick={() =>
                      selectZakazka(zakazka.KAMPAN_ID, zakazka.MEDIATYP)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div class="table__col table__col--w160">
                      {zakazka.KAMPAN_ID}
                    </div>
                    <div class="table__col table__col--w400">
                      {zakazka.NAZEV_ZAKAZKY}
                    </div>
                    <div class="table__col table__col--w280">
                      {zakazka.CLIENT}
                    </div>

                    <div class="table__col table__col--w160">
                      {formatDate(zakazka.OD)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
