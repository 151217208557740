import axios from "axios";
import React, { useEffect, useState } from "react";
import ModalClients from "./ClientsManagement-Modal_clients";
import ModalRunning from "./modal_running";
import Loader from "./Loader";
import ModalClientUpdate from "./ClientsManagement-Modal_client_update";
import Filter from "./Filter";
import ModalInfoList from "./modal_info_list";

export default function ClientsManagement(props) {
  const [isLoading, setLoading] = useState(true);
  const [modalClients, setModalClients] = useState(false);
  const [clients, setClients] = useState([]);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [clientToUpdate, setClientToUpdate] = useState([]);
  const [orders, setOrders] = useState([]);

  const [clientsF, setClientsF] = useState([]);
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [sortDirection, setSortDirection] = useState(false);

  const [isRunning, setIsRunning] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const sortColumn = (colName) => {
    const values = [...clientsF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setClientsF(values);
  };

  useEffect(() => {
    axios.get("/clients?queryDB=nikola&rozpad=true").then(
      (res) => {
        setClients(res.data);
        setClientsF(res.data);
        console.log(res.data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const updateClient = (ID) => {
    setModalUpdate(true);
    setClientToUpdate(clients.filter((o) => o.ID === ID));
  };

  if (isLoading) {
    return <Loader message="" />;
  }

  return (
    <>
      <main class="content">
        <ModalRunning
          isRunning={isRunning}
          setIsRunning={setIsRunning}
          message="Ukládam změny."
        />
        <ModalClients
          setModalClients={setModalClients}
          modalClients={modalClients}
          clients={clients}
          setClients={setClients}
          setClientsF={setClientsF}
        />
        <ModalClientUpdate
          setModalUpdate={setModalUpdate}
          modalUpdate={modalUpdate}
          clients={clients}
          setClients={setClients}
          setClientsF={setClientsF}
          clientToUpdate={clientToUpdate}
          setClientToUpdate={setClientToUpdate}
          setInfoBox={setInfoBox}
          setIsError={setIsError}
          setOrders={setOrders}
        />
        <ModalInfoList
          isError={isError}
          setIsError={setIsError}
          infoBox={infoBox}
          setInfoBox={setInfoBox}
          list={orders}
          colName={"ID"}
        />
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              <div class="table-container">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--pin">
                        <div class="table-pin"></div>
                      </div>

                      <div class="table__col table__col--w300">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("CLIENT")}
                        >
                          Klient
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={clients}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setClientsF}
                          colName={"CLIENT"}
                        />
                      </div>

                      <div class="table__col table__col--w200">Brand</div>
                      <div class="table__col table__col--w200">IČO </div>
                      <div class="table__col table__col--w100">Klient ID </div>
                      <div class="table__col table__col--w100">Brand ID </div>
                      <div class="table__col table__col--flexible">
                        Kód zakázky
                      </div>
                    </div>
                  </div>

                  <div class="table__body">
                    {clientsF.map((clients, index) => (
                      <div
                        class={
                          clients.STATUS == 1
                            ? "table__row"
                            : "table__row table__row--highlight-red"
                        }
                        key={index}
                        onClick={() => updateClient(clients.ID)}
                        style={{ cursor: "pointer" }}
                      >
                        <div class="table__col table__col--pin">
                          <div class="table-pin"></div>
                        </div>

                        <div class="table__col table__col--w300">
                          {clients.CLIENT}
                        </div>

                        <div class="table__col table__col--w200">
                          {clients.BRAND}
                        </div>
                        <div class="table__col table__col--w200">
                          {clients.ICO}
                        </div>
                        <div class="table__col table__col--w100">
                          {clients.CLIENT_ID}
                        </div>
                        <div class="table__col table__col--w100">
                          {clients.BRAND_ID}
                        </div>
                        <div class="table__col table__col--flexible">
                          {clients.KOD_ZAKAZKA}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="action-bar">
                  <div class="action-bar__in">
                    <a
                      onClick={() => {
                        setModalClients(true);
                      }}
                      className="btn btn--medium btn--primary"
                    >
                      Přidat klienta
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
