import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import Filter from "./Filter";
import ModalRunningWhite from "./modal_running_white";
import Tooltip from "react-bootstrap/Tooltip";
import ModalInfo from "./modal_info";
import ModalFiEvidence from "./Order-FI-Modal_fi_evidence";
import ModalFiUpdateFeeOS from "./Order-FI-Modal_fi_update_fee_os";
import ModalFiFakturace from "./Order-FI-Modal_fi_fakturace";
import FinancialInsightFeeOsTable from "./Order-FI_fee_os_table";
import ModalFIDelete from "./Order-FI-Modal_delete";
import { numberWithSpaces } from "../helpers";
import { useParams } from "react-router-dom";
export default function FinancialInsightFeeOS(props) {
  const [financialInsight, setFinancialInsight] = useState([]);
  const [financialInsightF, setFinancialInsightF] = useState([]);

  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalEvidence, setModalEvidence] = useState(false);
  const [modalFakturace, setModalFakturace] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [invoiceToUpdate, setInvoiceToUpdate] = useState([]);
  const [uctarna, setUctarna] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);
  const [sortDirection, setSortDirection] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [invoiceCreated, setInvoiceCreated] = useState(false); // pridane kvoli re run /financialinsigh v useffecte po vytvoreni faktury
  const params = useParams();

  useEffect(() => {
    const url_query_string =
      "/financialinsight?kampan_id=" +
      params.zakazka +
      "&mediatyp=" +
      params.mediatyp;

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        setFinancialInsight(res.data);
        setFinancialInsightF(res.data);
        setLoading(false);
      } //,
      //(err) => {
      //  console.log(err);
      //}
    );
    console.log(props.invoiceInsight);
  }, [invoiceCreated]);

  const sortColumn = (colName) => {
    const values = [...financialInsightF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setFinancialInsightF(values);
  };

  const SelectMediaplan = (event, index, vydavatel) => {
    const values = [...financialInsightF];

    values[index]["SELECTED"] = event.target.checked === true ? 1 : 0;

    setFinancialInsightF(values);
  };

  const SelectAll = () => {
    const values = [...financialInsightF];
    var value_to_change = selectAll == false ? 1 : 0;

    values.map(function (x) {
      x.SELECTED = x.ID_INVOICE_TO_HELIOS == null ? value_to_change : 0;
      return x;
    });

    setSelectAll(!selectAll);
    setFinancialInsightF(values);
  };

  const updateInvoice = (ID) => {
    let values = JSON.parse(JSON.stringify(financialInsight));

    setModalUpdate(true);
    setInvoiceToUpdate(values.filter((o) => o.ID === ID));
  };

  const renderTooltip = (col) => {
    if (col == "DATUM_UVEREJNENI")
      var tip =
        "Období, ve kterém byla kampaň on-air a které zahrnuje vyúčtování na faktuře.";

    if (col == "CNNN")
      var tip =
        "Klientovi skutečně fakturovaná cena u dané položky za dané období včetně dodatečných nákladů (fee).";

    if (col == "NOTE_1") var tip = "Jakákoliv dodatečná poznámka.";

    if (col == "DEPOSIT_INVOICE_AMOUNT")
      var tip =
        "Část ceny mediálního prostoru, která již byla uhrazena dříve prostřednictvím zálohové faktury.";

    return <Tooltip {...props}>{tip}</Tooltip>;
  };

  if (isLoading) {
    return <Loader message="" />;
  }

  return (
    <>
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <ModalFiEvidence
        vybranaZakazka={params.zakazka}
        vybranyMediatyp={params.mediatyp}
        setFinancialInsight={setFinancialInsight}
        setFinancialInsightF={setFinancialInsightF}
        modalEvidence={modalEvidence}
        setModalEvidence={setModalEvidence}
        setIsError={setIsError}
        setInfoBox={setInfoBox}
      />
      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              {props.user < 6 ? (
                <>
                  <ModalFiUpdateFeeOS
                    vybranaZakazka={params.zakazka}
                    vybranyMediatyp={params.mediatyp}
                    setFinancialInsight={setFinancialInsight}
                    setFinancialInsightF={setFinancialInsightF}
                    invoiceToUpdate={invoiceToUpdate}
                    setInvoiceToUpdate={setInvoiceToUpdate}
                    modalUpdate={modalUpdate}
                    setModalUpdate={setModalUpdate}
                    setIsError={setIsError}
                    setInfoBox={setInfoBox}
                  />
                  <ModalFIDelete
                    vybranaZakazka={params.zakazka}
                    vybranyMediatyp={params.mediatyp}
                    setFinancialInsight={setFinancialInsight}
                    setFinancialInsightF={setFinancialInsightF}
                    financialInsightF={financialInsightF}
                    modalDelete={modalDelete}
                    setModalDelete={setModalDelete}
                    setIsError={setIsError}
                    setInfoBox={setInfoBox}
                    setUctarna={setUctarna}
                  />

                  {modalFakturace ? (
                    <ModalFiFakturace
                      setFinancialInsight={setFinancialInsight}
                      setFinancialInsightF={setFinancialInsightF}
                      financialInsight={financialInsight}
                      modalFakturace={modalFakturace}
                      setModalFakturace={setModalFakturace}
                      setIsError={setIsError}
                      setInfoBox={setInfoBox}
                      setUctarna={setUctarna}
                      setInvoiceCreated={setInvoiceCreated}
                      vybranaZakazka={params.zakazka}
                      vybranyMediatyp={params.mediatyp}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <div class="table-container">
                <FinancialInsightFeeOsTable
                  financialInsight={financialInsight}
                  financialInsightF={financialInsightF}
                  setFinancialInsightF={setFinancialInsightF}
                  renderTooltip={renderTooltip}
                  sortColumn={sortColumn}
                  parentFI={"standard"}
                  uctarna={uctarna}
                  SelectMediaplan={SelectMediaplan}
                  updateInvoice={updateInvoice}
                  numberWithSpaces={numberWithSpaces}
                  vybranyMediatyp={params.mediatyp}
                />
                {props.user < 6 ? (
                  <div class="action-bar">
                    <div class="action-bar__in">
                      {uctarna == 0 ? (
                        <>
                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => setModalEvidence(true)}
                          >
                            Evidovat novou službu
                          </a>
                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => setUctarna(1)}
                          >
                            Vybrat řádky k fakturaci
                          </a>
                          <a
                            class="btn btn--medium btn--bordered"
                            onClick={() => setUctarna(2)}
                          >
                            Odstranit řádky
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                      {uctarna == 1 ? (
                        <>
                          <a
                            class="btn btn--medium btn--transparent"
                            onClick={SelectAll}
                          >
                            Vybrat všechny řádky
                          </a>
                          <a
                            class="btn btn--medium btn--transparent"
                            onClick={() => setUctarna(0)}
                          >
                            Skrýt výběr řádků
                          </a>

                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => setModalFakturace(true)}
                          >
                            Pokračovat
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                      {uctarna == 2 ? (
                        <>
                          <a
                            class="btn btn--medium btn--transparent"
                            onClick={() => setUctarna(0)}
                          >
                            Skrýt výběr řádků
                          </a>

                          <a
                            class="btn btn--medium btn--red"
                            onClick={() => setModalDelete(true)}
                          >
                            Pokračovat
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
