import React, { useState } from "react";
import { Outlet } from "react-router-dom";

export default function SafeKurzyMena(props) {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <div class="tabs">
        <div class="container">
          <div class="tabs__in">
            <div
              class={
                selectedTab == 0
                  ? "tabs__item tabs__item--active"
                  : "tabs__item"
              }
              onClick={() => {
                setSelectedTab(0);
                props.setSelectedMena("EUR");
              }}
            >
              EUR
            </div>

            <div
              class={
                selectedTab == 1
                  ? "tabs__item tabs__item--active"
                  : "tabs__item"
              }
              onClick={() => {
                setSelectedTab(1);
                props.setSelectedMena("USD");
              }}
            >
              USD
            </div>

            <div
              class={
                selectedTab == 2
                  ? "tabs__item tabs__item--active"
                  : "tabs__item"
              }
              onClick={() => {
                setSelectedTab(2);
                props.setSelectedMena("GBP");
              }}
            >
              GBP
            </div>

            <div
              class={
                selectedTab == 3
                  ? "tabs__item tabs__item--active"
                  : "tabs__item"
              }
              onClick={() => {
                setSelectedTab(3);
                props.setSelectedMena("PLN");
              }}
            >
              PLN
            </div>

            <div
              class={
                selectedTab == 4
                  ? "tabs__item tabs__item--active"
                  : "tabs__item"
              }
              onClick={() => {
                setSelectedTab(4);
                props.setSelectedMena("JPY");
              }}
            >
              JPY
            </div>

            <div
              class={
                selectedTab == 5
                  ? "tabs__item tabs__item--active"
                  : "tabs__item"
              }
              onClick={() => {
                setSelectedTab(5);
                props.setSelectedMena("CHF");
              }}
            >
              CHF
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
}
