import React, { useState } from "react";
import axios from "axios";
import ModalInfo from "./modal_info";
import ModalClientUsers from "./ClientsManagement-Modal_client_users";

export default function ModalClientUpdate(props) {
  const [action, setAction] = useState("");

  const UpdateClient = () => {
    const data = props.clientToUpdate[0];
    // console.log(data);

    const url_query_string = "/clients?type=update";

    axios
      .post(url_query_string, data)
      .then((res) => {
        console.log(res);
        props.setClients(res.data);
        props.setModalUpdate(false);
        props.setInfoBox("Údaje o klientovi byli změneny.");
        props.setIsError(false);
      })
      .catch((err) => {
        console.log(err);
        props.setModalUpdate(false);
        props.setInfoBox("Při úpravě klienta došlo k chybě!");
        props.setIsError(true);
      });
    setAction("");
  };

  const Deactivation = () => {
    // console.log(data);
    const url_query_string =
      props.clientToUpdate[0]["STATUS"] == 1
        ? "/clients?type=deactivate&id=" + props.clientToUpdate[0]["ID"]
        : "/clients?type=activate&id=" + props.clientToUpdate[0]["ID"];

    axios
      .post(url_query_string)
      .then((res) => {
        console.log(res);
        props.setClients(res.data);
        props.setClientsF(res.data);
        props.setModalUpdate(false);
      })
      .catch((err) => {
        console.log(err);
      });

    setAction("");
  };

  //var b = props.clients.find((a) => a.KOD_ZAKAZKA.indexof(kod));
  const toggleModal = () => {
    props.setModalUpdate(!props.modalUpdate);
    setAction("");
  };

  const ChangeVal = (value, name) => {
    const values = [...props.clientToUpdate];

    values[0][name] = value;

    props.setClientToUpdate(values);
  };

  return (
    <>
      {props.modalUpdate && (
        <>
          {action === "" ? (
            <div class="modal-window">
              <div class="modal-window__wrap">
                <div class="modal-window__close" onClick={toggleModal}>
                  <div class="icon">
                    <svg>
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref="/assets/img/sprite.svg#cross"
                      />
                    </svg>
                  </div>
                </div>
                <div class="modal-window__in">
                  <div class="modal-window__title">
                    Co chcete udělat s klientem{" "}
                    {props.clientToUpdate[0]["CLIENT"]}?
                  </div>

                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--primary"
                      onClick={() => setAction("update")}
                    >
                      Upravit
                    </a>
                    <a
                      class="btn btn--medium btn--primary"
                      onClick={() => setAction("users")}
                    >
                      Spravovat uživatelé
                    </a>
                    <a
                      class="btn btn--medium btn--red"
                      onClick={() => setAction("deactivate")}
                    >
                      {props.clientToUpdate[0]["STATUS"] == 1
                        ? "Deaktivovat"
                        : "Aktivovat"}
                    </a>
                  </div>
                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Storno
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {action === "deactivate" ? (
            <div class="modal-window">
              <div class="modal-window__wrap">
                <div class="modal-window__close" onClick={toggleModal}>
                  <div class="icon">
                    <svg>
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref="/assets/img/sprite.svg#cross"
                      />
                    </svg>
                  </div>
                </div>
                <div class="modal-window__in">
                  <div class="modal-window__title">
                    {props.clientToUpdate[0]["STATUS"] == 1
                      ? "Opravdu chcete deaktivovat klienta " +
                        props.clientToUpdate[0]["CLIENT"] +
                        "?"
                      : "Opravdu chcete aktivovat klienta " +
                        props.clientToUpdate[0]["CLIENT"] +
                        "?"}
                    {}?
                  </div>

                  <div class="modal-window__actions">
                    <a class="btn btn--medium btn--red" onClick={Deactivation}>
                      Ano
                    </a>

                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Ne
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {action === "update" ? (
            <div class="modal-window">
              <div class="modal-window__wrap">
                <div class="modal-window__close" onClick={toggleModal}>
                  <div class="icon">
                    <svg>
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref="/assets/img/sprite.svg#cross"
                      />
                    </svg>
                  </div>
                </div>
                <div class="modal-window__in">
                  <div class="modal-window__title">Editace klienta</div>
                  <div class="modal-window__content">
                    <div class="form">
                      <form class="form__in">
                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Název
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["CLIENT"]}
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Sídlo
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["Ulice"]}
                              //  onChange={(e) => UpdateInvoice(e.target.value)}
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            IČO
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["ICO"]}
                              // onChange={(e) => UpdateInvoice(e.target.value)}
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            DIČ
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["DIC"]}
                              readOnly
                              //   onChange={(e) => UpdateInvoice(e.target.value)}
                            />
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Brand
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["BRAND"]}
                              onChange={(e) =>
                                ChangeVal(e.target.value, "BRAND")
                              }
                            />
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Klient ID
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["CLIENT_ID"]}
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Brand ID
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["BRAND_ID"]}
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            Kód zakázky
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              value={props.clientToUpdate[0]["KOD_ZAKAZKA"]}
                              readOnly
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <br></br>
                  <div class="modal-window__actions">
                    <a
                      class="btn btn--medium btn--primary"
                      onClick={UpdateClient}
                    >
                      Uložit změny
                    </a>
                    <a
                      class="btn btn--medium btn--bordered"
                      onClick={toggleModal}
                    >
                      Storno
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {action === "users" ? (
            <ModalClientUsers
              clientToUpdateID={props.clientToUpdate[0]["ID"]}
              setModalUpdate={props.setModalUpdate}
              modalUpdate={props.modalUpdate}
              setAction={setAction}
              setIsError={props.setIsError}
              setInfoBox={props.setInfoBox}
              setOrders={props.setOrders}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
