import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import Filter from "./Filter";
import ModalInvoicesAction from "./Order-RI-Modal_invoices_action";
import {
  numberWithSpaces,
  formatDateTime,
  formatDate,
  replaceComma,
} from "../helpers";
import { useParams } from "react-router-dom";
import ModalConfirmationBox from "./modal_confirmation_box";
import ModalRunningWhite from "./modal_running_white";
import ModalInfo from "./modal_info";

export default function PendingInvoices(props) {
  const [inv, setInv] = useState([]);
  const [invP, setInvP] = useState([]);
  const [invPFa, setInvPFa] = useState([]);
  const [di, setDI] = useState([]);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [vybranaFaktura, setVybranaFaktura] = useState("");

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [invoiceDeleted, setInvoiceDeleted] = useState(false);

  const [isLoading, setLoading] = useState(true);

  const [sortDirection, setSortDirection] = useState(false);
  const params = useParams();

  useEffect(() => {
    const url_query_string =
      "/heliosinvoices?kampan_id=" + params.zakazka + "&type=pending_invoices";

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        setInv(res.data[0]["INV"]);
        setInvP(res.data[0]["INV_P"]);
        setInvPFa(res.data[0]["INV_P_FA"]);
        setDI(res.data[0]["DI"]);
        setLoading(false);
      } //,
      //(err) => {
      //  console.log(err);
      //}
    );
  }, [invoiceDeleted]);

  const DeleteInvoice = (id) => {
    const url_query_string =
      "/invoiceevidence?typ=delete" +
      "&mediatyp=" +
      params.mediatyp +
      "&id_inv=" +
      id;

    setIsRunning(true);
    setInfoMessage("Odstraňuji fakturu");

    axios.post(url_query_string).then(
      (res) => {
        setModalConfirmation(false);
        setInfoBox("Faktura byla úspěšně smazána.");
        setIsRunning(false);
        setInvoiceDeleted(!invoiceDeleted);
      },
      (err) => {
        console.log(err);
        setModalConfirmation(false);
        setIsRunning(false);
        setIsError(true);
        setInfoBox("Došlo k chybě!");
      }
    );
  };

  const sortColumn = (colName) => {
    const values = [...inv];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setInv(values);
  };

  if (isLoading) {
    return <Loader message="Načítám data." />;
  }

  return (
    <>
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              <ModalConfirmationBox
                setModalConfirmation={setModalConfirmation}
                modalConfirmation={modalConfirmation}
                Confirm={DeleteInvoice}
                confirmParam={vybranaFaktura}
                textConfirmation={
                  "Opravdu chcete odstranit fakturu s ID " +
                  vybranaFaktura +
                  "?"
                }
              />
              <ModalRunningWhite
                isRunning={isRunning}
                setIsRunning={setIsRunning}
                message={infoMessage}
              />
              <ModalInfo
                isError={isError}
                setIsError={setIsError}
                infoBox={infoBox}
                setInfoBox={setInfoBox}
              />
              <div class="table-container">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--hidden table__col--w1572"></div>
                      <div class="table__col table__col--w340">Účetnictví</div>
                      <div class="table__col table__col--w340">Faktura</div>
                    </div>
                  </div>

                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--w140">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("ID")}
                        >
                          Systémové ID faktury
                        </span>
                      </div>
                      <div class="table__col table__col--w200">
                        Vytvoření faktury
                      </div>
                      <div class="table__col table__col--w140">Období</div>
                      <div class="table__col table__col--w200">
                        Text faktury
                      </div>
                      <div class="table__col table__col--w200">
                        Poznámka k fakturaci
                      </div>
                      <div class="table__col table__col--w200">PO klienta</div>
                      <div class="table__col table__col--w100">Měna</div>
                      <div class="table__col table__col--w200">Kategorie</div>
                      <div class="table__col table__col--w200">Útvar</div>
                      <div class="table__col table__col--w140">
                        Cena bez DPH
                      </div>
                      <div class="table__col table__col--w200">
                        Text položky
                      </div>
                      <div class="table__col table__col--w140">
                        Cena bez DPH
                      </div>
                      <div class="table__col table__col--w200">
                        Text položky
                      </div>
                      <div class="table__col table__col--w200">Klient</div>

                      <div class="table__col table__col--w200">Zakázka</div>
                      <div class="table__col table__col--w140">
                        Číslo zálohovky
                      </div>
                      <div class="table__col table__col--w140">
                        Čerpání zálohovky
                      </div>
                      <div class="table__col table__col--flexible"></div>
                    </div>
                  </div>
                  <div class="table__body">
                    {inv.map((inv, index) => (
                      <>
                        <div class={"table__row"} key={index}>
                          <div class="table__col table__col--w140">
                            {inv.ID}
                          </div>
                          <div class="table__col table__col--w200">
                            {formatDateTime(inv.VALID_FROM)}
                          </div>
                          <div class="table__col table__col--w140">
                            {formatDate(inv.OBDOBI)}
                          </div>
                          <div class="table__col table__col--w200">
                            {inv.TEXT_FAKTURY}
                          </div>
                          <div class="table__col table__col--w200">
                            {inv.POZNAMKA}
                          </div>
                          <div class="table__col table__col--w200">
                            {inv.KLIENT_PO_INV}
                          </div>
                          <div class="table__col table__col--w100">
                            {inv.MENA}
                          </div>
                          <div class="table__col table__col--w200">
                            <ul class="table-unordered-lists">
                              {invP
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {polozky.NAZEV_REG == null
                                      ? "<empty>"
                                      : polozky.NAZEV_REG}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w200">
                            <ul class="table-unordered-lists">
                              {invP
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {polozky.NAZEV == null
                                      ? "<empty>"
                                      : polozky.NAZEV}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w140">
                            <ul class="table-unordered-lists">
                              {invP
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {numberWithSpaces(polozky.AMOUNT) == null
                                      ? "<empty>"
                                      : numberWithSpaces(polozky.AMOUNT)}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w200">
                            <ul class="table-unordered-lists">
                              {invP
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {polozky.TEXT == null
                                      ? "<empty>"
                                      : polozky.TEXT}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w140">
                            <ul class="table-unordered-lists">
                              {invPFa
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {numberWithSpaces(polozky.AMOUNT) == null
                                      ? "<empty>"
                                      : numberWithSpaces(polozky.AMOUNT)}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w200">
                            <ul class="table-unordered-lists">
                              {invPFa
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {polozky.TEXT == null
                                      ? "<empty>"
                                      : polozky.TEXT}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w200">
                            <ul class="table-unordered-lists">
                              {invP
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {polozky.CLIENT == null
                                      ? "<empty>"
                                      : polozky.CLIENT}
                                  </li>
                                ))}
                            </ul>
                          </div>

                          <div class="table__col table__col--w200">
                            <ul class="table-unordered-lists">
                              {invP
                                .filter((o) => o.ID_FAKTURY_ZS == inv.ID)
                                .map((polozky) => (
                                  <li>
                                    {polozky.ID_KAMPAN == null
                                      ? "<empty>"
                                      : polozky.ID_KAMPAN}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w140">
                            <ul class="table-unordered-lists">
                              {di
                                .filter((o) => o.ID_FAKTURY == inv.ID)
                                .map((zalohovky) => (
                                  <li>
                                    {zalohovky.ParovaciZnak == null
                                      ? "<empty>"
                                      : zalohovky.ParovaciZnak}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--w140">
                            <ul class="table-unordered-lists">
                              {di
                                .filter((o) => o.ID_FAKTURY == inv.ID)
                                .map((zalohovky) => (
                                  <li>
                                    {numberWithSpaces(
                                      zalohovky.CerpaniCastka
                                    ) == null
                                      ? "<empty>"
                                      : numberWithSpaces(
                                          zalohovky.CerpaniCastka
                                        )}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div class="table__col table__col--flexible">
                            {inv.Realizovano != 1 ? (
                              <a
                                onClick={() => {
                                  setModalConfirmation(true);
                                  setVybranaFaktura(inv.ID);
                                }}
                                class="btn btn--small btn--bordered"
                              >
                                Odstranit
                              </a>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ))}
                    <div class="table__row" key="sumacny">
                      <div class="table__col table__col--w400 table__col--hidden"></div>
                      <div class="table__col table__col--w400 table__col--hidden"></div>
                      <div class="table__col table__col--w400 table__col--hidden"></div>
                      <div class="table__col table__col--w200 table__col--hidden"></div>
                      <div class="table__col table__col--w175 table__col--hidden"></div>

                      <div class="table__col table__col--w140 table__col--highlight-green">
                        {numberWithSpaces(
                          invP
                            .filter((o) =>
                              inv.some(
                                (invItem) => invItem.ID === o.ID_FAKTURY_ZS
                              )
                            )
                            .reduce((n, { AMOUNT }) => n + AMOUNT, 0)
                        )}
                      </div>
                      <div class="table__col table__col--w200 table__col--hidden"></div>
                      <div class="table__col table__col--w140 table__col--highlight-green">
                        {numberWithSpaces(
                          invP
                            .filter((o) =>
                              inv.some(
                                (invItem) => invItem.ID === o.ID_FAKTURY_ZS
                              )
                            )
                            .reduce((n, { AMOUNT }) => n + AMOUNT, 0)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
