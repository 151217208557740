import axios from "axios";
import React, { useEffect, useState } from "react";
//import "bootstrap/dist/css/bootstrap.min.css";
import fileDownload from "js-file-download";
import { formatDate } from "../helpers";
import ModalRunningWhite from "./modal_running_white";
export default function UnsortedCommunication(props) {
  const [infoMessage, setInfoMessage] = useState("");
  const [isRunning, setIsRunning] = useState(false);

  const handleChangeInput2 = (event, ID) => {
    const arr = [...props.mails];

    var value_to_change;
    value_to_change = event.target.value;

    var colname_to_change;
    colname_to_change = "EMAIL_TO";

    //treba otestovat ci uzivatel nemeni rovnakeho uzivatela, ak ano tak len updatnejme v novom array hodnotu
    const obj = arr.find((o) => o.ID === ID);
    obj[colname_to_change] = value_to_change;
    props.setMails(arr);

    axios.post("/emailsupdate?delete=0", obj).then((res) => {
      console.log(res);
      props.setMails(res.data);
    });
  };

  const deleteEmail = (ID) => {
    const url_query_string = "/emailsupdate?delete=1&id=" + ID;
    setIsRunning(true);
    setInfoMessage("Mazání emailu probíhá, prosím čekejte...");
    axios
      .post(url_query_string)
      .then((res) => {
        props.setEmailDeleted(!props.emailDeleted);
        setIsRunning(false);
      })
      .catch((err) => {
        setIsRunning(false);
      });
  };

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  let urldown;

  const downloadEmail = async (ID, to, date) => {
    var url_query_string = "/emailsdownload?id=" + ID + "&what=email";

    const response = await axios({
      method: "GET",
      url: url_query_string,
    });
    console.log(response);
    urldown = response.data;

    //na presignedurl pouzivame fetch lebo ta nejde posielat TOKENY!!!!
    const uninterceptedAxiosInstance = axios.create();

    uninterceptedAxiosInstance(urldown, {
      method: "GET", //,
      responseType: "blob",
    })
      .then((res) => {
        fileDownload(res.data, to + "_" + formatDate(date) + ".eml");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div class="content__main">
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <div class="table">
        <div class="table__head">
          <div class="table__row">
            <div class="table__col table__col--pin">
              <div class="table-pin">
                <div class="icon"></div>
              </div>
            </div>

            <div class="table__col table__col--w200">Datum</div>

            <div class="table__col table__col--w280">Odesilatel</div>

            <div class="table__col table__col--w200">Odesláno k</div>
            <div class="table__col table__col--w400">Předmět</div>

            <div class="table__col table__col--w280">Zakázka</div>
            <div class="table__col table__col--flexible"></div>
          </div>
        </div>

        <div class="table__body">
          {props.mails.map((mails, index) => (
            <div class="table__row" key={index}>
              <div class="table__col table__col--pin">
                <div class="table-pin">
                  <div
                    class="icon"
                    onClick={() =>
                      downloadEmail(mails.ID, mails.EMAIL_TO, mails.VALID_FROM)
                    }
                  >
                    <svg>
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref="/assets/img/sprite.svg#email2"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div class="table__col table__col--w200">
                {formatDate(mails.VALID_FROM)}
              </div>
              <div class="table__col table__col--w280">
                {mails.SENDER_ADDRESS}
              </div>
              <div class="table__col table__col--w200">{mails.EMAIL_TO}</div>
              <div class="table__col table__col--w400">
                {mails.EMAIL_SUBJECT}
              </div>
              <div class="table__col table__col--w280">
                <select
                  className="form-select"
                  name="EMAIL_TO"
                  value={mails.EMAIL_TO}
                  onChange={(event) => handleChangeInput2(event, mails.ID)}
                >
                  <option>Přiřadit zakázku</option>
                  {props.zakazka.map((zakazka) => (
                    <option value={zakazka.KAMPAN_ID}>
                      {zakazka.KAMPAN_ID}
                    </option>
                  ))}
                </select>
              </div>
              <div class="table__col table__col--flexible">
                <a
                  onClick={() => deleteEmail(mails.ID)}
                  class="btn btn--medium btn--bordered"
                >
                  Odstranit
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
