import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import ModalFiFakturaceOrders from "./Order-FI-Modal_fi_fakturace-Orders";
import ModalFIFi from "./Order-FI-Modal_fi_fakturace-Fi";
import ModalFIFiDobropis from "./Order-FI-Modal_fi_fakturace-Fi-dobropis";
import ModalFIComm from "./Order-FI-Modal_fi_fakturace-Comm";
import ModalFIView from "./Order-FI-Modal_fi_fakturace-Fi_view";
import ModalFIDI from "./Order-FI-Modal_fi_fakturace-DI";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  IsValidDate,
  CheckValidPaste,
  formatDate,
} from "../helpers";
import ModalInfo from "./modal_info";
import FiTextCastkaUcto from "./Order-FI-text_castka_ucto";
import FiTextCastkaF from "./Order-FI-text_castka_faktura";
import FiTextCastkaFS from "./Order-FI-text_castka_faktura_s";
import Validation from "./Validation";
export default function ModalFiFakturace(props) {
  const [validace, setValidace] = useState("");
  const [validaceP, setValidaceP] = useState(true);

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [vyber, setVyber] = useState(6);
  const [predVyber, setPredVyber] = useState(0);

  const [modalZakazky, setModalZakazky] = useState(false);
  const [modalFI, setModalFI] = useState(false);
  const [modalComm, setModalComm] = useState(false);
  const [modalFIView, setModalFIView] = useState(0);
  const [financialInsightModal, setFinancialInsightModal] = useState([]);
  const [financialInsightModalF, setFinancialInsightModalF] = useState([]);
  const [modalDI, setModalDI] = useState(false);

  const [invoiceToEvidate, setInvoiceToEvidate] = useState(null);

  const [druhaZakazka, setDruhaZakazka] = useState(null);
  const [druhyMediatyp, setDruhyMediatyp] = useState(null);
  const [zakazkaVyber, setZakazkaVyber] = useState(1); //vyber zakazky do modalu na vybratie prilohy

  const [stredisko1, setStredisko1] = useState(0); //vyber zakazky do modalu na vybratie prilohy
  const [stredisko2, setStredisko2] = useState(0); //vyber zakazky do modalu na vybratie prilohy

  const [attachments, setAttachments] = useState([
    { ZAKAZKA: 1, attID: null, att: null },
    { ZAKAZKA: 2, attID: null, att: null },
  ]);

  const [obdobi, setObdobi] = useState(null);
  const [text, setText] = useState(
    JSON.parse(localStorage.getItem("zakazka")).find(
      (x) => x.KAMPAN_ID === props.vybranaZakazka
    )["NAZEV_ZAKAZKY"]
  );
  const [poznamka, setPoznamka] = useState(null);
  const [klientPO, setKlientPO] = useState(
    JSON.parse(localStorage.getItem("zakazka")).find(
      (x) => x.KAMPAN_ID === props.vybranaZakazka
    )["KLIENT_PO"]
  );
  const [mena, setMena] = useState("CZK");
  const [polozkyRozdil, setPolozkyRozdil] = useState(0);

  const [depositInvoices, setDepositInvoices] = useState([]);
  const [depositInvoicesF, setDepositInvoicesF] = useState([]);

  const [safeKurzy, setSafeKurzy] = useState([]);
  const [safeKurz, setSafeKurz] = useState(0);

  const polozkyDefault = [
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
    {
      TEXT: "",
      ID_HELIOS_P: null,
      NAZEV: "",
      AMOUNT: null,
      AMOUNT_FC: null,
      ID_KAMPAN: null,
      STREDISKO: null,
      ID_STREDISKO: null,
      SKUPINA: null,
    },
  ];
  const [polozky, setPolozky] = useState(polozkyDefault);

  useEffect(() => {
    //obdobi ked sa nachadaze v prvych 15 dnoch v mesiace tak deafultne je minuly mesiac ak po, tak aktualny
    const url_query_string =
      "/heliosinvoices?kampan_id=" + props.vybranaZakazka + "&type=safe_kurzy";

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        setSafeKurzy(res.data);
      },
      (err) => {
        console.log(err);
      }
    );

    var current_month = new Date();
    var previous_month = new Date();
    previous_month.setMonth(previous_month.getMonth() - 1);

    var test = parseInt(String(current_month.getDate()).padStart(2, "0"));
    if (test <= 15) {
      var mm = String(previous_month.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = previous_month.getFullYear();
    } else {
      var mm = String(current_month.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = current_month.getFullYear();
    }

    setObdobi(yyyy + "-" + mm);
    VyplnPolozky();
  }, []);

  const CreateInvoice = (fakturace) => {
    if (!IsValidDate(obdobi + "-01")) {
      setValidace("obdobi");
      return;
    }

    if (text == "") {
      setValidace("text");
      return;
    }

    for (let i = 0; i < polozky.length; i++) {
      var helios_p = polozky[i].ID_HELIOS_P;
      var id_stredisko = polozky[i].ID_STREDISKO;

      if (helios_p !== null && id_stredisko == null) {
        setValidace("stredisko");
        return;
      }
    }
    //pri rozpade poliziek na lubovolne nevyplnujeme kampan ID,
    // takze pole je prazdne treba ho vyplnit,
    //aj pre stredisko a aj pre samotnu fakturu
    if ((druhaZakazka == null) & (vyber == 6)) {
      polozky.map((obj) => {
        obj.ID_KAMPAN = props.vybranaZakazka;
      });
    }

    for (let i = 0; i < depositInvoices.length; i++) {
      if ("DRAWING" in depositInvoices[i])
        depositInvoices[i].DRAWING = parseFloat(
          depositInvoices[i].DRAWING.toString().replace(",", ".")
        );
    }

    for (let i = 0; i < polozky.length; i++) {
      if ("AMOUNT" in polozky[i])
        if ((polozky[i].AMOUNT !== null) & (polozky[i].AMOUNT !== undefined))
          polozky[i].AMOUNT = parseFloat(
            polozky[i].AMOUNT.toString().replace(",", ".")
          );
    }

    if (mena !== "CZK") {
      polozky.map(function (x) {
        x.AMOUNT_FC = x.AMOUNT / safeKurz;
        return x;
      });
    }

    polozky.map(function (x) {
      x.SKUPINA = x.SKUPINA == "Samostatně" ? 0 : x.SKUPINA;
      return x;
    });

    var data;

    data = {
      OBDOBI: obdobi + "-01",
      TEXT: text,
      POZNAMKA: poznamka,
      KLIENT_PO: klientPO,
      ATTACHMENT_1:
        attachments[0]["attID"] == false ? null : attachments[0]["attID"],
      ATTACHMENT_2:
        attachments[1]["attID"] == false ? null : attachments[1]["attID"],

      POLOZKY: polozky.filter((o) => o.ID_HELIOS_P !== null),
      MENA: mena,
      FI: props.financialInsight.filter((o) => o.SELECTED == 1),
      FI_2: financialInsightModal.filter((o) => o.SELECTED == 1),
      DEPOSIT_INVOICES: depositInvoices.filter((o) => o.SELECTED == 1),
    };

    setIsRunning(true);
    setInfoMessage("Zakládam fakturu.");

    const url_query_string =
      "/invoiceevidence?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&typ=create&vyber=" +
      vyber +
      "&agg=" +
      (druhaZakazka == null ? 0 : 1) +
      "&fakturace=" +
      fakturace +
      "&typfaktury=" +
      props.typFaktury +
      "&dobropisna=" +
      (props.typFaktury == "dobropis"
        ? props.invoiceToEvidate[0]["ID_INVOICE_TO_HELIOS"]
        : null) +
      "&druhymediatyp=" +
      (props.typFaktury == "dobropis"
        ? props.invoiceToEvidate[0]["MEDIATYP_DRUHY"]
        : null);

    axios
      .post(url_query_string, data)
      .then((res) => {
        console.log(res.data);
        props.setModalFakturace(false);
        setVyber(0);
        setDruhaZakazka(null);
        setIsRunning(false);
        props.setIsError(false);
        props.setInfoBox("Faktura byla založena.");
        if (props.typFaktury == "dobropis") {
          props.setModalToEvidate(false);
        } else {
          props.setUctarna(0);
          props.setInvoiceCreated(!props.invoiceCreated);
        }
      })
      .catch((err) => {
        console.log(err);
        setInfoBox("Při zakladání faktury došlo k chybě!");
        setIsRunning(false);
        setIsError(true);
      });
  };

  const InsertStredisko = (id_zakazky, id_stredisko, stredisko) => {
    const values = [...polozky];
    values.map(function (x) {
      x.STREDISKO = x.ID_KAMPAN == id_zakazky ? stredisko : x.STREDISKO;
      x.ID_STREDISKO =
        x.ID_KAMPAN == id_zakazky ? id_stredisko : x.ID_STREDISKO;
      return x;
    });
    setPolozky(values);
  };

  const VyplnPolozky = () => {
    const values = [...polozky];

    const vlozHodnoty = (idx, fi, zkzka, helios_p, nzv, sk, txt, amount_t) => {
      //first, second, potrebujeme vlozit 1/2 riadky, bez ropadu 1 hodnotu, s rozpadom hodnotu+fee
      // ID_HELIOS_P polozky v heliose z ciselniku. Zadefinovane Businesom.
      var amount;
      if (amount_t === "cnnn")
        amount = fi
          .filter((o) => o.SELECTED == 1) // hodnota CNNN vo fee je fee
          .reduce((n, { CNNN }) => n + CNNN, 0);
      if (amount_t === "mnnp")
        amount = fi
          .filter((o) => o.SELECTED == 1)
          .reduce((n, { MNNP }) => n + MNNP, 0);
      if (amount_t === "fee")
        amount = fi
          .filter((o) => o.SELECTED == 1)
          .reduce((n, { FEE }) => n + FEE, 0);

      values[idx]["ID_HELIOS_P"] = helios_p;
      values[idx]["NAZEV"] = nzv;
      values[idx]["ID_KAMPAN"] = zkzka;
      values[idx]["SKUPINA"] = sk;
      values[idx]["AMOUNT"] = amount;
      values[idx]["TEXT"] = txt;
    };
    var zkz = props.vybranaZakazka;
    var onm = "Ostatní nemediální služby";
    var mi = "Mediální Inzerce";
    var prvyMediatyp = props.vybranyMediatyp;
    var s = "Samostatně";

    if (prvyMediatyp == "Fee") {
      vlozHodnoty(0, props.financialInsight, zkz, 3, "Fee", s, "Fee", "cnnn");
    } else if (prvyMediatyp == "Kickback") {
      vlozHodnoty(0, props.financialInsight, zkz, 2, mi, s, mi, "cnnn"); // z OS neberieme FEE ale len CNNN
    } else if (prvyMediatyp == "Other services") {
      vlozHodnoty(0, props.financialInsight, zkz, 1, onm, s, onm, "cnnn"); // z OS neberieme FEE ale len CNNN
    } else {
      vlozHodnoty(0, props.financialInsight, zkz, 2, mi, "1", mi, "mnnp");
      vlozHodnoty(1, props.financialInsight, zkz, 3, "Fee", "1", mi, "fee");
    }

    if (druhaZakazka != null) {
      var i = ["Other services", "Fee"].includes(prvyMediatyp) ? 1 : 2; //od jakeho indexu mam zacat vkladat, fee a OS maju len jednu polozku
      var fiModal = financialInsightModal;

      if (druhyMediatyp == "Fee") {
        vlozHodnoty(i, fiModal, druhaZakazka, 3, "Fee", s, "Fee", "cnnn");
      } else if (druhyMediatyp == "Kickback") {
        vlozHodnoty(i, fiModal, druhaZakazka, 2, mi, s, mi, "cnnn"); // z OS neberieme FEE ale len CNNN
      } else if (druhyMediatyp == "Other services") {
        vlozHodnoty(i, fiModal, druhaZakazka, 1, onm, s, onm, "cnnn"); // z OS neberieme FEE ale len CNNN
      } else {
        vlozHodnoty(i, fiModal, druhaZakazka, 2, mi, "2", mi, "mnnp");
        vlozHodnoty(i + 1, fiModal, druhaZakazka, 3, "Fee", "2", mi, "fee");
      }
    }

    if (["TV", "Print", "Radio", "OOH", "Cinema"].includes(prvyMediatyp))
      InsertStredisko(zkz, 1, "Client Success");
    if (["Internet"].includes(prvyMediatyp))
      InsertStredisko(zkz, 2, "Digital Solutions");

    if (druhaZakazka != null) {
      if (["TV", "Print", "Radio", "OOH", "Cinema"].includes(druhyMediatyp))
        InsertStredisko(druhaZakazka, 1, "Client Success");
      if (["Internet"].includes(druhyMediatyp))
        InsertStredisko(druhaZakazka, 2, "Digital Solutions");
    }

    setPolozky(values);
  };

  const RozpadPolozekCheck = () => {
    for (let i = 0; i < polozky.length; i++) {
      var text = polozky[i].TEXT;
      var helios_p = polozky[i].ID_HELIOS_P;
      var amount = polozky[i].AMOUNT;
      var zakazka = druhaZakazka !== null ? polozky[i].ID_KAMPAN : null; //ked neni vybrata druha zakazka tak zakazku vyplnujeme az pri odosielani
      var id_stredisko = polozky[i].ID_STREDISKO;
      var skupina = polozky[i].SKUPINA;

      if (
        (text !== "" ||
          helios_p !== null ||
          amount !== null ||
          zakazka !== null ||
          id_stredisko !== null ||
          skupina !== null) &&
        (text == "" ||
          helios_p == null ||
          amount == null ||
          zakazka == (druhaZakazka !== null ? null : "a") ||
          id_stredisko == null ||
          skupina == null)
      ) {
        setValidace(i);
        if (
          text == "" &&
          helios_p !== null &&
          amount !== null &&
          zakazka !== (druhaZakazka !== null ? null : "a") &&
          id_stredisko !== null &&
          skupina !== null &&
          skupina !== "Samostatně"
        ) {
          setValidaceP(false);
        } else {
          setValidaceP(true);
        }
        return;
      }
    }

    var a = props.financialInsight
      .filter((o) => o.SELECTED == 1)
      .reduce((n, { CNNN }) => n + CNNN, 0);
    var b = financialInsightModal
      .filter((o) => o.SELECTED == 1)
      .reduce((n, { CNNN }) => n + CNNN, 0);
    var c = replaceComma(
      polozky
        .filter((o) => o.AMOUNT != null)
        .reduce((n, { AMOUNT }) => replaceComma(n) + replaceComma(AMOUNT), 0)
    );

    if (a + b - c > -0.1 && a + b - c < 0.1) {
      //kvoli zaokruhlovaniu staci ked to bude medzi
      setVyber(6);
      setValidace("");
    } else {
      setValidace("notzero");
    }
  };

  const MenaSafeKurz = (mena) => {
    setMena(mena);

    if (mena !== "CZK" && IsValidDate(obdobi + "-01")) {
      var safe = safeKurzy.find(
        (o) => o.MENA == mena && formatDate(o.OBDOBI) == obdobi + "-01"
      );
      safe != undefined && setSafeKurz(safe.NAKUP_CZK);
    }
  };

  const ObdobiSafeKurz = (obdobi) => {
    setObdobi(obdobi);

    if (mena !== "CZK" && IsValidDate(obdobi + "-01")) {
      var safe = safeKurzy.find(
        (o) => o.MENA == mena && formatDate(o.OBDOBI) == obdobi + "-01"
      );
      safe != undefined && setSafeKurz(safe.NAKUP_CZK);
    }
  };

  const DrawInvoice = (id, value) => {
    const values = [...depositInvoices];
    var item = values.find((o) => o.ID == id);
    item["DRAWING"] = value;

    setDepositInvoices(values);
  };

  const toggleModal = () => {
    setVyber(4);
    props.setModalFakturace(!props.modalFakturace);
  };

  return (
    <>
      {modalZakazky ? (
        <ModalFiFakturaceOrders
          setModalZakazky={setModalZakazky}
          setModalFI={setModalFI}
          setDruhaZakazka={setDruhaZakazka}
          setDruhyMediatyp={setDruhyMediatyp}
          setVyber={setVyber}
          predVyber={predVyber}
        />
      ) : (
        <></>
      )}

      {modalFI ? (
        props.typFaktury !== "dobropis" ? (
          <ModalFIFi
            setModalFI={setModalFI}
            druhaZakazka={druhaZakazka}
            setDruhaZakazka={setDruhaZakazka}
            druhyMediatyp={druhyMediatyp}
            setFinancialInsightModalF={setFinancialInsightModalF}
            financialInsightModalF={financialInsightModalF}
            setFinancialInsightModal={setFinancialInsightModal}
            financialInsightModal={financialInsightModal}
            setVyber={setVyber}
            predVyber={predVyber}
            VyplnPolozky={VyplnPolozky}
          />
        ) : (
          <ModalFIFiDobropis
            setModalFI={setModalFI}
            setDruhaZakazka={setDruhaZakazka}
            setDruhyMediatyp={setDruhyMediatyp}
            setFinancialInsightModalF={setFinancialInsightModalF}
            financialInsightModalF={financialInsightModalF}
            setFinancialInsightModal={setFinancialInsightModal}
            financialInsightModal={financialInsightModal}
            setVyber={setVyber}
            predVyber={predVyber}
            VyplnPolozky={VyplnPolozky}
            invoiceToEvidate={props.invoiceToEvidate}
          />
        )
      ) : (
        <></>
      )}
      {vyber == 3 ? (
        <div
          class={
            druhaZakazka == null
              ? "modal-window modal-window--w1300" //w760
              : "modal-window modal-window--w1500"
          }
        >
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                {"Celkovy Cost to Client: " + numberWithSpaces(polozkyRozdil)}
              </div>
              <Validation
                validace={validace}
                validace_custom={"notzero"}
                message={"Rozdíl musí být rovný nule!"}
              />

              <div class="modal-window__content">
                <div class="modal-window__subtitle">Položky do učetnictví</div>
                <form
                  class={
                    druhaZakazka == null
                      ? "modal-form-5__in"
                      : "modal-form-6__in"
                  }
                >
                  {polozky.map((polozka, index) => (
                    <>
                      <FiTextCastkaUcto
                        polozka={polozka}
                        setPolozky={setPolozky}
                        polozky={polozky}
                        idx={index}
                        druhaZakazka={druhaZakazka}
                        vybranaZakazka={props.vybranaZakazka}
                        setPolozkyRozdil={setPolozkyRozdil}
                        financialInsight={props.financialInsight}
                        financialInsightModal={financialInsightModal}
                        validace={validace}
                        validace_custom={index}
                        validaceP={validaceP}
                        mena={mena}
                        safeKurz={safeKurz}
                      />
                    </>
                  ))}
                  <FiTextCastkaUcto
                    polozka={{
                      TEXT: "TOTAL",
                      AMOUNT: numberWithSpaces(
                        polozky
                          .filter((o) => o.AMOUNT != null)
                          .reduce(
                            (n, { AMOUNT }) => n + replaceComma(AMOUNT),
                            0
                          )
                      ),
                    }}
                    setPolozky={setPolozky}
                    polozky={polozky}
                    idx={10}
                    druhaZakazka={druhaZakazka}
                    mena={mena}
                  />
                </form>
                <br></br>
                <div class="divider"> </div>
                <br></br>
                <div class="modal-window__subtitle">Položky na faktuře</div>

                <form
                  class={
                    druhaZakazka == null
                      ? "modal-form-5__in"
                      : "modal-form-6__in"
                  }
                >
                  {[
                    ...new Set(polozky.map(({ SKUPINA = [] }) => SKUPINA)), //toto by malo spravit array s unique skupoinami
                  ].map((skupina, index) =>
                    (skupina != null) & (skupina !== "Samostatně") ? (
                      <FiTextCastkaF
                        skupina={skupina}
                        setPolozky={setPolozky}
                        polozky={polozky}
                        idx={index}
                        druhaZakazka={druhaZakazka}
                        setPolozkyRozdil={setPolozkyRozdil}
                        financialInsight={props.financialInsight}
                        financialInsightModal={financialInsightModal}
                      />
                    ) : (
                      <></>
                    )
                  )}
                  {polozky.map((polozka, index) =>
                    (polozka.SKUPINA != null) &
                    (polozka.SKUPINA === "Samostatně") ? (
                      <FiTextCastkaFS
                        skupina={"Samostatně"}
                        setPolozky={setPolozky}
                        polozky={polozky}
                        polozka={polozka}
                        idx={index}
                        druhaZakazka={druhaZakazka}
                        setPolozkyRozdil={setPolozkyRozdil}
                        financialInsight={props.financialInsight}
                        financialInsightModal={financialInsightModal}
                      />
                    ) : (
                      <></>
                    )
                  )}
                </form>
              </div>
              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--bordered"
                  onClick={RozpadPolozekCheck}
                >
                  Potvrdit
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {modalComm ? (
        <ModalFIComm
          setModalComm={setModalComm}
          zakazka={zakazkaVyber == 1 ? props.vybranaZakazka : druhaZakazka}
          zakazkaVyber={zakazkaVyber}
          setVyber={setVyber}
          predVyber={predVyber}
          attachments={attachments}
          setAttachments={setAttachments}
        />
      ) : (
        <></>
      )}
      {modalDI ? (
        <ModalFIDI
          setModalDI={setModalDI}
          setVyber={setVyber}
          predVyber={predVyber}
          setDepositInvoices={setDepositInvoices}
          depositInvoices={depositInvoices}
          setDepositInvoicesF={setDepositInvoicesF}
          depositInvoicesF={depositInvoicesF}
          vybranaZakazka={props.vybranaZakazka}
        />
      ) : (
        <></>
      )}

      {/* POSLEDNE OKNOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO */
      /* POSLEDNE OKNOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO */
      /* POSLEDNE OKNOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO */
      /* POSLEDNE OKNOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO */
      /* POSLEDNE OKNOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO */}

      {vyber >= 4 ? (
        <div class="modal-window modal-window--w680">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={infoMessage}
          />
          <ModalInfo
            isError={isError}
            setIsError={setIsError}
            infoBox={infoBox}
            setInfoBox={setInfoBox}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Fakturační okno</div>
              <div class="modal-window__content">
                <div class="modal-form">
                  <form class="modal-form__in">
                    {props.typFaktury === "dobropis" ? (
                      props.invoiceToEvidate[0]["ID_KAMPAN_DRUHA"] != null ? (
                        <>
                          <div class="form__actions">
                            <a
                              class="btn btn--small btn--primary"
                              onClick={() => {
                                props.typFaktury == "dobropis"
                                  ? setModalFI(true)
                                  : setModalZakazky(true);
                                setPredVyber(vyber);
                                setVyber(-1);
                              }}
                            >
                              {props.typFaktury !== "dobropis"
                                ? "Fakturovat více zakázek na jednu fakturu"
                                : "Přidat položky ze zakázky " +
                                  props.invoiceToEvidate[0]["ID_KAMPAN_DRUHA"]}
                            </a>
                          </div>
                          <div class="modal-form__item">
                            <div class="modal-form__value"> </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <>
                        <div class="form__actions">
                          <a
                            class="btn btn--small btn--primary"
                            onClick={() => {
                              props.typFaktury == "dobropis"
                                ? setModalFI(true)
                                : setModalZakazky(true);
                              setPredVyber(vyber);
                              setVyber(-1);
                            }}
                          >
                            {props.typFaktury !== "dobropis"
                              ? "Fakturovat více zakázek na jednu fakturu"
                              : "Přidat položky ze zakázky " +
                                props.invoiceToEvidate[0]["ID_KAMPAN_DRUHA"]}
                          </a>
                        </div>
                        <div class="modal-form__item">
                          <div class="modal-form__value"> </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
                <br></br>
                <div class="form">
                  <form class=" form__in">
                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Fakturace v měně
                      </label>
                      <div class="form__value">
                        <select
                          class="form-select"
                          value={mena}
                          onChange={(e) => MenaSafeKurz(e.target.value)}
                        >
                          <option value={"CZK"}>CZK</option>
                          <option value={"EUR"}>EUR</option>
                          <option value={"GBP"}>GBP</option>
                          <option value={"CHF"}>CHF</option>
                          <option value={"JPY"}>JPY</option>
                          <option value={"PLN"}>PLN</option>
                          <option value={"USD"}>USD</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-form">
                  <form class="modal-form__in">
                    <div class="modal-form__item">
                      <label for="id" class="form-label form-label--inline">
                        Chci fakturovat za období
                      </label>

                      <div class="modal-form__value">
                        <input
                          class="form-input"
                          placeholder="YYYY-MM - Povinné"
                          value={obdobi}
                          onChange={(e) => ObdobiSafeKurz(e.target.value)}
                        />
                      </div>
                      {validace === "obdobi" ? (
                        <div class="validation">
                          <div class="validation__item">
                            Špatně vyplněný datum!
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div class="modal-form__item">
                      <label for="id" class="form-label form-label--inline">
                        PO číslo
                      </label>

                      <div class="modal-form__value">
                        <input
                          class="form-input"
                          value={klientPO}
                          onChange={(e) => setKlientPO(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="modal-form__item modal-form__item--full">
                      <label for="id" class="form-label form-label--inline">
                        Text faktury
                      </label>

                      <div class="modal-form__value">
                        <textarea
                          placeholder="Max. 500 znaků.."
                          type="text"
                          class="form-textarea"
                          value={text}
                          onChange={(e) => setText(e.target.value)}
                        />
                      </div>
                      {validace === "text" ? (
                        <div class="validation">
                          <div class="validation__item">Vyplňte!</div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </form>
                </div>
                {modalFIView >= 1 ? (
                  <ModalFIView
                    financialInsightModal={
                      modalFIView == 1
                        ? props.financialInsight
                        : financialInsightModal
                    }
                    setModalFIView={setModalFIView}
                    mediatyp={
                      modalFIView == 1 ? props.vybranyMediatyp : druhyMediatyp
                    }
                  />
                ) : (
                  <></>
                )}

                <div class="modal-form">
                  <form class="modal-form__in">
                    {druhaZakazka != null ? (
                      <>
                        <div class="modal-form__item">
                          <label
                            for="id"
                            class="form-label form-label--inline"
                            onClick={() => setModalFIView(1)}
                          >
                            Cost to Client - {props.vybranaZakazka},- Kč
                          </label>

                          <div class="modal-form__value">
                            <input
                              class="form-input"
                              value={numberWithSpaces(
                                props.financialInsight
                                  .filter((o) => o.SELECTED == 1)
                                  .reduce((n, { CNNN }) => n + CNNN, 0)
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="modal-form__item">
                          <label
                            for="id"
                            class="form-label form-label--inline"
                            onClick={() => setModalFIView(2)}
                          >
                            Cost to Client - {druhaZakazka},- Kč
                          </label>

                          <div class="modal-form__value">
                            <input
                              class="form-input"
                              value={numberWithSpaces(
                                financialInsightModal
                                  .filter((o) => o.SELECTED == 1)
                                  .reduce((n, { CNNN }) => n + CNNN, 0)
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div class="modal-form__item">
                      <label for="id" class="form-label form-label--inline">
                        Položky do učetníctví
                      </label>

                      <div class="modal-form__value">
                        <ul class="table-unordered-lists">
                          {polozky
                            .filter((o) => o.ID_HELIOS_P != null)
                            .map((polozky) => (
                              <li>
                                {polozky.NAZEV +
                                  " - " +
                                  numberWithSpaces(polozky.AMOUNT) +
                                  ",- Kč"}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div class="modal-form__item">
                      <label for="id" class="form-label form-label--inline">
                        Položky na faktuře
                      </label>

                      <div class="modal-form__value">
                        <ul class="table-unordered-lists">
                          {[
                            ...new Set( //toto by malo spravit array s unique skupoinami
                              polozky.map(({ SKUPINA = [] }) => SKUPINA)
                            ),
                          ].map((skupina, index) =>
                            (skupina != null) & (skupina !== "Samostatně") ? (
                              <li>
                                {polozky.find((o) => o.SKUPINA == skupina)[
                                  "TEXT"
                                ] +
                                  " - " +
                                  numberWithSpaces(
                                    polozky
                                      .filter(
                                        (o) =>
                                          (o.ID_HELIOS_P != null) &
                                          (o.SKUPINA == skupina)
                                      )
                                      .reduce(
                                        (n, { AMOUNT }) =>
                                          n + replaceComma(AMOUNT),
                                        0
                                      )
                                  ) +
                                  ",- Kč"}
                              </li>
                            ) : (
                              <></>
                            )
                          )}
                          {polozky
                            .filter(
                              (o) =>
                                (o.ID_HELIOS_P != null) &
                                (o.SKUPINA === "Samostatně")
                            )
                            .map((polozky) => (
                              <li>
                                {polozky.TEXT +
                                  " - " +
                                  numberWithSpaces(polozky.AMOUNT) +
                                  ",- Kč"}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div class="modal-form__item">
                      <div class="modal-form__value">
                        <div class="form__actions">
                          <a
                            class="btn btn--small btn--primary"
                            onClick={() => {
                              setVyber(3);
                            }}
                          >
                            Rozpad fakturačních položek
                          </a>
                        </div>
                        <Validation
                          validace={validace}
                          validace_custom={"stredisko"}
                          message={
                            "K fakturačním položkám nebylo přiřazeno středisko!"
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <br></br>
                <div class="form">
                  <form class="form__in">
                    {attachments.map((attachment, index) => (
                      <>
                        {(index == 1) & (druhaZakazka == null) ? (
                          <> </>
                        ) : attachment.attID != null ? (
                          <div class="form__item">
                            <label
                              for="id"
                              class="form-label form-label--inline"
                            >
                              Příloha k faktuře -{" "}
                              {index == 0 ? props.vybranaZakazka : druhaZakazka}
                            </label>
                            <div class="form__value">
                              <input
                                class="form-input"
                                value={attachment.att}
                                readOnly
                              />
                            </div>
                            <div class="form__actions">
                              <a
                                class="btn btn--small btn--bordered"
                                onClick={() => {
                                  setAttachments(
                                    attachments.map((o) =>
                                      o.ZAKAZKA == attachment.ZAKAZKA
                                        ? {
                                            ...o,
                                            attID: null,
                                          }
                                        : o
                                    )
                                  );
                                }}
                              >
                                X
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div class="form__item">
                            <label
                              for="id"
                              class="form-label form-label--inline"
                            >
                              Příloha k faktuře -{" "}
                              {index == 0 ? props.vybranaZakazka : druhaZakazka}
                            </label>
                            <div class="form__actions">
                              <a
                                class="btn btn--small btn--primary"
                                onClick={() => {
                                  setModalComm(true);
                                  setPredVyber(vyber);
                                  setVyber(-1);
                                  setZakazkaVyber(attachment.ZAKAZKA);
                                }}
                              >
                                Vybrat
                              </a>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                    {validace === "text" ? (
                      <div class="validation">
                        <div class="validation__item">
                          Vyberte jednu z možností!
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
                <div class=" form">
                  <form class=" form__in">
                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Poznamka k fakturaci
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={poznamka}
                          onChange={(e) => setPoznamka(e.target.value)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-form">
                  <form class="modal-form__in">
                    <div class="form__actions">
                      <a
                        class="btn btn--small btn--primary"
                        onClick={() => {
                          setModalDI(true);
                          setPredVyber(vyber);
                          setVyber(-1);
                        }}
                      >
                        Vybrat zálohovu fakturu
                      </a>
                    </div>
                    <div class="modal-form__item">
                      <div class="modal-form__value"> </div>
                    </div>
                    <div class="modal-form__item">
                      {depositInvoices
                        .filter((o) => o.SELECTED == 1)
                        .map((depositInvoices, index) => (
                          <>
                            {index == 0 ? (
                              <label
                                for="id"
                                class="form-label form-label--inline-wider"
                              >
                                Zálohove faktury – číslo, zustatek
                              </label>
                            ) : (
                              <></>
                            )}
                            <div class="modal-form__value">
                              <input
                                class="form-input"
                                value={
                                  depositInvoices.ParovaciZnak +
                                  " - " +
                                  numberWithSpaces(
                                    depositInvoices.SumaKcBezDPH -
                                      (depositInvoices.DRAW == null
                                        ? 0
                                        : depositInvoices.DRAW) -
                                      ((depositInvoices.DRAWING == undefined) |
                                      (depositInvoices.DRAWING == null)
                                        ? 0
                                        : replaceComma(depositInvoices.DRAWING))
                                  )
                                }
                                readOnly
                                key={index}
                              />
                            </div>
                          </>
                        ))}
                    </div>
                    <div class="modal-form__item">
                      {depositInvoices
                        .filter((o) => o.SELECTED == 1)
                        .map((depositInvoices, index) => (
                          <>
                            {index == 0 ? (
                              <label
                                for="id"
                                class="form-label form-label--inline-wider"
                              >
                                Čerpání ze zálohové faktury
                              </label>
                            ) : (
                              <></>
                            )}
                            <div class="modal-form__value">
                              <input
                                class="form-input"
                                value={depositInvoices.DRAWING}
                                onChange={(event) =>
                                  DrawInvoice(
                                    depositInvoices.ID,
                                    event.target.value
                                  )
                                }
                                key={index}
                                onKeyDown={(e) => checkValidInput(e)}
                                onPaste={(e) => CheckValidPaste(e)}
                              />
                            </div>
                          </>
                        ))}
                    </div>
                  </form>
                </div>
              </div>
              <br></br>
              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--primary"
                  onClick={() => CreateInvoice(1)}
                >
                  Odeslat k fakturaci
                </a>
                <a
                  class="btn btn--medium btn--primary"
                  onClick={() => CreateInvoice(0)}
                >
                  Odeslat - bez fakturace
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
