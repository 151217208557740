import React, { useState } from "react";
import Loader from "./Loader";

export default function ModalRunningWhite(props) {
  return (
    <>
      {props.isRunning && (
        <div class="modal-window-white">
          <div class="modal-window-white__wrap">
            <div class="modal-window-white__in">
              <br></br>
              <Loader message={props.message} />

              <br></br>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
