import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Filter from "./Filter";
export default function NewOrderModal(props) {
  // const [modal, setModal] = useState(false);
  const [zakazka, setZakazka] = useState([]);

  const [zakazkaF, setZakazkaF] = useState([]);
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [sortDirection, setSortDirection] = useState(false);

  const toggleModal = () => {
    props.setModal(!props.modal);
  };

  useEffect(() => {
    axios.get("/myorders?clone=true").then(
      (res) => {
        //potrebujeme zobrazit distinct zakazky
        const tableData = res.data[0]["ZAKAZKY"].map((row) => ({
          ID: row.ID,
          NAZEV_ZAKAZKY: row.NAZEV_ZAKAZKY,
          CLIENT: row.CLIENT,
          ID_CLIENTS: row.ID_CLIENTS,
          BRAND: row.BRAND,
          //SEGMENT: row.SEGMENT,
          OD: row.OD,
          DO: row.DO,
          CLIENT_SUCCESS_CONSULTANT: row.CLIENT_SUCCESS_CONSULTANT,
          CLIENT_LEAD: row.CLIENT_LEAD,
          NAVAZANE_ZAKAZKY: row.NAVAZANE_ZAKAZKY,
        }));
        const key = "ID";

        const arrayUniqueByKey = [
          ...new Map(tableData.map((item) => [item[key], item])).values(),
        ];

        setZakazka(arrayUniqueByKey);
        setZakazkaF(arrayUniqueByKey);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const PreFillOrder = (index) => {
    props.setID(zakazkaF[index]["ID"]);
    props.setNAZEV_ZAKAZKY(zakazkaF[index]["NAZEV_ZAKAZKY"]);
    props.setCLIENT(zakazkaF[index]["CLIENT"]);
    props.setID_CLIENTS(zakazkaF[index]["ID_CLIENTS"]);
    props.setBRAND(zakazkaF[index]["BRAND"]);
    //props.setSEGMENT(zakazka[index]["SEGMENT"]);
    props.setOD(new Date(zakazkaF[index]["OD"]));
    props.setDO(new Date(zakazkaF[index]["DO"]));
    props.setCLIENT_SUCCESS_CONSULTANT(
      zakazkaF[index]["CLIENT_SUCCESS_CONSULTANT"]
    );
    props.setCLIENT_LEAD(zakazkaF[index]["CLIENT_LEAD"]);
    props.setNAVAZANE_ZAKAZKY(zakazkaF[index]["NAVAZANE_ZAKAZKY"]);
    props.setPreFilled(1);
  };

  const sortColumn = (colName) => {
    const values = [...zakazkaF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    values.sort((a, b) => {
      return sortDirection
        ? a[colName].localeCompare(b[colName])
        : b[colName].localeCompare(a[colName]);
    });
    setSortDirection(!sortDirection);
    setZakazkaF(values);
  };

  return (
    <>
      {props.modal && (
        <div class="modal-window modal-window--w900">
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Vyberte zakázku</div>
              <div class="modal-window__content">
                <div class="table-container">
                  <div class="table">
                    <div class="table__head">
                      <div class="table__row">
                        <div class="table__col table__col--w200">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => sortColumn("ID")}
                          >
                            ID zakázky
                          </span>
                          <Filter
                            filter={filter}
                            setFilter={setFilter}
                            filterVal={filterVal}
                            setFilterVal={setFilterVal}
                            table={zakazka}
                            filterInput={filterInput}
                            setFilterInput={setFilterInput}
                            setFilteredTable={setZakazkaF}
                            colName={"ID"}
                          />
                        </div>
                        <div class="table__col table__col--w200">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => sortColumn("NAZEV_ZAKAZKY")}
                          >
                            Název
                          </span>
                          <Filter
                            filter={filter}
                            setFilter={setFilter}
                            filterVal={filterVal}
                            setFilterVal={setFilterVal}
                            table={zakazka}
                            filterInput={filterInput}
                            setFilterInput={setFilterInput}
                            setFilteredTable={setZakazkaF}
                            colName={"NAZEV_ZAKAZKY"}
                          />
                        </div>
                        <div class="table__col table__col--w200">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => sortColumn("BRAND")}
                          >
                            Brand
                          </span>
                          <Filter
                            filter={filter}
                            setFilter={setFilter}
                            filterVal={filterVal}
                            setFilterVal={setFilterVal}
                            table={zakazka}
                            filterInput={filterInput}
                            setFilterInput={setFilterInput}
                            setFilteredTable={setZakazkaF}
                            colName={"BRAND"}
                          />
                        </div>
                        <div class="table__col table__col--w300">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => sortColumn("CLIENT")}
                          >
                            Klient
                          </span>
                          <Filter
                            filter={filter}
                            setFilter={setFilter}
                            filterVal={filterVal}
                            setFilterVal={setFilterVal}
                            table={zakazka}
                            filterInput={filterInput}
                            setFilterInput={setFilterInput}
                            setFilteredTable={setZakazkaF}
                            colName={"CLIENT"}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="table__body">
                      {zakazkaF.map((zakazka, index) => (
                        <div
                          class="table__row"
                          key={index}
                          onClick={() => {
                            toggleModal();
                            PreFillOrder(index);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div class="table__col table__col--w200">
                            {zakazka.ID}
                          </div>
                          <div class="table__col table__col--w200">
                            {zakazka.NAZEV_ZAKAZKY}
                          </div>
                          <div class="table__col table__col--w200">
                            {zakazka.BRAND}
                          </div>
                          <div class="table__col table__col--w300">
                            {zakazka.CLIENT}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--bordered"
                  onClick={() => props.setModal(false)}
                >
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
