import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import FinancialInsightStandardTable from "./Order-FI_standard_table";
import FinancialInsightFeeOsTable from "./Order-FI_fee_os_table";
import { numberWithSpaces } from "../helpers";
import Loader from "./Loader";
export default function ModalFIFi(props) {
  const [isLoading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState(false);

  useEffect(() => {
    const url_query_string =
      "/financialinsight?kampan_id=" +
      props.druhaZakazka +
      "&mediatyp=" +
      props.druhyMediatyp;

    axios.get(url_query_string).then(
      (res) => {
        props.setFinancialInsightModal(res.data);
        props.setFinancialInsightModalF(res.data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  const SelectMediaplan = (event, index) => {
    const values = [...props.financialInsightModalF];

    values[index]["SELECTED"] = event.target.checked === true ? 1 : 0;

    props.setFinancialInsightModalF(values);
  };

  const Continue = () => {
    if (props.financialInsightModal.filter((o) => o.SELECTED == 1).length > 0) {
      props.setModalFI(false);
      props.setVyber(props.predVyber);
      props.VyplnPolozky();
    }
  };

  const toggleModal = () => {
    props.setDruhaZakazka(null);
    props.setVyber(props.predVyber);
    props.setFinancialInsightModal([]);
    props.setFinancialInsightModalF([]);
    props.setModalFI(false);
  };

  const sortColumn = (colName) => {
    const values = [...props.financialInsightModalF];

    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    props.setFinancialInsightModalF(values);
  };

  return (
    <div class={"modal-window modal-window--flexible"}>
      <div class="modal-window__wrap">
        <div class="modal-window__close" onClick={toggleModal}>
          <div class="icon">
            <svg>
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="/assets/img/sprite.svg#cross"
              />
            </svg>
          </div>
        </div>
        <div class="modal-window__in">
          <div class="modal-window__title">Vyberte položku k napárovaní</div>
          <div class="modal-window__content">
            <div class="table-container">
              {isLoading ? (
                <Loader message="" />
              ) : props.druhyMediatyp == "Other services" ||
                props.druhyMediatyp == "Fee" ||
                props.druhyMediatyp == "Internet" ? (
                <FinancialInsightFeeOsTable
                  financialInsight={props.financialInsightModal}
                  financialInsightF={props.financialInsightModalF}
                  setFinancialInsightF={props.setFinancialInsightModalF}
                  sortColumn={sortColumn}
                  parentFI={"fakturace"}
                  SelectMediaplan={SelectMediaplan}
                  numberWithSpaces={numberWithSpaces}
                  vybranyMediatyp={props.druhyMediatyp}
                />
              ) : (
                <FinancialInsightStandardTable
                  financialInsightF={props.financialInsightModalF}
                  financialInsight={props.financialInsightModal}
                  setFinancialInsightF={props.setFinancialInsightModalF}
                  sortColumn={sortColumn}
                  parentFI={"fakturace"}
                  SelectMediaplan={SelectMediaplan}
                  numberWithSpaces={numberWithSpaces}
                />
              )}
            </div>
          </div>
          <div class="modal-window__actions">
            <a
              className={
                props.financialInsightModal.filter((o) => o.SELECTED == 1)
                  .length > 0
                  ? "btn btn-medium btn--primary"
                  : "btn btn-medium btn--primary btn--disabled"
              }
              onClick={Continue}
            >
              Pokračovat
            </a>
            <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
              Storno
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
