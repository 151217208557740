import React, { useEffect, useState } from "react";
import { formatDate } from "../helpers";
import Loader from "./Loader";
import { numberWithSpaces } from "../helpers";

export default function DepositInvoices(props) {
  if (props.isLoading) {
    // return <div class="loader__in"></div>;
    return <Loader message="" />;
  }

  return (
    <div class="table-container">
      <div class="table">
        <div class="table__head">
          <div class="table__row">
            <div class="table__col table__col--w140">Číslo faktury</div>
            <div class="table__col table__col--w140">Částka</div>
            <div class="table__col table__col--w140">Zůstatek</div>
            <div class="table__col table__col--w160">Čerpáno - částka</div>
            <div class="table__col table__col--w160">Čerpáno - faktura</div>
            <div class="table__col table__col--flexible">Čerpáno - zakázka</div>
          </div>
        </div>
        <div class="table__body">
          {[
            ...new Map(
              props.depositInvoices.map((item) => [item["ID"], item])
            ).values(),
          ] // vracia unique parovaci znak, posledny z duplikatov a vsetky ostatne hodnoty
            .filter((o) => o.SumaKcBezDPH - o.DRAW > 0.1) // zobrazujeme len nevycerpane
            .map((depositInvoices, index) => (
              <div class="table__row" key={depositInvoices.ID}>
                <div class="table__col table__col--w140">
                  {depositInvoices.ParovaciZnak}
                </div>
                <div class="table__col table__col--w140">
                  {numberWithSpaces(depositInvoices.SumaKcBezDPH)}
                </div>
                <div class="table__col table__col--w140">
                  {numberWithSpaces(
                    depositInvoices.SumaKcBezDPH - depositInvoices.DRAW
                  )}
                </div>
                <div class="table__col table__col--w160">
                  <ul class="table-unordered-lists">
                    {props.depositInvoices
                      .filter((o) => o.ID == depositInvoices.ID)
                      .map((drawing) =>
                        drawing.DRAW != null ? (
                          <li>{numberWithSpaces(drawing.DRAW)}</li>
                        ) : (
                          <></>
                        )
                      )}
                  </ul>
                </div>
                <div class="table__col table__col--w160">
                  <ul class="table-unordered-lists">
                    {props.depositInvoices
                      .filter((o) => o.ID == depositInvoices.ID)
                      .map((drawing) =>
                        drawing.ID_FAKTURY != null ? (
                          <li>{drawing.ID_FAKTURY}</li>
                        ) : (
                          <></>
                        )
                      )}
                  </ul>
                </div>
                <div class="table__col table__col--flexible">
                  <ul class="table-unordered-lists">
                    {props.depositInvoices
                      .filter((o) => o.ID == depositInvoices.ID)
                      .map((drawing) =>
                        drawing.ID_KAMPAN != null ? (
                          <li>{drawing.ID_KAMPAN}</li>
                        ) : (
                          <></>
                        )
                      )}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
