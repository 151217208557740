import axios from "axios";
import ModalRunning from "./modal_running";
import React, { useState } from "react";

export default function ModalFIDelete(props) {
  const [isRunning, setIsRunning] = useState(false);

  const toggleModal = () => {
    props.setModalDelete(false);
  };

  const DeletePolozky = () => {
    setIsRunning(true);

    const data = props.financialInsightF.filter((o) => o.SELECTED == 1);
    const url_query_string =
      "financialinsight?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&type=delete";

    axios.post(url_query_string, data).then(
      (res) => {
        console.log(res.data);
        setIsRunning(false);
        props.setModalDelete(false);
        props.setUctarna(0);

        props.setIsError(false);
        props.setInfoBox("Řádky byli odstránění.");
        props.setFinancialInsight(res.data);
        props.setFinancialInsightF(res.data);
      },
      (err) => {
        console.log(err);
        setIsRunning(false);
        props.setModalDelete(false);
        props.setUctarna(0);

        props.setIsError(true);
        props.setInfoBox("Operace se nezdařila.");
      }
    );
  };

  return (
    <>
      {props.modalDelete && (
        <div class="modal-window modal-window--alert">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={""}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__content">
                <div class="alert alert--error">
                  Opravdu chcete smazat vybrané řádky? Akci už nebude možné
                  vrátit zpět!
                </div>
              </div>
              <div class="modal-window__actions">
                <a className="btn btn--medium btn--red" onClick={DeletePolozky}>
                  Ano
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Ne
                </a>
              </div>
            </div>
            <div class="modal-window__actions"></div>
          </div>
        </div>
      )}
    </>
  );
}
