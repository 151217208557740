import React from "react";

export default function ModalConfirmationBox(props) {
  return (
    <>
      {props.modalConfirmation && (
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__in">
              <div class="modal-window__title">
                <div class="alert alert--warning">{props.textConfirmation}</div>
              </div>

              <div class="modal-window__actions">
                <a
                  className="btn btn--medium btn--bordered"
                  onClick={() => props.Confirm(props.confirmParam)}
                >
                  Ano
                </a>

                <a
                  class="btn btn--medium btn--bordered"
                  onClick={() => props.setModalConfirmation(false)}
                >
                  Ne
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
