import React, { useState } from "react";
import axios from "axios";
import { ConsoleLogger } from "@aws-amplify/core";
import fileDownload from "js-file-download";
import ModalRunning from "./modal_running";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  CheckValidPaste,
  IsValidDate,
} from "../helpers";

export default function ModalNovaVerzeMTVInput(props) {
  const [agencyFee, setAgencyFee] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [stopaz, setStopaz] = useState(false);
  const [obdobi, setObdobi] = useState(null);
  const [validace, setValidace] = useState("");

  const handleChangeInput = (event, index) => {
    const values = [...props.inputData];

    var value_to_change;
    value_to_change = event.target.value;
    var colname_to_change;
    colname_to_change = event.target.name;

    values[index][colname_to_change] = value_to_change;

    props.setInputData(values);
  };

  const sortMV = (data) => {
    const values = data;
    values.sort((a, b) => b.ID - a.ID);
    props.setMediaplanVerzia(values);
  };

  const SendInputData = () => {
    for (let i = 0; i < props.inputData.length; i++) {
      props.inputData[i].BRUTTO = parseFloat(
        props.inputData[i].BRUTTO.toString().replace(",", ".")
      );
      props.inputData[i].CLIENT_NET = parseFloat(
        props.inputData[i].CLIENT_NET.toString().replace(",", ".")
      );
      props.inputData[i].OVD_GRPS_PERCENT = parseFloat(
        props.inputData[i].OVD_GRPS_PERCENT.toString().replace(",", ".")
      );
      props.inputData[i].POCET_SPOTU = parseFloat(
        props.inputData[i].POCET_SPOTU.toString().replace(",", ".")
      );
    }

    if (props.loadType === "nemerene") {
      if (!IsValidDate(obdobi + "-01")) {
        setValidace("obdobi");
        return;
      }
    }

    const newInputData = props.inputData.map(
      ({ POCET_SPOTU_NOT_0, ...keepAttrs }) => keepAttrs
    );

    const data = {
      DATA: stopaz
        ? newInputData.filter((o) => o.STOPAZ != "Celkem")
        : newInputData.filter((o) => o.STOPAZ == "Celkem"),
      AGENCYFEE: parseFloat(agencyFee.toString().replace(",", ".")),
      KAMPAN_ID: props.vybranaZakazka,
      DATUM: obdobi + "-01",
    };
    setIsRunning(true);

    console.log(data);
    const url_query_string =
      "/mediaplantvload?type=load&stopaz=" +
      stopaz +
      "&loadtype=" +
      props.loadType;

    axios
      .post(url_query_string, data)
      .then((res) => {
        console.log(res);
        props.mv_id.current = 0;
        props.setLoadingMV(props.mv_id.current);
        props.setSubModal(false);
        props.setModal(false);
        props.setMediaplanVerzia(res.data[0]["MV"]);
        props.setMediaplan(res.data[0]["M"]);

        sortMV(res.data[0]["MV"]);

        if (res.data[0]["STAV"] === 1) {
          props.setProcessType(res.data[0]["STAV"]);
          props.setProcessToInvoke(true);
          props.setProcessFrom("upload");
        }

        if (res.data[0]["STAV"] === 2) {
          props.setProcessType(res.data[0]["STAV"]);
          props.setProcessToInvoke(true);
          props.setProcessFrom("upload");
        }

        const aktualny_mediaplan = Math.max(
          ...res.data[0]["M"].map((o) => o.ID_MEDIAPLAN_VERSION)
        );
        props.setVybranyMediaplan(aktualny_mediaplan);
        //  let asd = [...props.vybranyMediaplan];
        //  props.setVybranyMediaplan(asd);
        setIsRunning(false);
      })
      .catch((err) => {
        props.setLoadingMV(0);
        setIsRunning(false);
        console.log(err);
      });
  };

  const toggleModal = () => {
    axios
      .post("/mediaplantvload?type=delete&mv=" + props.loadingMV)
      .then((res) => {
        console.log(res);
      });

    props.setSubModal(false);
    props.setModal(false);
    props.mv_id.current = 0;
    props.setLoadingMV(props.mv_id.current);
  };

  return (
    <>
      {props.subModal && props.vybranyMediatyp == "TV" && (
        <>
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Probíha import mediaplánu"}
          />
          <div
            class={
              isRunning
                ? "modal-window modal-window--hidden"
                : "modal-window modal-window--w1400"
            }
          >
            <div class="modal-window__wrap">
              <div class="modal-window__close" onClick={toggleModal}>
                <div class="icon">
                  <svg>
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="/assets/img/sprite.svg#cross"
                    />
                  </svg>
                </div>
              </div>
              <div class="modal-window__in">
                <div class="modal-window__title">
                  Doplňujíci údaje k TV mediaplánu
                </div>
                <div class="modal-window__content">
                  <div class="table-container">
                    <div class="table">
                      <div class="table__body">
                        <div class="table__row">
                          {props.loadType === "standard" ? (
                            <>
                              <div class="table__col table__col--w300">
                                Rozpadnout dle stopáže
                              </div>
                              <div class="table__col table__col--w100">
                                <div class="form-checkbox form-checkbox--no-label">
                                  <input
                                    type="checkbox"
                                    defaultChecked={
                                      stopaz
                                      //usersClients.USERS_ID
                                    }
                                    onChange={(event) =>
                                      setStopaz(event.target.checked)
                                    }
                                    name="foo"
                                    id={"asd"}
                                  />
                                  <label for={"asd"}></label>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div class="table__col table__col--w300">
                                Datum
                              </div>
                              <div class="table__col table__col--w300">
                                <input
                                  class={
                                    validace === ""
                                      ? "form-input"
                                      : "form-input form-input--invalid"
                                  }
                                  placeholder="YYYY-MM - Povinné"
                                  value={obdobi}
                                  onChange={(e) => setObdobi(e.target.value)}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-container">
                    <div class="table">
                      <div class="table__body">
                        <div class="table__row">
                          <div class="table__col table__col--w300">
                            Agency Fee (%)
                          </div>
                          <div class="table__col table__col--w300">
                            <input
                              class="form-input"
                              name="AgencyFee"
                              onKeyDown={(e) => checkValidInput(e)}
                              onPaste={(e) => CheckValidPaste(e)}
                              value={replaceDot(agencyFee)}
                              onChange={(e) => setAgencyFee(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-container">
                    <div class="table">
                      <div class="table__head">
                        <div class="table__row">
                          <div class="table__col table__col--w300">
                            Vydavatel
                          </div>
                          <div class="table__col table__col--w280">Gross</div>
                          <div class="table__col table__col--w280">
                            Client Net
                          </div>
                          <div class="table__col table__col--w280">
                            OVD GRPs (%)
                          </div>
                          <div class="table__col table__col--w280">
                            Počet spotů
                          </div>
                        </div>
                      </div>
                      {stopaz
                        ? [
                            ...new Set( //toto by malo spravit array s unique skupoinami
                              props.inputData.map(
                                ({ OBCHODNI_SKUPINY = [] }) => OBCHODNI_SKUPINY
                              )
                            ),
                          ].map(
                            (
                              skupina,
                              index2 // rozpadnute podla stopaze
                            ) => (
                              <div class="table__body">
                                <br></br>
                                <div class="table__row" key={index2}>
                                  <div class="table__col table__col--w300">
                                    {skupina}
                                  </div>
                                  <div class="table__col table__col--w280  ">
                                    <input
                                      class="form-input"
                                      name="BRUTTO_s"
                                      value={numberWithSpaces(
                                        props.inputData
                                          .filter(
                                            (o) =>
                                              (o.STOPAZ != "Celkem") &
                                              (o.OBCHODNI_SKUPINY == skupina)
                                          )
                                          .reduce(
                                            (n, { BRUTTO }) =>
                                              n +
                                              parseFloat(
                                                BRUTTO.toString().replace(
                                                  ",",
                                                  "."
                                                )
                                              ),
                                            0
                                          )
                                      )}
                                      readOnly
                                    />
                                  </div>
                                  <div class="table__col table__col--w280 ">
                                    <input
                                      class="form-input"
                                      name="CLIENT_NET_s"
                                      value={numberWithSpaces(
                                        props.inputData
                                          .filter(
                                            (o) =>
                                              (o.STOPAZ != "Celkem") &
                                              (o.OBCHODNI_SKUPINY == skupina)
                                          )
                                          .reduce(
                                            (n, { CLIENT_NET }) =>
                                              n +
                                              parseFloat(
                                                CLIENT_NET.toString().replace(
                                                  ",",
                                                  "."
                                                )
                                              ),
                                            0
                                          )
                                      )}
                                      readOnly
                                    />
                                  </div>
                                  <div class="table__col table__col--w280 ">
                                    <input
                                      class="form-input"
                                      name="OVD_GRPS_PERCENT_s"
                                      value={numberWithSpaces(
                                        props.inputData
                                          .filter(
                                            (o) =>
                                              (o.STOPAZ != "Celkem") &
                                              (o.OBCHODNI_SKUPINY == skupina)
                                          )
                                          .reduce(
                                            (n, { OVD_GRPS_PERCENT }) =>
                                              n +
                                              parseFloat(
                                                OVD_GRPS_PERCENT.toString().replace(
                                                  ",",
                                                  "."
                                                )
                                              ),
                                            0
                                          )
                                      )}
                                      readOnly
                                    />
                                  </div>
                                  <div class="table__col table__col--w280 ">
                                    <input
                                      class="form-input"
                                      name="POCET_SPOTU_s"
                                      value={numberWithSpaces(
                                        props.inputData
                                          .filter(
                                            (o) =>
                                              (o.STOPAZ != "Celkem") &
                                              (o.OBCHODNI_SKUPINY == skupina)
                                          )
                                          .reduce(
                                            (n, { POCET_SPOTU }) =>
                                              n +
                                              parseFloat(
                                                POCET_SPOTU.toString().replace(
                                                  ",",
                                                  "."
                                                )
                                              ),
                                            0
                                          )
                                      )}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                {props.inputData
                                  .filter(
                                    (o) =>
                                      (o.STOPAZ != "Celkem") &
                                      (o.OBCHODNI_SKUPINY == skupina)
                                  )
                                  .map((inputData, index) => (
                                    <div class="table__row" key={index}>
                                      <div class="table__col table__col--w300">
                                        {"Stopáž - " + inputData.STOPAZ}
                                      </div>
                                      <div class="table__col table__col--w280">
                                        <input
                                          class="form-input"
                                          name="BRUTTO"
                                          onKeyDown={(e) => checkValidInput(e)}
                                          onPaste={(e) => CheckValidPaste(e)}
                                          defaultValue={0}
                                          value={replaceDot(inputData.BRUTTO)}
                                          onChange={(e) =>
                                            handleChangeInput(
                                              e,
                                              inputData.index
                                            )
                                          }
                                        />
                                      </div>
                                      <div class="table__col table__col--w280">
                                        <input
                                          class="form-input"
                                          name="CLIENT_NET"
                                          onKeyDown={(e) => checkValidInput(e)}
                                          onPaste={(e) => CheckValidPaste(e)}
                                          value={replaceDot(
                                            inputData.CLIENT_NET
                                          )}
                                          onChange={(e) =>
                                            handleChangeInput(
                                              e,
                                              inputData.index
                                            )
                                          }
                                        />
                                      </div>
                                      <div class="table__col table__col--w280">
                                        <input
                                          class="form-input"
                                          name="OVD_GRPS_PERCENT"
                                          onKeyDown={(e) => checkValidInput(e)}
                                          onPaste={(e) => CheckValidPaste(e)}
                                          value={replaceDot(
                                            inputData.OVD_GRPS_PERCENT
                                          )}
                                          onChange={(e) =>
                                            handleChangeInput(
                                              e,
                                              inputData.index
                                            )
                                          }
                                        />
                                      </div>
                                      <div class="table__col table__col--w280">
                                        {inputData.POCET_SPOTU_NOT_0 == 1 ? (
                                          <input
                                            class="form-input"
                                            name="POCET_SPOTU"
                                            onKeyDown={(e) =>
                                              checkValidInput(e)
                                            }
                                            onPaste={(e) => CheckValidPaste(e)}
                                            value={replaceDot(
                                              inputData.POCET_SPOTU
                                            )}
                                          />
                                        ) : (
                                          <input
                                            class="form-input"
                                            name="POCET_SPOTU"
                                            onKeyDown={(e) =>
                                              checkValidInput(e)
                                            }
                                            onPaste={(e) => CheckValidPaste(e)}
                                            value={replaceDot(
                                              inputData.POCET_SPOTU
                                            )}
                                            onChange={(e) =>
                                              handleChangeInput(
                                                e,
                                                inputData.index
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )
                          )
                        : props.inputData //standardne okno
                            .filter((o) => o.STOPAZ == "Celkem")
                            .map((inputData, index) => (
                              <div class="table__body">
                                <div class="table__row" key={index}>
                                  <div class="table__col table__col--w300">
                                    {inputData.OBCHODNI_SKUPINY}
                                  </div>
                                  <div class="table__col table__col--w280">
                                    <input
                                      class="form-input"
                                      name="BRUTTO"
                                      onKeyDown={(e) => checkValidInput(e)}
                                      onPaste={(e) => CheckValidPaste(e)}
                                      value={replaceDot(inputData.BRUTTO)}
                                      onChange={(e) =>
                                        handleChangeInput(e, inputData.index)
                                      }
                                    />
                                  </div>
                                  <div class="table__col table__col--w280">
                                    <input
                                      class="form-input"
                                      name="CLIENT_NET"
                                      onKeyDown={(e) => checkValidInput(e)}
                                      onPaste={(e) => CheckValidPaste(e)}
                                      value={replaceDot(inputData.CLIENT_NET)}
                                      onChange={(e) =>
                                        handleChangeInput(e, inputData.index)
                                      }
                                    />
                                  </div>
                                  <div class="table__col table__col--w280">
                                    <input
                                      class="form-input"
                                      name="OVD_GRPS_PERCENT"
                                      onKeyDown={(e) => checkValidInput(e)}
                                      onPaste={(e) => CheckValidPaste(e)}
                                      value={replaceDot(
                                        inputData.OVD_GRPS_PERCENT
                                      )}
                                      onChange={(e) =>
                                        handleChangeInput(e, inputData.index)
                                      }
                                    />
                                  </div>
                                  <div class="table__col table__col--w280">
                                    {inputData.POCET_SPOTU_NOT_0 == 1 ? (
                                      <input
                                        class="form-input"
                                        name="POCET_SPOTU"
                                        onKeyDown={(e) => checkValidInput(e)}
                                        onPaste={(e) => CheckValidPaste(e)}
                                        value={replaceDot(
                                          inputData.POCET_SPOTU
                                        )}
                                      />
                                    ) : (
                                      <input
                                        class="form-input"
                                        name="POCET_SPOTU"
                                        onKeyDown={(e) => checkValidInput(e)}
                                        onPaste={(e) => CheckValidPaste(e)}
                                        value={replaceDot(
                                          inputData.POCET_SPOTU
                                        )}
                                        onChange={(e) =>
                                          handleChangeInput(e, inputData.index)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                    </div>
                  </div>
                </div>

                <div class="modal-window__actions">
                  <a
                    className="btn btn-primary btn--primary"
                    onClick={SendInputData}
                  >
                    Nahrát
                  </a>
                  <br></br>
                  <a
                    className="btn btn--medium btn--bordered"
                    onClick={toggleModal}
                  >
                    Storno
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
