import React, { useState } from "react";
import Loader from "./Loader";

export default function ModalRunning(props) {
  return (
    <>
      {props.isRunning && (
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__in">
              <Loader message={props.message} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
