import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

export default function ModalClientCreate(props) {
  const [brand, setBrand] = useState("");
  const [klientID, setKlientID] = useState("");
  const [brandID, setBrandID] = useState("");
  const [validace, setValidace] = useState(0);

  const CreateClient = () => {
    var kod = klientID + brandID;
    var validace_var;

    validace_var =
      klientID.length < 3
        ? 1
        : brandID.length < 2
        ? 2
        : props.clients.find((a) => kod.includes(a.KOD_ZAKAZKA)) !== undefined
        ? 3
        : 0;

    setValidace(validace_var);

    if (validace_var === 0) {
      const data = {
        ICO: props.pickedClient[0]["ICO"],
        DIC: props.pickedClient[0]["DIC"],
        CLIENT: props.pickedClient[0]["Nazev"],
        //Moze byt vsetko v Ulice alebo Ulice/Misto alebo v Kazdom poli
        ULICE:
          props.pickedClient[0]["PopCislo"] !== ""
            ? props.pickedClient[0]["Ulice"] +
              " " +
              props.pickedClient[0]["PopCislo"] +
              "/" +
              props.pickedClient[0]["OrCislo"] +
              ", " +
              props.pickedClient[0]["PSC"] +
              " " +
              props.pickedClient[0]["Misto"]
            : props.pickedClient[0]["Misto"] == ""
            ? props.pickedClient[0]["Ulice"]
            : props.pickedClient[0]["Ulice"] +
              ", " +
              props.pickedClient[0]["Misto"],
        CITY:
          props.pickedClient[0]["PSC"] + " " + props.pickedClient[0]["Misto"],
        BRAND: brand,
        CLIENT_ID: klientID,
        BRAND_ID: brandID,
        KOD_ZAKAZKA: kod,
        CISLOORG: props.pickedClient[0]["CisloOrg"],
      };
      // console.log(data);

      const url_query_string = "/clients?type=create";

      axios
        .post(url_query_string, data)
        .then((res) => {
          console.log(res);
          props.setClients(res.data);
          props.setClientsF(res.data);
          props.setModalClientCreate(!props.modalClientCreate);
          props.setModalClients(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //var b = props.clients.find((a) => a.KOD_ZAKAZKA.indexof(kod));
  };

  const toggleModal = () => {
    props.setModalClientCreate(!props.modalClientCreate);
  };

  return (
    <>
      {props.modalClientCreate && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Vytvoření nového klienta</div>
              <div class="modal-window__content">
                <div class="form">
                  <form class="form__in">
                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Název
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={props.pickedClient[0]["Nazev"]}
                          // onChange={(e) => UpdateInvoice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Sídlo
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={props.pickedClient[0]["Ulice"]}
                          //  onChange={(e) => UpdateInvoice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        IČO
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={props.pickedClient[0]["ICO"]}
                          // onChange={(e) => UpdateInvoice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        DIČ
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={props.pickedClient[0]["DIC"]}
                          //   onChange={(e) => UpdateInvoice(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Brand
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Klient ID
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={klientID}
                          onChange={(e) => setKlientID(e.target.value)}
                        />
                        {validace === 1 ? (
                          <div class="validation">
                            <div class="validation__item">
                              Klient ID musí obsahovat přesně 3 znaky!
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Brand ID
                      </label>

                      <div class="form__value">
                        <input
                          class="form-input"
                          value={brandID}
                          onChange={(e) => setBrandID(e.target.value)}
                        />
                        {validace === 2 ? (
                          <div class="validation">
                            <div class="validation__item">
                              Brand ID musí obsahovat přesně 2 znaky!
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div class="form__item">
                      <label for="id" class="form-label form-label--inline">
                        Kód zakázky
                      </label>

                      <div class="form__value">
                        <input class="form-input" value={klientID + brandID} />
                        {validace === 3 ? (
                          <div class="validation">
                            <div class="validation__item">
                              Klient s tímto kódem již existuje!
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <br></br>
              <div class="modal-window__actions">
                <a class="btn btn--medium btn--primary" onClick={CreateClient}>
                  Uložit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
