import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import MediaplanModalExport from "./Order-Mediaplan-Modal_export";
import MediaplanModalImport from "./Order-Mediaplan-Modal_import";
import MediaplanModalApproval from "./Order-Mediaplan-Modal_approval";
import OrderModalProcessContinuation from "./Order-Modal_process_continuation";
import Loader from "./Loader";
import ModalInfo from "./modal_info";
import { formatDate, numberWithSpaces, cutFile } from "../helpers";
import ModalConfirmationBox from "./modal_confirmation_box";
import ModalRunningWhite from "./modal_running_white";
export default function Mediaplan(props) {
  const [mediaplanVerzia, setMediaplanVerzia] = useState([]);
  const [mediaplan, setMediaplan] = useState([]);
  const [mediaplanTVstandard, setMediaplanTVstandard] = useState(true);
  const [mediaplanSP, setMediaplanSP] = useState([]);

  const [vybranyMediaplan, setVybranyMediaplan] = useState(0);
  const [vybranyMediaplanFE, setVybranyMediaplanFE] = useState(0);
  const [vybranyMediaplanFN, setVybranyMediaplanFN] = useState(0);

  const [modal, setModal] = useState(false);
  const [modalex, setModalex] = useState(false);
  const [modalExType, setModalExType] = useState("");
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [substitution, setSubstitution] = useState(false);

  const [modalapp, setModalapp] = useState(false);

  const [mediaplanToApprove, setMediaplanToApprove] = useState(0);
  const [mediaplanToApproveValue, setMediaplanToApproveValue] = useState(0);
  const [mediaplanToApproveVersion, setMediaplanToApproveVersion] =
    useState("");

  const [isLoading, setLoading] = useState(true);

  const [processType, setProcessType] = useState(3);
  const [processToInvoke, setProcessToInvoke] = useState(false);
  const [processFrom, setProcessFrom] = useState("");

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const mv_id = useRef(0);
  const [loadingMV, setLoadingMV] = useState(mv_id.current);
  const params = useParams();

  useEffect(() => {
    const url_query_string =
      "/mediaplanversion?kampan_id=" +
      params.zakazka +
      "&mediatyp=" +
      params.mediatyp;

    axios.get(url_query_string).then(
      (res) => {
        SetUpStates(res);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );

    //ked nenahral TV mediaplan cely tak mazeme

    window.addEventListener("unload", handleTabClose);

    return () => {
      if (mv_id.current !== 0) {
        axios
          .post("/mediaplantvload?type=delete&mv=" + mv_id.current)
          .then((res) => {
            console.log(res);
          });
        window.removeEventListener("unload", handleTabClose);

        setLoadingMV(0);
      }
    };
  }, []);

  const handleTabClose = () => {
    //pouzivame fetch lebo request nedobehne ked zatvorime stranku
    if (mv_id.current !== 0) {
      fetch(
        //"https://jqrigg3fbk.execute-api.eu-central-1.amazonaws.com/dev/mediaplantvload?type=delete&mv=" +
        "https://wc81duo900.execute-api.eu-central-1.amazonaws.com/test/mediaplantvload?type=delete&mv=" +
          mv_id.current,
        {
          method: "POST",
          keepalive: true,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ).then((res) => {
        window.removeEventListener("unload", handleTabClose);
      });
      setLoadingMV(0);
    }
  };

  const sortMV = (data) => {
    const values = data;
    values.sort((a, b) => b.ID - a.ID);
    setMediaplanVerzia(values);
    console.log(values);
  };

  const handleCheck = (event, index, verze) => {
    setMediaplanToApprove(index);
    setMediaplanToApproveValue(event.target.checked);
    setMediaplanToApproveVersion(verze);
    setModalapp(!modalapp);
  };

  const GetMediaplanFeAndFileName = () => {
    const values = [...mediaplanVerzia];
    const mv = values.find((x) => x.ID === vybranyMediaplan);

    setVybranyMediaplanFE(mv["ID_FE"]);
    setVybranyMediaplanFN(cutFile(mv["S3_KEY"]));
  };

  const RemoveMediaplan = () => {
    const url_query_string =
      "/mediaplanversion?kampan_id=" +
      params.zakazka +
      "&mediatyp=" +
      params.mediatyp +
      "&id_mv=" +
      vybranyMediaplan;

    setIsRunning(true);
    setInfoMessage("Odstraňuji mediaplán");

    axios.post(url_query_string).then(
      (res) => {
        SetUpStates(res);
        setModalConfirmation(false);
        setIsRunning(false);
      },
      (err) => {
        console.log(err);
        setIsRunning(false);
        setIsError(true);
        setInfoBox("Došlo k chybě!");
      }
    );
  };

  const SetUpStates = (res) => {
    setMediaplanVerzia(res.data[0]["MV"]);
    setMediaplan(res.data[0]["M"]);

    if (params.mediatyp == "TV") {
      setMediaplanSP(res.data[0]["M_SP"]);
    }
    const aktualny_mediaplan = Math.max(...res.data[0]["MV"].map((o) => o.ID));
    const aktualny_mediaplan_fe = Math.max(
      ...res.data[0]["MV"].map((o) => o.ID_FE)
    );
    setVybranyMediaplan(aktualny_mediaplan);
    setVybranyMediaplanFE(aktualny_mediaplan_fe);

    sortMV(res.data[0]["MV"]);
  };

  if (isLoading) {
    return <Loader message="" />;
  }

  return (
    <>
      <MediaplanModalImport
        vybranaZakazka={params.zakazka}
        vybranyMediatyp={params.mediatyp}
        setMediaplanVerzia={setMediaplanVerzia}
        setMediaplan={setMediaplan}
        setMediaplanSP={setMediaplanSP}
        modal={modal}
        setModal={setModal}
        sortMV={sortMV}
        setVybranyMediaplan={setVybranyMediaplan}
        vybranyMediaplan={vybranyMediaplan}
        setProcessType={setProcessType}
        setProcessToInvoke={setProcessToInvoke}
        setProcessFrom={setProcessFrom}
        loadingMV={loadingMV}
        setLoadingMV={setLoadingMV}
        mv_id={mv_id}
        setIsError={setIsError}
        setInfoBox={setInfoBox}
        substitution={substitution}
      />
      <MediaplanModalExport
        vybranyMediatyp={params.mediatyp}
        vybranyMediaplanFE={vybranyMediaplanFE}
        vybranyMediaplanFN={vybranyMediaplanFN}
        vybranyMediaplan={vybranyMediaplan}
        modalex={modalex}
        setModalex={setModalex}
        modalExType={modalExType}
      />
      <MediaplanModalApproval
        mediaplanToApprove={mediaplanToApprove}
        mediaplanToApproveValue={mediaplanToApproveValue}
        mediaplanToApproveVersion={mediaplanToApproveVersion}
        modalapp={modalapp}
        setModalapp={setModalapp}
        mediaplanVerzia={mediaplanVerzia}
        setMediaplanVerzia={setMediaplanVerzia}
        vybranaZakazka={params.zakazka}
        vybranyMediatyp={params.mediatyp}
        setProcessFrom={setProcessFrom}
        setProcessToInvoke={setProcessToInvoke}
        setIsError={setIsError}
        setInfoBox={setInfoBox}
      />
      <OrderModalProcessContinuation
        processType={processType}
        processToInvoke={processToInvoke}
        processFrom={processFrom}
        setProcessToInvoke={setProcessToInvoke}
        vybranaZakazka={params.zakazka}
      />
      <ModalConfirmationBox
        setModalConfirmation={setModalConfirmation}
        modalConfirmation={modalConfirmation}
        Confirm={RemoveMediaplan}
        textConfirmation={
          "Opravdu chcete odstranit verzi mediaplánu " +
          vybranyMediaplanFE +
          ".0?"
        }
      />
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__side content__side-w280">
              <div class="side-panel">
                <div class="panel-info">
                  <div class="panel-info-table">
                    <div class="panel-info-table__head">
                      <div class="panel-info-table__row">
                        <div class="panel-info-table__col panel-info-table__col--40">
                          Verze
                        </div>

                        <div class="panel-info-table__col panel-info-table__col--flexbile">
                          Název
                        </div>

                        <div class="panel-info-table__col panel-info-table__col--w40">
                          Schváleno
                        </div>
                      </div>
                    </div>

                    <div class="panel-info-table__body">
                      {mediaplanVerzia.map((mVerzia, index) => (
                        <div
                          class={
                            mVerzia.ID === vybranyMediaplan
                              ? "panel-info-table__row--active"
                              : "panel-info-table__row"
                          }
                          key={mVerzia.ID_FE}
                        >
                          <div
                            class="panel-info-table__col panel-info-table__col--w30"
                            onClick={() => {
                              setVybranyMediaplan(mVerzia.ID);
                              setVybranyMediaplanFE(mVerzia.ID_FE);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span>
                              {mediaplanSP.some(
                                (o) => o.ID_MEDIAPLAN_VERSION === mVerzia.ID
                              )
                                ? mVerzia.ID_FE + ".0s"
                                : mVerzia.ID_FE + ".0"}{" "}
                            </span>
                          </div>

                          <div class="panel-info-table__col panel-info-table__col--flexbile">
                            <span>{cutFile(mVerzia.S3_KEY)} </span>
                          </div>

                          <div class="panel-info-table__col panel-info-table__col--w30">
                            {props.user > 4 ? (
                              mVerzia.ID_MEDIAPLAN_VERSION !== null ? (
                                <span>
                                  {mVerzia.ID_MEDIAPLAN_VERSION + ".0"}
                                </span>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={mVerzia.CONFIRMED}
                                  // onChange={(event) => handleCheck(event, index)}
                                  // onChange="return false;"
                                ></input>
                              )
                            ) : mVerzia.ID_MEDIAPLAN_VERSION !== null ? (
                              <span>{mVerzia.ID_MEDIAPLAN_VERSION + ".0"}</span>
                            ) : (
                              <input
                                type="checkbox"
                                checked={mVerzia.CONFIRMED == 1 ? true : false}
                                onChange={(event) =>
                                  handleCheck(
                                    event,
                                    index,
                                    mVerzia.ID_FE + ".0"
                                  )
                                }
                                // onChange="return false;"
                              ></input>
                            )}
                            <label for="panelInfo1"></label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="content__main">
              <div class="table-container">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      {params.mediatyp !== "TV" &&
                      params.mediatyp !== "Print" &&
                      params.mediatyp !== "Radio" &&
                      params.mediatyp !== "OOH" &&
                      params.mediatyp !== "Cinema" ? (
                        <>
                          <div class="table__col table__col--w200">
                            <label>Dodavatel</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Název média</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Formát</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Datum uveřejnění</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Gross</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Client Net</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Media Net</label>
                          </div>
                          <div class="table__col table__col--flexible">
                            <label>Client Net Net Net</label>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {params.mediatyp == "TV" ? (
                        mediaplan.some(
                          (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                        ) ? (
                          <>
                            <div class="table__col table__col--w200">
                              <label>Dodavatel</label>
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Gross</label>
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Client Net </label>
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Media Net</label>
                            </div>
                            <div class="table__col table__col--w160">
                              <label>Cost to Client </label>
                            </div>
                            <div class="table__col table__col--w160">
                              <label>GRP</label>
                            </div>
                            <div class="table__col table__col--w160">
                              <label>TRP</label>
                            </div>
                            <div class="table__col table__col--w160">
                              <label>Affinity</label>
                            </div>
                            <div class="table__col table__col--flexible">
                              <label>Počet spotů</label>
                            </div>
                          </>
                        ) : (
                          <>
                            <div class="table__col table__col--w200">
                              <label>Vydavatel</label>
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Gross</label>
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Media Net Net</label>
                            </div>
                            <div class="table__col table__col--w160">
                              <label>GRP</label>
                            </div>
                            <div class="table__col table__col--w160">
                              <label>TRP</label>
                            </div>
                            <div class="table__col table__col--flexible">
                              <label>Affinity</label>
                            </div>
                          </>
                        )
                      ) : (
                        <></>
                      )}
                      {params.mediatyp == "Print" ||
                      params.mediatyp == "Radio" ? (
                        <>
                          <div class="table__col table__col--w200">
                            <label>Dodavatel</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Název média</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Formát</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Datum uveřejnění</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient Gross</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient Příplatky</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient Slevy</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient NetNet</label>
                          </div>
                          {props.user >= 4 || props.user == 1 ? (
                            <>
                              <div class="table__col table__col--w160">
                                <label>Agentura Gross</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>Agentura Příplatky</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>Agentura Slevy</label>
                              </div>
                              <div class="table__col table__col--flexible">
                                <label>Agentura NetNet</label>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {params.mediatyp == "OOH" ||
                      params.mediatyp == "Cinema" ? (
                        <>
                          <div class="table__col table__col--w200">
                            <label>Dodavatel</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Název média</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Formát</label>
                          </div>
                          <div class="table__col table__col--w200">
                            <label>Datum uveřejnění</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient Gross</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient Slevy</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient Net</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient AP</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient NetNet</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Klient Fee</label>
                          </div>
                          <div class="table__col table__col--w160">
                            <label>Cost To Client</label>
                          </div>
                          {props.user >= 4 || props.user == 1 ? (
                            <>
                              <div class="table__col table__col--w160">
                                <label>Agentura Gross</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>Agentura Slevy</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>Agentura Net</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>Agentura AP</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>Agentura NetNet</label>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div class="table__body">
                    {params.mediatyp !== "TV" &&
                    params.mediatyp !== "Print" &&
                    params.mediatyp !== "Radio" &&
                    params.mediatyp !== "OOH" &&
                    params.mediatyp !== "Cinema" ? (
                      mediaplan
                        .filter(
                          (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                        )
                        .map((mediaplan, index) => (
                          <div
                            class={"table__row"}
                            key={mediaplan.ID_MEDIAPLAN_VERSION + index}
                          >
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.VYDAVATEL}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.NAZEV_MEDIA}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.FORMAT}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.DATUM_UVEREJNENI}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.GROSS)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.NET)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.MEDIA_NET)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--flexible"
                                  : "table__col table__col--flexible table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.CNNN)}
                            </div>
                          </div>
                        ))
                    ) : (
                      <></>
                    )}
                    {params.mediatyp == "TV" ? (
                      mediaplan.some(
                        (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                      ) ? (
                        mediaplan
                          .filter(
                            (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                          )
                          .map((mediaplan, index) => (
                            <div
                              class={"table__row"}
                              key={mediaplan.ID_MEDIAPLAN_VERSION + index}
                            >
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w200"
                                    : "table__col table__col--w200 table__col--hidden"
                                }
                              >
                                {mediaplan.OBCHODNI_SKUPINY}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w200"
                                    : "table__col table__col--w200 table__col--hidden"
                                }
                              >
                                {numberWithSpaces(mediaplan.GROSS)}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w200"
                                    : "table__col table__col--w200 table__col--hidden"
                                }
                              >
                                {numberWithSpaces(mediaplan.CLIENT_NET)}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w200"
                                    : "table__col table__col--w200 table__col--hidden"
                                }
                              >
                                {numberWithSpaces(mediaplan.MEDIA_NET)}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w160"
                                    : "table__col table__col--w160 table__col--highlight-green"
                                }
                              >
                                {numberWithSpaces(mediaplan.COST_TO_CLIENT)}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w160"
                                    : "table__col table__col--w160 table__col--highlight-green"
                                }
                              >
                                {numberWithSpaces(mediaplan.CELKEM_GRP)}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w160"
                                    : "table__col table__col--w160 table__col--highlight-green"
                                }
                              >
                                {numberWithSpaces(mediaplan.CELKEM_TRP)}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--w160"
                                    : "table__col table__col--w160 table__col--highlight-green"
                                }
                              >
                                {numberWithSpaces(mediaplan.AFFINITA)}
                              </div>
                              <div
                                class={
                                  mediaplan.OBCHODNI_SKUPINY !== null
                                    ? "table__col table__col--flexible"
                                    : "table__col table__col--flexible table__col--highlight-green"
                                }
                              >
                                {numberWithSpaces(mediaplan.POCET_SPOTU)}
                              </div>
                            </div>
                          ))
                      ) : (
                        mediaplanSP
                          .filter(
                            (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                          )
                          .map((mediaplanSP, index) => (
                            <div
                              class={"table__row"}
                              key={mediaplanSP.ID_MEDIAPLAN_VERSION + index}
                            >
                              <div
                                class={
                                  mediaplanSP.VYDAVATEL !== null
                                    ? "table__col table__col--w200"
                                    : "table__col table__col--w200 table__col--hidden"
                                }
                              >
                                {mediaplanSP.VYDAVATEL}
                              </div>
                              <div
                                class={
                                  mediaplanSP.VYDAVATEL !== null
                                    ? "table__col table__col--w200"
                                    : "table__col table__col--w200 table__col--hidden"
                                }
                              >
                                {numberWithSpaces(
                                  mediaplanSP.MEDIA_GROSS_CELKEM
                                )}
                              </div>
                              <div
                                class={
                                  mediaplanSP.VYDAVATEL !== null
                                    ? "table__col table__col--w200"
                                    : "table__col table__col--w200 table__col--hidden"
                                }
                              >
                                {numberWithSpaces(mediaplanSP.MEDIA_NET_NET)}
                              </div>
                              <div
                                class={
                                  mediaplanSP.VYDAVATEL !== null
                                    ? "table__col table__col--w160"
                                    : "table__col table__col--w160 table__col--hidden"
                                }
                              >
                                {numberWithSpaces(
                                  mediaplanSP.ODHAD_GRPS_CELKEM
                                )}
                              </div>
                              <div
                                class={
                                  mediaplanSP.VYDAVATEL !== null
                                    ? "table__col table__col--w160"
                                    : "table__col table__col--w160 table__col--hidden"
                                }
                              >
                                {numberWithSpaces(
                                  mediaplanSP.ODHAD_TRPS_CELKEM
                                )}
                              </div>
                              <div
                                class={
                                  mediaplanSP.VYDAVATEL !== null
                                    ? "table__col table__col--flexible"
                                    : "table__col table__col--flexible table__col--hidden"
                                }
                              >
                                {numberWithSpaces(mediaplanSP.AFINITA)}
                              </div>
                            </div>
                          ))
                      )
                    ) : (
                      <> </>
                    )}
                    {params.mediatyp == "Print" ||
                    params.mediatyp == "Radio" ? (
                      mediaplan
                        .filter(
                          (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                        )
                        .map((mediaplan, index) => (
                          <div
                            class={"table__row"}
                            key={mediaplan.ID_MEDIAPLAN_VERSION + index}
                          >
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.VYDAVATEL}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.NAZEV_MEDIA}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.FORMAT}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.DATUM_UVEREJNENI}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.GROSS)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.PRIPLATEK_CZK)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.SLEVY)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.NET_NET)}
                            </div>
                            {props.user >= 4 || props.user == 1 ? (
                              <>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.GROSS_D)}
                                </div>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.PRIPLATEK_D_czk)}
                                </div>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.SLEVY_D)}
                                </div>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--flexible"
                                      : "table__col table__col--flexible table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.NET_NET_D)}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))
                    ) : (
                      <></>
                    )}
                    {params.mediatyp == "OOH" || params.mediatyp == "Cinema" ? (
                      mediaplan
                        .filter(
                          (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                        )
                        .map((mediaplan, index) => (
                          <div
                            class={"table__row"}
                            key={mediaplan.ID_MEDIAPLAN_VERSION + index}
                          >
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.VYDAVATEL}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.NAZEV_MEDIA}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.FORMAT}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w200"
                                  : "table__col table__col--w200 table__col--hidden"
                              }
                            >
                              {mediaplan.DATUM_UVEREJNENI}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.GROSS)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.SLEVY)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.NET)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.AGENTURNI_PROVIZE)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.NET_NET)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.FEE)}
                            </div>
                            <div
                              class={
                                mediaplan.VYDAVATEL !== null
                                  ? "table__col table__col--w160"
                                  : "table__col table__col--w160 table__col--highlight-green"
                              }
                            >
                              {numberWithSpaces(mediaplan.CNNN)}
                            </div>

                            {props.user >= 4 || props.user == 1 ? (
                              <>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.GROSS_D)}
                                </div>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.SLEVY_D)}
                                </div>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.NET_D)}
                                </div>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(
                                    mediaplan.AGENTURNI_PROVIZE_D
                                  )}
                                </div>
                                <div
                                  class={
                                    mediaplan.VYDAVATEL !== null
                                      ? "table__col table__col--w160"
                                      : "table__col table__col--w160 table__col--highlight-green"
                                  }
                                >
                                  {numberWithSpaces(mediaplan.NET_NET_D)}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div class="action-bar">
                  {props.user < 6 ? (
                    <div class="action-bar__in">
                      <a
                        class="btn btn--medium btn--primary"
                        onClick={() => {
                          setModal(!modal);
                          setSubstitution(false);
                        }}
                      >
                        Import mediaplánu
                      </a>
                      {mediaplanVerzia
                        .filter((o) => o.ID == vybranyMediaplan)
                        .map((mv) =>
                          mv.CONFIRMED == 1 &&
                          (params.mediatyp == "Print" ||
                            params.mediatyp == "Radio" ||
                            params.mediatyp == "OOH") ? (
                            <a
                              class="btn btn--medium btn--bordered"
                              onClick={() => {
                                setModal(!modal);
                                GetMediaplanFeAndFileName();
                                setSubstitution(true);
                              }}
                            >
                              Nahradit mediaplán
                            </a>
                          ) : (
                            <></>
                          )
                        )}

                      <a
                        class="btn btn--medium btn--transparent"
                        onClick={() => {
                          setModalExType("odb");
                          setModalex(!modalex);
                          GetMediaplanFeAndFileName();
                        }}
                      >
                        Klientský export
                      </a>
                      {params.mediatyp !== "TV" ? (
                        <a
                          class="btn btn--medium btn--transparent"
                          onClick={() => {
                            setModalExType("dod");
                            setModalex(!modalex);
                            GetMediaplanFeAndFileName();
                          }}
                        >
                          Dodavatelský export
                        </a>
                      ) : (
                        <></>
                      )}
                      {mediaplanVerzia
                        .filter((o) => o.ID == vybranyMediaplan)
                        .map((mv) =>
                          mv.CONFIRMED == 0 || mv.CONFIRMED == null ? (
                            <a
                              class="btn btn--medium btn--red"
                              onClick={() => {
                                setModalConfirmation(true);
                              }}
                            >
                              Odstránit mediaplán
                            </a>
                          ) : (
                            <></>
                          )
                        )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
