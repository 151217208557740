import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import FormUpEvFeeOs from "./Order-Form_up_ev_fee_os";

export default function ModalFiUpdateFeeOS(props) {
  const [validace, setValidace] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const InvoiceToUpdate = () => {
    for (let i = 0; i < props.invoiceToUpdate.length; i++) {
      props.invoiceToUpdate[i].CNNN = parseFloat(
        props.invoiceToUpdate[i].CNNN.toString().replace(",", ".")
      );
    }

    if (props.vybranyMediatyp === "Internet") {
      for (let i = 0; i < props.invoiceToUpdate.length; i++) {
        props.invoiceToUpdate[i].MNNP = parseFloat(
          props.invoiceToUpdate[i].MNNP.toString().replace(",", ".")
        );
      }

      for (let i = 0; i < props.invoiceToUpdate.length; i++) {
        props.invoiceToUpdate[i].FEE = parseFloat(
          props.invoiceToUpdate[i].FEE.toString().replace(",", ".")
        );
      }
    }

    const data = props.invoiceToUpdate[0];

    setIsRunning(true);
    const url_query_string =
      "financialinsight?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&type=update";

    axios
      .post(url_query_string, data)
      .then((res) => {
        props.setFinancialInsight(res.data);
        props.setFinancialInsightF(res.data);
        props.setModalUpdate(!props.modalUpdate);
        setIsRunning(false);
        props.setIsError(false);
        props.setInfoBox("Fakturační položka byla úspěšne aktualizována.");
      })
      .catch((err) => {
        console.log(err);
        setIsRunning(false);
        props.setModalUpdate(!props.modalUpdate);
        props.setIsError(true);
        props.setInfoBox(
          "Neočekávaná chyba! Fakturační položku se nezdařilo aktualizovat!"
        );
      });
  };

  const toggleModal = () => {
    console.log(props.invoiceToUpdate[0]["ID"]);
    props.setModalUpdate(!props.modalUpdate);
  };

  const UpdateInvoice = (value, name) => {
    const values = [...props.invoiceToUpdate];
    values[0][name] = value;

    props.setInvoiceToUpdate(values);
  };

  return (
    <>
      {props.modalUpdate && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Probíha aktualizace fakturační položky."}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Editace fakturační položky</div>
              <div class="modal-window__content">
                <div class="modal-form">
                  <FormUpEvFeeOs
                    UpdateVal={UpdateInvoice}
                    myArr={props.invoiceToUpdate}
                    user={props.user}
                    validace={validace}
                    vybranyMediatyp={props.vybranyMediatyp}
                  />
                </div>
              </div>
              <br></br>
              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--primary"
                  onClick={InvoiceToUpdate}
                >
                  Uložit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
