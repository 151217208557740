import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import Filter from "./Filter";
import ModalInvoicesToUnpair from "./Order-RI-Modal_invoices_unpair";
import ModalInfo from "./modal_info";
import ModalInvoicesAction from "./Order-RI-Modal_invoices_action";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
} from "../helpers";
import ModalFiStandard from "./Order-RI-Modal_fi_standard";
import ModalFiFeeOs from "./Order-RI-Modal_fi_fee_os";
import { useParams } from "react-router-dom";

export default function RecievedInvoices(props) {
  const [modalToEvidate, setModalToEvidate] = useState(false);
  const [modalToUnpair, setModalToUnpair] = useState(false);
  const [action, setAction] = useState("");
  const [modalAction, setModalAction] = useState(false);

  const [invoiceToEvidate, setInvoiceToEvidate] = useState([]);
  const [recievedInvoices, setRecievedInvoices] = useState([]);
  const [invoiceToUnpair, setInvoiceToUnpair] = useState();

  const [isLoading, setLoading] = useState(true);

  const [recievedInvoicesF, setRecievedInvoicesF] = useState([]);
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [sortDirection, setSortDirection] = useState(false);

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [uctarna, setUctarna] = useState(0);
  const params = useParams();

  const sortColumn = (colName) => {
    const values = [...recievedInvoicesF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setRecievedInvoicesF(values);
  };

  useEffect(() => {
    const url_query_string =
      "/heliosinvoices?kampan_id=" + params.zakazka + "&type=assigned_invoices";

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        console.log(res);
        setRecievedInvoices(res.data);
        setRecievedInvoicesF(res.data);
        setLoading(false);
      } //,
      //(err) => {
      //  console.log(err);
      //}
    );
    console.log(props.invoiceInsight);
  }, []);

  const ManageInvoice = () => {
    setModalToEvidate(true);
    setInvoiceToEvidate(recievedInvoices.filter((o) => o.SELECTED === 1));
    // setModalAction(true);
  };

  const Manage1Invoice = (ID) => {
    setInvoiceToEvidate(recievedInvoices.filter((o) => o.ID === ID));
    setModalToEvidate(true);
  };

  const MoveInvoice = (ID) => {
    setInvoiceToEvidate(recievedInvoices.filter((o) => o.ID === ID));
    setModalAction(true);
  };

  const CancelInvoice = (ID, ALIAS) => {
    setModalToUnpair(!modalToUnpair);
    setInvoiceToUnpair({ ID: ID, ALIAS: ALIAS });
  };

  const SelectInvoice = (event, ID) => {
    const values = [...recievedInvoicesF];
    var item = values.find((o) => o.ID == ID);
    item["SELECTED"] = event.target.checked === true ? 1 : 0;

    setRecievedInvoicesF(values);
  };

  if (isLoading) {
    return <Loader message="Načítám data." />;
  }

  return (
    <>
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              {props.user < 6 ? (
                <>
                  <ModalInvoicesAction
                    modalAction={modalAction}
                    setModalAction={setModalAction}
                    setModalToEvidate={setModalToEvidate}
                    setAction={setAction}
                    action={action}
                    invoiceToEvidate={invoiceToEvidate}
                    setRecievedInvoices={setRecievedInvoices}
                    setRecievedInvoicesF={setRecievedInvoicesF}
                  />
                  {modalToEvidate == true ? (
                    (params.mediatyp != "Other services") &
                    (params.mediatyp != "Internet") ? (
                      <ModalFiStandard
                        invoiceToEvidate={invoiceToEvidate}
                        setInvoiceToEvidate={setInvoiceToEvidate}
                        setModalToEvidate={setModalToEvidate}
                        modalToEvidate={modalToEvidate}
                        setModalAction={setModalAction}
                        setRecievedInvoices={setRecievedInvoices}
                        setRecievedInvoicesF={setRecievedInvoicesF}
                        setIsError={setIsError}
                        setInfoBox={setInfoBox}
                        user={props.user}
                        uctarna={uctarna}
                        vybranaZakazka={params.zakazka}
                        vybranyMediatyp={params.mediatyp}
                      />
                    ) : (
                      <ModalFiFeeOs
                        invoiceToEvidate={invoiceToEvidate}
                        setInvoiceToEvidate={setInvoiceToEvidate}
                        setModalToEvidate={setModalToEvidate}
                        modalToEvidate={modalToEvidate}
                        setModalAction={setModalAction}
                        setRecievedInvoices={setRecievedInvoices}
                        setRecievedInvoicesF={setRecievedInvoicesF}
                        setIsError={setIsError}
                        setInfoBox={setInfoBox}
                        user={props.user}
                        uctarna={uctarna}
                        vybranaZakazka={params.zakazka}
                        vybranyMediatyp={params.mediatyp}
                      />
                    )
                  ) : (
                    <></>
                  )}
                  <ModalInvoicesToUnpair
                    vybranaZakazka={params.zakazka}
                    vybranyMediatyp={params.mediatyp}
                    setModalToUnpair={setModalToUnpair}
                    modalToUnpair={modalToUnpair}
                    setInvoiceToUnpair={setInvoiceToUnpair}
                    invoiceToUnpair={invoiceToUnpair}
                    setRecievedInvoices={setRecievedInvoices}
                    setRecievedInvoicesF={setRecievedInvoicesF}
                  />
                </>
              ) : (
                <></>
              )}
              <ModalInfo
                isError={isError}
                setIsError={setIsError}
                infoBox={infoBox}
                setInfoBox={setInfoBox}
              />
              <div class="table-container">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div
                        class={
                          uctarna == 0 || uctarna == 2
                            ? "table__col table__col--w140"
                            : "table__col table__col--w200 table__col--with-checkbox"
                        }
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("INVOICE_ALIAS")}
                        >
                          Číslo faktury
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={recievedInvoices}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setRecievedInvoicesF}
                          colName={"INVOICE_ALIAS"}
                        />
                      </div>
                      <div class="table__col table__col--w200">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("INVOICE_ENTITY")}
                        >
                          Dodavatel
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={recievedInvoices}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setRecievedInvoicesF}
                          colName={"INVOICE_ENTITY"}
                        />
                      </div>

                      <div class="table__col table__col--w200">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("INVOICE_PERIOD")}
                        >
                          Fakturované období
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={recievedInvoices}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setRecievedInvoicesF}
                          colName={"INVOICE_PERIOD"}
                        />
                      </div>
                      <div class="table__col table__col--w140">
                        Fakturovaná částka (bez DPH)
                      </div>
                      <div class="table__col table__col--w140">Měna</div>
                      <div class="table__col table__col--w140">Kurz</div>
                      <div class="table__col table__col--flexible">
                        Text na faktuře
                      </div>
                    </div>
                  </div>
                  <div class="table__body">
                    {recievedInvoicesF
                      .filter((o) =>
                        uctarna == 2
                          ? (o.INVOICE_TYPE === 18 || o.INVOICE_TYPE === 19) &
                            (o.STATUS !== 1)
                          : o.INVOICE_TYPE === 18 || o.INVOICE_TYPE === 19
                      )
                      .map((recievedInvoices, index) => (
                        <>
                          <div
                            class={
                              recievedInvoices.STATUS === 1
                                ? "table__row table__row--highlight-green"
                                : "table__row"
                            }
                            key={index}
                            style={{ cursor: "pointer" }}
                            {...(uctarna == 0
                              ? recievedInvoices.STATUS === 1
                                ? {
                                    onClick: () =>
                                      CancelInvoice(
                                        recievedInvoices.ID,
                                        recievedInvoices.INVOICE_ALIAS_PORCIS
                                      ),
                                  }
                                : {
                                    onClick: () =>
                                      Manage1Invoice(recievedInvoices.ID),
                                  }
                              : uctarna == 2
                              ? {
                                  onClick: () =>
                                    MoveInvoice(recievedInvoices.ID),
                                }
                              : {})}
                          >
                            {uctarna == 0 || uctarna == 2 ? (
                              <div class="table__col table__col--w140">
                                {recievedInvoices.INVOICE_ALIAS_PORCIS}
                              </div>
                            ) : (
                              <div class="table__col table__col--inner-cols table__col--w200">
                                <div class="table__inner-columns">
                                  <div class="table__inner-col">
                                    <div class="form-checkbox form-checkbox--no-label">
                                      {recievedInvoices.STATUS != 1 ? (
                                        <>
                                          <input
                                            type="checkbox"
                                            defaultChecked={
                                              recievedInvoices.SELECTED
                                            }
                                            onChange={(event) => {
                                              SelectInvoice(
                                                event,
                                                recievedInvoices.ID
                                              );
                                            }}
                                            id={recievedInvoices.ID}
                                          />
                                          <label
                                            htmlFor={recievedInvoices.ID}
                                          ></label>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>

                                  <div class="table__inner-col">
                                    {recievedInvoices.INVOICE_ALIAS_PORCIS}
                                  </div>
                                </div>
                              </div>
                            )}

                            <div class="table__col table__col--w200">
                              {recievedInvoices.INVOICE_ENTITY}
                            </div>

                            <div class="table__col table__col--w200">
                              {recievedInvoices.INVOICE_PERIOD}
                            </div>
                            <div class="table__col table__col--w140">
                              {numberWithSpaces(
                                recievedInvoices.INVOICE_AMOUNT
                              )}
                            </div>
                            <div class="table__col table__col--w140">
                              {recievedInvoices.MENA}
                            </div>
                            <div class="table__col table__col--w140">
                              {numberWithSpaces(recievedInvoices.KURZ)}
                            </div>
                            <div class="table__col table__col--flexible">
                              {recievedInvoices.POZNAMKAFA}
                            </div>
                          </div>
                        </>
                      ))}
                    <div class="table__row" key="sumacny">
                      <div
                        class={
                          uctarna == 0 || uctarna == 2
                            ? "table__col table__col--w140 table__col--hidden"
                            : "table__col table__col--w200 table__col--with-checkbox table__col--hidden"
                        }
                      ></div>
                      <div class="table__col table__col--w200 table__col--hidden"></div>
                      <div class="table__col table__col--w200 table__col--hidden"></div>
                      <div class="table__col table__col--w140 table__col--highlight-green">
                        {numberWithSpaces(
                          recievedInvoicesF
                            .filter(
                              (o) =>
                                o.INVOICE_TYPE == 18 || o.INVOICE_TYPE == 19
                            )
                            .reduce(
                              (n, { INVOICE_AMOUNT }) => n + INVOICE_AMOUNT,
                              0
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {props.user < 6 ? (
                  <div class="action-bar">
                    <div class="action-bar__in">
                      {uctarna == 0 ? (
                        <>
                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => setUctarna(1)}
                          >
                            Vybrat více faktur k napárovaní
                          </a>
                          <a
                            class="btn btn--medium btn--red"
                            onClick={() => setUctarna(2)}
                          >
                            Přesun mezi nezařazené faktury
                          </a>
                        </>
                      ) : uctarna == 1 ? (
                        <>
                          <a
                            class="btn btn--medium btn--transparent"
                            onClick={() => setUctarna(0)}
                          >
                            Skrýt výběr řádků
                          </a>

                          <a
                            class="btn btn--medium btn--primary"
                            onClick={ManageInvoice}
                          >
                            Pokračovat
                          </a>
                        </>
                      ) : (
                        <a
                          class="btn btn--medium btn--transparent"
                          onClick={() => setUctarna(0)}
                        >
                          Zpět
                        </a>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
