import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import Filter from "./Filter";
import ModalInvoicesAction from "./Order-RI-Modal_invoices_action";
import { numberWithSpaces } from "../helpers";
import ModalFiDobropis from "./Order-Modal_fi_dobropis";
import ModalInfo from "./modal_info";
export default function IssuedInvoices(props) {
  const [issuedInvoices, setIssuedInvoices] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [action, setAction] = useState("");
  const [modalAction, setModalAction] = useState(false);
  const [modalToEvidate, setModalToEvidate] = useState(false);

  const [issuedInvoicesF, setIssuedInvoicesF] = useState([]);
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [sortDirection, setSortDirection] = useState(false);
  const [invoiceToEvidate, setInvoiceToEvidate] = useState([]);
  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const params = useParams();

  useEffect(() => {
    const url_query_string =
      "/heliosinvoices?kampan_id=" + params.zakazka + "&type=issued_invoices";

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        console.log(res);
        setIssuedInvoices(res.data);
        setIssuedInvoicesF(res.data);
        setLoading(false);
      } //,
      //(err) => {
      //  console.log(err);
      //}
    );
  }, []);

  const sortColumn = (colName) => {
    const values = [...issuedInvoicesF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setIssuedInvoicesF(values);
  };

  const ManageInvoice = (e, ID) => {
    setInvoiceToEvidate(issuedInvoices.filter((o) => o.ID === ID));
    setModalAction(true);
  };

  const Dobropis = (ID) => {
    setInvoiceToEvidate(issuedInvoices.filter((o) => o.ID === ID));
    setModalToEvidate(true);
  };

  if (isLoading) {
    return <Loader message="Načítám data." />;
  }

  return (
    <>
      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      {props.user < 6 ? (
        <>
          <ModalInvoicesAction
            modalAction={modalAction}
            setModalAction={setModalAction}
            setAction={setAction}
            action={action}
            invoiceToEvidate={invoiceToEvidate}
            setRecievedInvoices={setIssuedInvoices}
            setRecievedInvoicesF={setIssuedInvoicesF}
            vybranaZakazka={params.zakazka}
            vybranyMediatyp={params.mediatyp}
          />
          {modalToEvidate && (
            <ModalFiDobropis
              invoiceToEvidate={invoiceToEvidate}
              setInvoiceToEvidate={setInvoiceToEvidate}
              setModalToEvidate={setModalToEvidate}
              modalToEvidate={modalToEvidate}
              setIsError={setIsError}
              setInfoBox={setInfoBox}
              user={props.user}
              vybranaZakazka={params.zakazka}
              vybranyMediatyp={params.mediatyp}
              uctarna={null}
            />
          )}
        </>
      ) : (
        <></>
      )}
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              <div class="table-container">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--w140">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("INVOICE_ALIAS")}
                        >
                          Číslo faktury
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={issuedInvoices}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setIssuedInvoicesF}
                          colName={"INVOICE_ALIAS"}
                        />
                      </div>
                      <div class="table__col table__col--w200">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("INVOICE_ENTITY")}
                        >
                          Dodavatel
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={issuedInvoices}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setIssuedInvoicesF}
                          colName={"INVOICE_ENTITY"}
                        />
                      </div>

                      <div class="table__col table__col--w200">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("INVOICE_PERIOD")}
                        >
                          Fakturované období
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={issuedInvoices}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setIssuedInvoicesF}
                          colName={"INVOICE_PERIOD"}
                        />
                      </div>

                      <div class="table__col table__col--w140">
                        Fakturovaná částka (bez DPH)
                      </div>
                      <div class="table__col table__col--w140">Měna</div>
                      <div class="table__col table__col--w140">Kurz</div>
                      <div class="table__col table__col--w500">
                        Text na faktuře
                      </div>
                      <div class="table__col table__col--flexible"></div>
                    </div>
                  </div>
                  <div class="table__body">
                    {issuedInvoicesF.map((issuedInvoices, index) => (
                      <div
                        class={"table__row"}
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          class="table__col table__col--w140"
                          onClick={(event) =>
                            ManageInvoice(event, issuedInvoices.ID)
                          }
                        >
                          {issuedInvoices.INVOICE_ALIAS_PORCIS}
                        </div>
                        <div
                          class="table__col table__col--w200"
                          onClick={(event) =>
                            ManageInvoice(event, issuedInvoices.ID)
                          }
                        >
                          {issuedInvoices.INVOICE_ENTITY}
                        </div>

                        <div
                          class="table__col table__col--w200"
                          onClick={(event) =>
                            ManageInvoice(event, issuedInvoices.ID)
                          }
                        >
                          {issuedInvoices.INVOICE_PERIOD}
                        </div>
                        <div
                          class="table__col table__col--w140"
                          onClick={(event) =>
                            ManageInvoice(event, issuedInvoices.ID)
                          }
                        >
                          {numberWithSpaces(issuedInvoices.INVOICE_AMOUNT * -1)}
                        </div>
                        <div
                          class="table__col table__col--w140"
                          onClick={(event) =>
                            ManageInvoice(event, issuedInvoices.ID)
                          }
                        >
                          {issuedInvoices.MENA}
                        </div>
                        <div
                          class="table__col table__col--w140"
                          onClick={(event) =>
                            ManageInvoice(event, issuedInvoices.ID)
                          }
                        >
                          {numberWithSpaces(issuedInvoices.KURZ)}
                        </div>
                        <div
                          class="table__col table__col--w500"
                          onClick={(event) =>
                            ManageInvoice(event, issuedInvoices.ID)
                          }
                        >
                          {issuedInvoices.NOTE}
                        </div>
                        <div class="table__col table__col--flexible">
                          {props.user !== 6 ? (
                            <a
                              class="btn btn--medium btn--bordered"
                              onClick={() => Dobropis(issuedInvoices.ID)}
                            >
                              Vystavit dobropis
                            </a>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ))}
                    <div class="table__row" key="sumacny">
                      <div class="table__col table__col--w140 table__col--hidden"></div>
                      <div class="table__col table__col--w200 table__col--hidden"></div>
                      <div class="table__col table__col--w200 table__col--hidden"></div>
                      <div class="table__col table__col--w140 table__col--highlight-green">
                        {numberWithSpaces(
                          issuedInvoicesF
                            .filter(
                              (o) =>
                                o.INVOICE_TYPE == 13 || o.INVOICE_TYPE == 14
                            )
                            .reduce(
                              (n, { INVOICE_AMOUNT }) =>
                                n + INVOICE_AMOUNT * -1,
                              0
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
