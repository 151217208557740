import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Filter from "./Filter";
import { numberWithSpaces } from "../helpers";
export default function ModalFIDI(props) {
  const [isLoading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState(false);

  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  useEffect(() => {
    const url_query_string =
      "/heliosinvoices?type=deposit_invoices" +
      "&kampan_id=" +
      props.vybranaZakazka;

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        props.setDepositInvoices(res.data);
        props.setDepositInvoicesF(res.data);
        setLoading(false);
      } //,
      //(err) => {
      //  console.log(err);
      //}
    );
  }, []);

  const SelectDI = (event, id) => {
    const values = [...props.depositInvoicesF];
    var item = values.find((o) => o.ID == id);

    item["SELECTED"] = event.target.checked === true ? 1 : 0;

    props.setDepositInvoicesF(values);
  };

  const Continue = () => {
    if (props.depositInvoices.filter((o) => o.SELECTED == 1).length > 0) {
      props.setModalDI(false);
      props.setVyber(props.predVyber);
    }
  };

  const toggleModal = () => {
    props.setModalDI(false);
    props.setVyber(props.predVyber);

    props.setDepositInvoices([]);
    props.setDepositInvoicesF([]);
  };

  const sortColumn = (colName) => {
    const values = [...props.depositInvoicesF];

    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    props.setDepositInvoicesF(values);
  };

  if (isLoading) {
    return (
      <>
        {
          //modal &&
          <>
            <div class="modal-window">
              <div class="modal-window__wrap">
                <div class="modal-window__close">
                  <div>Loading...</div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }

  return (
    <div class="modal-window modal-window--w600">
      <div class="modal-window__wrap">
        <div class="modal-window__close" onClick={toggleModal}>
          <div class="icon">
            <svg>
              <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref="/assets/img/sprite.svg#cross"
              />
            </svg>
          </div>
        </div>
        <div class="modal-window__in">
          <div class="modal-window__title">Vyberte položku k napárovaní</div>
          <div class="modal-window__content">
            <div class="table-container">
              <div class="table">
                <div class="table__head">
                  <div class="table__row">
                    <div class="table__col table__col--w200 table__col--with-checkbox">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => sortColumn("Cislo")}
                      >
                        Číslo faktury
                      </span>
                      <Filter
                        filter={filter}
                        setFilter={setFilter}
                        filterVal={filterVal}
                        setFilterVal={setFilterVal}
                        table={props.depositInvoices}
                        filterInput={filterInput}
                        setFilterInput={setFilterInput}
                        setFilteredTable={props.setDepositInvoicesF}
                        colName={"Cislo"}
                      />
                    </div>
                    <div class="table__col table__col--w140">Částka</div>
                    <div class="table__col table__col--w140">Čerpáno</div>
                    <div class="table__col table__col--w140">Zůstatek</div>
                  </div>
                </div>
                <div class="table__body">
                  {props.depositInvoicesF
                    .filter((o) => o.SumaKcBezDPH - o.DRAW > 0.1) // zobrazujeme len nevycerpane
                    .map((depositInvoices, index) => (
                      <div class="table__row" key={depositInvoices.ID}>
                        <div class="table__col table__col--inner-cols table__col--w200">
                          <div class="table__inner-columns">
                            <div class="table__inner-col">
                              <div class="form-checkbox form-checkbox--no-label">
                                {depositInvoices.SumaKcBezDPH -
                                  depositInvoices.DRAW !==
                                0 ? (
                                  <input
                                    type="checkbox"
                                    defaultChecked={depositInvoices.SELECTED}
                                    onChange={(event) => {
                                      SelectDI(event, depositInvoices.ID);
                                    }}
                                    id={depositInvoices.ID}
                                  />
                                ) : (
                                  <></>
                                )}

                                <label htmlFor={depositInvoices.ID}></label>
                              </div>
                            </div>

                            <div class="table__inner-col">
                              {depositInvoices.ParovaciZnak}
                            </div>
                          </div>
                        </div>

                        <div class="table__col table__col--w140">
                          {numberWithSpaces(depositInvoices.SumaKcBezDPH)}
                        </div>
                        <div class="table__col table__col--w140">
                          {numberWithSpaces(depositInvoices.DRAW)}
                        </div>
                        <div class="table__col table__col--w140">
                          {numberWithSpaces(
                            depositInvoices.SumaKcBezDPH - depositInvoices.DRAW
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-window__actions">
            <a
              className={
                props.depositInvoices.filter((o) => o.SELECTED == 1).length > 0
                  ? "btn btn-medium btn--primary"
                  : "btn btn-medium btn--primary btn--disabled"
              }
              onClick={Continue}
            >
              Pokracovat
            </a>
            <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
              Storno
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
