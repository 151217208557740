import axios from "axios";
import React, { useEffect, useState } from "react";
import ModalFiUpdate from "./Order-FI-Modal_fi_update";
import Loader from "./Loader";
import ModalRunningWhite from "./modal_running_white";
import Tooltip from "react-bootstrap/Tooltip";
import ModalInfo from "./modal_info";
import ModalFiFakturace from "./Order-FI-Modal_fi_fakturace";
import FinancialInsightStandardTable from "./Order-FI_standard_table";
import ModalFiDobropis from "./Order-Modal_fi_dobropis";
import { numberWithSpaces } from "../helpers";
import { useParams } from "react-router-dom";

export default function FinancialInsightStandard(props) {
  const [isLoading, setLoading] = useState(true);
  const [financialInsight, setFinancialInsight] = useState([]);
  const [financialInsightF, setFinancialInsightF] = useState([]);
  const [depositInvoicesAct, setDepositInvoicesAct] = useState([]);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [invoiceToUpdate, setInvoiceToUpdate] = useState([]);
  const [modalToEvidate, setModalToEvidate] = useState(false);

  const [uctarna, setUctarna] = useState(0);

  const [modalFakturace, setModalFakturace] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [sortDirection, setSortDirection] = useState(false);

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const [invoiceCreated, setInvoiceCreated] = useState(false); // pridane kvoli re run /financialinsigh v useffecte po vytvoreni faktury
  const params = useParams();

  useEffect(() => {
    const url_query_string =
      "/financialinsight?kampan_id=" +
      params.zakazka +
      "&mediatyp=" +
      params.mediatyp;

    axios.get(url_query_string).then(
      (res) => {
        console.log(res.data);
        setFinancialInsight(res.data);
        setFinancialInsightF(res.data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
    console.log(props.invoiceInsight);
  }, [invoiceCreated]);

  const sortColumn = (colName) => {
    const values = [...financialInsightF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setFinancialInsightF(values);
  };

  const SelectMediaplan = (event, index, vydavatel) => {
    const values = [...financialInsightF];

    values[index]["SELECTED"] = event.target.checked === true ? 1 : 0;

    setFinancialInsightF(values);
  };

  const SelectAll = () => {
    const values = [...financialInsightF];
    var value_to_change = selectAll == false ? 1 : 0;

    values.map(function (x) {
      x.SELECTED = x.ID_INVOICE_TO_HELIOS == null ? value_to_change : 0;
      return x;
    });

    setSelectAll(!selectAll);
    setFinancialInsightF(values);
  };

  const updateInvoice = (ID) => {
    let values = JSON.parse(JSON.stringify(financialInsight));

    setModalUpdate(true);
    setInvoiceToUpdate(values.filter((o) => o.ID === ID));
    console.log(values.filter((o) => o.ID === ID));
  };

  const renderTooltip = (col) => {
    if (col == "ID_MEDIAPLAN_VERSION_FE")
      var tip =
        "Verze schváleného mediaplánu, ke kterému se váže faktura. Relevantní v případě, že je více schválených mediaplánů.";

    if (col == "DATUM_UVEREJNENI")
      var tip =
        "Období, ve kterém byla kampaň on-air a které zahrnuje vyúčtování na faktuře.";
    if (col == "Dobropis") var tip = "ID dokumentu v Heliosu.";

    if (col == "MNN")
      var tip =
        "Nákupní cena dané položky u dodavatele, cena, která je za dané období uvedena na faktuře.";

    if (col == "MNPMP")
      var tip =
        "Cena, která byla u dané položky klientem schválena na mediaplánu.";

    if (col == "MNP")
      var tip =
        "Klientovi skutečně fakturovaná cena u dané položky za mediální prostor za dané období. Může a nemusí se shodovat s „Media Net MP“.";

    if (col == "CNNN")
      var tip =
        "Klientovi skutečně fakturovaná cena u dané položky za dané období včetně dodatečných nákladů (fee).";

    if (col == "MNP_MNN")
      var tip =
        "Rozdíl v ceně mediálního prostoru u dané položky za dané období – rozdíl mezi nákupem a prodejem.";

    if (col == "MNP_MNN_FI_ID_LABEL")
      var tip =
        "Přednastavené pole, které je nutno vyplnit pro vysvětlení případné nenulové položky v kolonce „Delta MNP – MNN“.";

    if (col == "NOTE_1") var tip = "Jakákoliv dodatečná poznámka.";

    if (col == "DEPOSIT_INVOICE_AMOUNT")
      var tip =
        "Část ceny mediálního prostoru, která již byla uhrazena dříve prostřednictvím zálohové faktury.";

    return <Tooltip {...props}>{tip}</Tooltip>;
  };

  if (isLoading) {
    return <Loader message="" />;
  }

  return (
    <>
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      <main class="content">
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              {props.user < 6 ? (
                <>
                  <ModalFiUpdate
                    vybranaZakazka={params.zakazka}
                    vybranyMediatyp={params.mediatyp}
                    setFinancialInsight={setFinancialInsight}
                    setFinancialInsightF={setFinancialInsightF}
                    setDepositInvoicesAct={setDepositInvoicesAct}
                    depositInvoicesAct={depositInvoicesAct}
                    invoiceToUpdate={invoiceToUpdate}
                    setInvoiceToUpdate={setInvoiceToUpdate}
                    modalUpdate={modalUpdate}
                    setModalUpdate={setModalUpdate}
                    setIsError={setIsError}
                    setInfoBox={setInfoBox}
                  />
                  {modalFakturace ? (
                    <ModalFiFakturace
                      setFinancialInsight={setFinancialInsight}
                      setFinancialInsightF={setFinancialInsightF}
                      financialInsight={financialInsight}
                      modalFakturace={modalFakturace}
                      setModalFakturace={setModalFakturace}
                      setIsError={setIsError}
                      setInfoBox={setInfoBox}
                      setUctarna={setUctarna}
                      setInvoiceCreated={setInvoiceCreated}
                      invoiceCreated={invoiceCreated}
                      vybranaZakazka={params.zakazka}
                      vybranyMediatyp={params.mediatyp}
                    />
                  ) : (
                    <></>
                  )}
                  {modalToEvidate && (
                    <ModalFiDobropis
                      invoiceToEvidate={financialInsight}
                      setModalToEvidate={setModalToEvidate}
                      modalToEvidate={modalToEvidate}
                      setIsError={setIsError}
                      setInfoBox={setInfoBox}
                      user={props.user}
                      vybranaZakazka={params.zakazka}
                      vybranyMediatyp={params.mediatyp}
                      uctarna={uctarna}
                    />
                  )}
                </>
              ) : (
                <></>
              )}
              <div class="table-container">
                <FinancialInsightStandardTable
                  financialInsightF={financialInsightF}
                  parentFI={"standard"}
                  uctarna={uctarna}
                  SelectMediaplan={SelectMediaplan}
                  updateInvoice={updateInvoice}
                  numberWithSpaces={numberWithSpaces}
                  renderTooltip={renderTooltip}
                  financialInsight={financialInsight}
                  sortColumn={sortColumn}
                  setFinancialInsightF={setFinancialInsightF}
                />

                {props.user < 6 ? (
                  <div class="action-bar">
                    <div class="action-bar__in">
                      {uctarna == 0 ? (
                        <>
                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => setUctarna(1)}
                          >
                            Vybrat řádky k fakturaci
                          </a>
                          {/*<a
                            class="btn btn--medium btn--primary"
                            onClick={() => setUctarna(2)}
                          >
                            Vystavit dobropis
                          </a>*/}
                        </>
                      ) : (
                        <></>
                      )}
                      {uctarna == 1 ? (
                        <>
                          <a
                            class="btn btn--medium btn--transparent"
                            onClick={SelectAll}
                          >
                            Vybrat všechny řádky
                          </a>
                          <a
                            class="btn btn--medium btn--transparent"
                            onClick={() => setUctarna(0)}
                          >
                            Skrýt výběr řádků
                          </a>

                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => setModalFakturace(true)}
                          >
                            Pokračovat
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                      {uctarna == 2 ? (
                        <>
                          <a
                            class="btn btn--medium btn--transparent"
                            onClick={() => setUctarna(0)}
                          >
                            Skrýt výběr řádků
                          </a>

                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => setModalToEvidate(true)}
                          >
                            Pokračovat
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
