import React from "react";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
} from "../helpers";
import Validation from "./Validation";
export default function FiTextCastkaUcto(props) {
  const heliosP = [
    { ID_HELIOS_P: 1, NAZEV: "Ostatní nemediální služby" },
    { ID_HELIOS_P: 2, NAZEV: "Mediální Inzerce" },
    { ID_HELIOS_P: 3, NAZEV: "Fee" },
    { ID_HELIOS_P: 5, NAZEV: "Obratové bonusy" },
  ];

  const stredisko = [
    { ID_STREDISKO: 1, STREDISKO: "Client Success" },
    { ID_STREDISKO: 2, STREDISKO: "Digital Solutions" },
  ];

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const UpdatePolozky = async (value, idx, col) => {
    const values = [...props.polozky];
    if (col == "SKUPINA") {
      if (value == "Samostatně") {
        values[idx]["TEXT"] = "";
      } else {
        var item = values.find((o) => (o.TEXT != null) & (o.SKUPINA == value));
        if (item == null) {
          values[idx]["TEXT"] = "";
        } else {
          values[idx]["TEXT"] = item["TEXT"];
        }
      }
    }
    if (col == "ID_HELIOS_P") {
      values[idx]["NAZEV"] = value;
      values[idx][col] =
        value != ""
          ? heliosP.find((o) => o.NAZEV == value)["ID_HELIOS_P"]
          : null; //heliosP[parseInt(value)]["NAZEV"];
    } else if (col == "ID_STREDISKO") {
      values[idx]["STREDISKO"] = value;
      values[idx][col] =
        value != ""
          ? stredisko.find((o) => o.STREDISKO == value)["ID_STREDISKO"]
          : null;
    } else if (col == "TEXT") {
      values[idx][col] = value;
    } else if (col == "AMOUNT") {
      if (value == "") {
        values[idx][col] = null;
      } else {
        values[idx][col] = value.replace(/\s+/g, ""); //nahradzujeme medzeru v stringu, lebo cisla vracaju numberwithspaces
      }
    } else {
      if (value == "") {
        values[idx][col] = null;
      } else {
        values[idx][col] = value;
      }
    }

    props.setPolozky(values);

    await delay(4000);
    props.setPolozkyRozdil(
      props.financialInsight
        .filter((o) => o.SELECTED == 1)
        .reduce((n, { CNNN }) => n + CNNN, 0) +
        props.financialInsightModal
          .filter((o) => o.SELECTED == 1)
          .reduce((n, { CNNN }) => n + CNNN, 0) -
        replaceComma(
          props.polozky
            .filter((o) => o.AMOUNT != null)
            .reduce(
              (n, { AMOUNT }) => replaceComma(n) + replaceComma(AMOUNT),
              0
            )
        )
    );
  };

  return (
    <>
      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for="id" class="form-label form-label--inline">
            Skupina
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {props.idx < 10 ? (
            <select
              class="form-select"
              value={props.polozka.SKUPINA}
              onChange={(e) =>
                UpdatePolozky(e.target.value, props.idx, "SKUPINA")
              }
              key={props.idx}
            >
              <option value={null}></option>
              <option value={"Samostatně"}>Samostatně</option>
              <option value={"1"}>1</option>
              <option value={"2"}>2</option>
              <option value={"3"}>3</option>
            </select>
          ) : (
            <></>
          )}
        </div>
        {props.validaceP === true ? (
          <Validation
            validace={parseInt(props.validace)}
            validace_custom={parseInt(props.validace_custom)}
            message={"Vyplňte celý řádek!"}
          />
        ) : (
          <></>
        )}
      </div>
      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for={props.idx} class="form-label form-label--inline">
            Text
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {props.idx < 10 ? (
            props.polozka.SKUPINA === "Samostatně" ||
            props.polozka.SKUPINA == null ? (
              <input
                class="form-input"
                placeholder={
                  props.idx == 0
                    ? props.idx + 1 + ". Asd ghfr sd..."
                    : props.idx + 1 + "."
                }
                value={props.polozka.TEXT}
                onChange={(e) => {
                  UpdatePolozky(e.target.value, props.idx, "TEXT");
                }}
              />
            ) : (
              <></>
            )
          ) : (
            <input
              class="form-input"
              placeholder="YYYY-MM - Povinné"
              value={props.polozka.TEXT}
              readOnly
            />
          )}
        </div>
      </div>

      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for={props.idx} class="form-label form-label--inline">
            Kategorizace Položky
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {/*props.idx < 10 ? (
            <input
              class="form-input"
              placeholder={
                props.idx == 0
                  ? props.idx + 1 + ". Asd ghfr sd..."
                  : props.idx + 1 + "."
              }
              value={props.polozka.TEXT}
              onChange={(e) => {
                UpdatePolozky(e.target.value, props.idx, "TEXT");
              }*/}
          {props.idx < 10 ? (
            <select
              class="form-select"
              value={props.polozka.NAZEV}
              onChange={(e) =>
                UpdatePolozky(e.target.value, props.idx, "ID_HELIOS_P")
              }
              key={props.idx}
            >
              <option value={null}></option>
              {heliosP.map((heliosP, index) => (
                <option value={heliosP.NAZEV} key={props.idx + index}>
                  {heliosP.NAZEV}
                </option>
              ))}
            </select>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for="id" class="form-label form-label--inline">
            Hodnota
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {props.idx < 10 ? (
            <input
              class="form-input"
              value={numberWithSpaces(props.polozka.AMOUNT)}
              placeholder={props.idx == 0 ? "13000.." : ""}
              onChange={(e) =>
                UpdatePolozky(e.target.value, props.idx, "AMOUNT")
              }
              onKeyDown={(e) => checkValidInput(e)}
            />
          ) : (
            <input
              class="form-input"
              value={props.polozka.AMOUNT}
              placeholder="150000.. - Povinné"
              readOnly
            />
          )}
        </div>
      </div>

      <div class={"modal-form__item"}>
        {props.idx == 0 ? (
          <label for="id" class="form-label form-label--inline">
            Středisko
          </label>
        ) : (
          <></>
        )}

        <div class="form__value">
          {props.idx < 10 ? (
            <select
              class="form-select"
              value={props.polozka.STREDISKO}
              onChange={(e) =>
                UpdatePolozky(e.target.value, props.idx, "ID_STREDISKO")
              }
              key={props.idx}
            >
              <option value={null}></option>
              {stredisko.map((stredisko, index) => (
                <option value={stredisko.STREDISKO} key={props.idx + index}>
                  {stredisko.STREDISKO}
                </option>
              ))}
            </select>
          ) : (
            <></>
          )}
        </div>
      </div>

      {props.druhaZakazka == null ? (
        <></>
      ) : (
        <div class="modal-form__item">
          {props.idx == 0 ? (
            <label for="id" class="form-label form-label--inline">
              Zakázka
            </label>
          ) : (
            <></>
          )}

          <div class="form__value">
            {props.idx < 10 ? (
              props.druhaZakazka === "vyplnit" ? (
                <input
                  class="form-input"
                  placeholder={props.idx == 0 ? "24saz..." : ""}
                  value={props.polozka.ID_KAMPAN}
                  onChange={(e) => {
                    UpdatePolozky(e.target.value, props.idx, "ID_KAMPAN");
                  }}
                />
              ) : (
                <select
                  class="form-select"
                  value={props.polozka.ID_KAMPAN}
                  onChange={(e) =>
                    UpdatePolozky(e.target.value, props.idx, "ID_KAMPAN")
                  }
                >
                  <option value={null}></option>
                  <option value={props.vybranaZakazka}>
                    {props.vybranaZakazka}
                  </option>
                  <option value={props.druhaZakazka}>
                    {props.druhaZakazka}
                  </option>
                </select>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {(props.mena !== "CZK") &
      (props.polozka.AMOUNT != null) &
      (props.idx < 10) ? (
        <>
          <div class={"modal-form__item"}></div>
          <div class={"modal-form__item"}></div>
          <div class={"modal-form__item"}>
            <input
              class="form-input"
              value={"Hodonta v " + props.mena + ":"}
              readOnly
            />
          </div>
          <div class={"modal-form__item"}>
            <div class="form__value">
              <input
                class="form-input"
                value={props.polozka.AMOUNT / props.safeKurz}
              />
            </div>
          </div>
          <div class={"modal-form__item"}></div>

          {props.druhaZakazka == null ? (
            <></>
          ) : (
            <div class={"modal-form__item"}></div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
