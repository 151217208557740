import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  CheckValidPaste,
} from "../helpers";
import FormUpEv from "./Order-Form_up_ev";

export default function ModalInvoicesEvidence(props) {
  const [validace, setValidace] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const EvidateInvoice = async () => {
    for (let i = 0; i < props.mediaplanAgg.length; i++) {
      props.mediaplanAgg[i].MNNP = parseFloat(
        props.mediaplanAgg[i].MNNP.toString().replace(",", ".")
      );

      props.mediaplanAgg[i].CNNN = parseFloat(
        props.mediaplanAgg[i].CNNN.toString().replace(",", ".")
      );
    }

    const data = {
      DATA: props.mediaplanAgg[0],
      INV: props.invoiceToEvidate,
    };

    console.log(data);

    var validace_var;

    validace_var =
      data["MNNP"] - data["MNNN"] !== null && data["MNNP"] - data["MNNN"] !== 0
        ? data["MNNP_MNNN_FI_ID"] === null
          ? 1
          : 0
        : 0;

    validace_var =
      data["MNNP_MNNN_FI_ID"] == 5 || data["MNNP_MNNN_FI_ID"] == 6
        ? data["NOTE_1"] === null
          ? 3
          : validace_var
        : validace_var;

    setValidace(validace_var);

    if (validace_var === 0) {
      setIsRunning(true);
      const url_query_string =
        "invoiceevidence?kampan_id=" +
        props.vybranaZakazka +
        "&mediatyp=" +
        props.vybranyMediatyp +
        "&typ=evidence&mass=false";

      await axios
        .post(url_query_string, data)
        .then((res) => {
          setIsRunning(false);

          props.setVybranyClient(false);
          props.setRecievedInvoices(res.data);
          props.setRecievedInvoicesF(res.data);
          props.setModalInvoiceToEvidate(false);
          props.setModalToEvidate(false);
          props.setIsError(false);
          props.setModalAction(false);
          props.setInfoBox("Faktura byla úspěšne zaevidována.");
        })
        .catch((err) => {
          setIsRunning(false);
          props.setIsError(true);
          props.setInfoBox("Fakturu se nezdařilo zaevidovat!");
          console.log(err);
        });

      const url =
        "/financialinsight?kampan_id=" +
        props.vybranaZakazka +
        "&mediatyp=" +
        props.vybranyMediatyp;

      await axios.get(url).then(
        (res) => {
          console.log("fi");
          props.setFinancialInsightModal(res.data);
          props.setFinancialInsightModalF(res.data);
        },
        (err) => {}
      );
    }
  };

  const toggleModal = () => {
    console.log(props.mediaplanAgg[0]["ID"]);

    props.setModalInvoiceToEvidate(!props.modalInvoiceToEvidate);
    props.setModalAction(false);
  };

  const UpdateInvoice = (value, name) => {
    const values = [...props.mediaplanAgg];
    values[0][name] = value;

    props.setMediaplanAgg(values);
  };

  return (
    <>
      {props.modalInvoiceToEvidate && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Evidují fakturu."}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">Evidence Faktury</div>
              <div class="modal-window__content">
                <div class="form">
                  <FormUpEv
                    UpdateVal={UpdateInvoice}
                    myArr={props.mediaplanAgg}
                    user={props.user}
                    mnnpUpdate={false}
                    validace={validace}
                  />
                  <br></br>
                </div>
              </div>
              <br></br>
              <div class="modal-window__actions">
                <a
                  class="btn btn--medium btn--primary"
                  onClick={EvidateInvoice}
                >
                  Uložit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
