import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../helpers";
export default function InfoBar(props) {
  const [NAZEV_ZAKAZKY, setNAZEV_ZAKAZKY] = useState("");
  const [CLIENT, setCLIENT] = useState("");
  const [MEDIATYP, setMEDIATYP] = useState("");
  const [OD, setOD] = useState("");
  const [DO, setDO] = useState("");
  const [STAV_ZAKAZKY, setSTAV_ZAKAZKY] = useState("");
  const [KAMPAN_ID, setKAMPAN_ID] = useState("");
  const params = useParams();

  useEffect(() => {
    try {
      const obj = JSON.parse(localStorage.getItem("zakazka")).find(
        (x) => x.KAMPAN_ID === params.zakazka
      );

      setNAZEV_ZAKAZKY(obj["NAZEV_ZAKAZKY"]);
      setCLIENT(obj["CLIENT"]);
      setMEDIATYP(obj["MEDIATYP"]);
      setOD(obj["OD"]);
      setDO(obj["DO"]);
      setSTAV_ZAKAZKY(
        obj["STAV_ZAKAZKY"] === "Schváleno"
          ? obj["CONFIRMED_COUNT"] === null
            ? obj["STAV_ZAKAZKY"] + " (0x)"
            : obj["STAV_ZAKAZKY"] + " (" + obj["CONFIRMED_COUNT"] + "x)"
          : obj["STAV_ZAKAZKY"]
      );
      setKAMPAN_ID(obj["KAMPAN_ID"]);
    } catch {}
  }, [props.emailRedirect]);

  return (
    <div class="info-bar">
      <div class="container">
        <div class="info-bar__in">
          <div class="info-bar__item">
            <span>ID:</span> {KAMPAN_ID}
          </div>

          <div class="info-bar__item">
            <span>Název:</span> {NAZEV_ZAKAZKY}
          </div>

          <div class="info-bar__item">
            <span>Mediatyp:</span> {MEDIATYP}
          </div>

          <div class="info-bar__item">
            <span>Klient:</span> {CLIENT}
          </div>

          <div class="info-bar__item">
            <span>Realizace:</span> {formatDate(OD) + "--" + formatDate(DO)}
          </div>

          <div
            class="info-bar__item"
            onClick={() => {
              navigator.clipboard.writeText(
                KAMPAN_ID + // "@mind2purchases.com"
                  "@mind2zs.com"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <span>Systémový e-mail:</span>
            {KAMPAN_ID + //"@mind2purchases.com"
              "@mind2zs.com"}
          </div>

          <div class="info-bar__item">
            <div class="info-bar__status info-bar__status--green">
              <span>Stav:</span> {sessionStorage.getItem("STAV_ZAKAZKY")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
